import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const MessageContext = createContext();

// Helper function to get message from local storage
const getMessageFromStorage = () => {
    return localStorage.getItem('message') || '';
};

const MessageProvider = ({ children }) => {
    const [message, setMessage] = useState(getMessageFromStorage);

    // Sync the message with local storage whenever it changes
    useEffect(() => {
        localStorage.setItem('message', message);
    }, [message]);

    // Listen for changes in local storage (from other tabs)
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'message') {
                setMessage(event.newValue || '');
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <MessageContext.Provider value={{ message, setMessage }}>
            {children}
        </MessageContext.Provider>
    );
};

export default MessageProvider;