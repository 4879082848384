import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from "react-router-dom";

import "./assets/styles/index.scss";

import AppRoutes from "./navigation/app-routes";
import BaseRoutes from "./navigation/base-routes";

import { isAuthenticated } from "./utils/helpers/Authentication";
import { ENVIRONMENT } from "./utils/config/constants";
import MessageProvider from "./components/message-provider";





if (ENVIRONMENT === "PRODUCTION") {
  console.log = function () {};
}

const MainLayout = ({ children }) => {
  return (
    <>
      {children}
    </>
  );
};

const BaseApp = () => {
  return (
    <MainLayout>
      <BaseRoutes />
    </MainLayout>
  );
};

const AuthenticatedApp = () => {
  return (
    <MainLayout>
      <AppRoutes />
    </MainLayout>
  );
};

function App() {

  useEffect(() => {
    // document.addEventListener("gesturestart", function (e) {
    //   e.preventDefault();
    //     document.body.style.zoom = 0.99;
    // });
    
    // document.addEventListener("gesturechange", function (e) {
    //   e.preventDefault();
    
    //   document.body.style.zoom = 0.99;
    // });
    // document.addEventListener("gestureend", function (e) {
    //     e.preventDefault();
    //     document.body.style.zoom = 1;
    // });
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
  
  }, [])


  return (
    <MessageProvider>
      <Router>
        <Routes>
          <Route
            path="*"
            element={!isAuthenticated() ? (
              <BaseApp />
            ) : (
              <AuthenticatedApp />
            )}
          >
          </Route>
        </Routes>
      </Router>
    </MessageProvider>
  );
}

export default App;
