import React, { useEffect, useRef, useState } from "react";

//framework
import { Col, Row, Form } from "react-bootstrap";


// plugin

// images
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";

// components
import { Btn, IconButton, Check } from "../../../components";
import EndSession from "../../../components/end-session";


// styles
import './index.scss'
import TextSpeechVoice from "../../../components/text-to-speech-voice";
import { getData, storeData } from "../../../utils/common/storage-utils";
const ApplicationStartUp = ({ onContinue }) => {
    const userData = getData("userData") || {};
    const isInitialMount = useRef(true);

    const [isAudioPlay, setIsAudioPlay] = useState(null);

    const voiceText = `Great ${userData?.firstName}, Before we begin, here is some information regarding the company license that will be created`;
    // const voiceText = `Hello, Please press the "I Agree" button before we begin here is some information regarding the company license that will be created`;
    // const voiceText = `Hello ${userData?.firstName}, Please press the "I Agree" button for acknowledgement and proceed further with instant license process`;
    // const voiceText = `Hello, Before we proceed, here are some important information regarding the company license that will be issued`;
    const textSpeechRef = useRef(null);

    const handleSpeechPause = () => {
        if (textSpeechRef.current) {
            textSpeechRef.current.pauseSpeech();
        }
    };
    const handleResume = (data) => {
        if (textSpeechRef.current) {
            textSpeechRef.current.restartSpeech();
        }
        
    };

    const handleClick = () => {
        handleSpeechPause();
        onContinue();
    };

   /*  useEffect(() => {
        
        if(isAudioPlay) {
            handleSpeechPause();
            storeData("isAduioPlay", false);
        } else {
            handleResume();
            storeData("isAduioPlay", true);
        }
    }, [isAudioPlay]); */

    const handleAudio = (data) => {
        setIsAudioPlay(data);
        if(!data) {
            handleSpeechPause();
        } else {
            handleResume();
        }
        storeData("isAduioPlay", data);
    }

    const getStoreAudioStatus = () => {
        const getAudioStatus = getData("isAduioPlay");
        console.log(getAudioStatus, "getAudioStatus_startup")
        setIsAudioPlay(getAudioStatus);
    }

    useEffect(()=> {
        if (isInitialMount.current) {
            getStoreAudioStatus();
            
            isInitialMount.current = false; // Set flag to prevent future calls   
        }
    }, []);



    return (
        <React.Fragment>
            <div className="fullscreen-view-wrap fullscreen-view-overlap bg-tertiary-color">
                <div className="fs-header-wrap">
                    <div className="d-flex justify-content-between">
                        <div className="logo-wrap mb-0">
                            <img src={GLOBAL_IMAGES.LOGO} alt="LOGO" />
                        </div>
                        <div>
                            <IconButton
                                src={isAudioPlay ? GLOBAL_IMAGES.SPEAKER_ON_ICON : GLOBAL_IMAGES.SPEAKER_OFF_ICON}
                                style="btn-outline-primary no-hover square-btn mr-20"
                                onClick={()=>{handleAudio(!isAudioPlay)}}
                            />
                            <EndSession handleSessionClose={handleSpeechPause}/>
                        </div>
                    </div>
                </div>
                <div className="fs-body-wrap fs-body-wrap-content">
                    <div className="content-wrap height-auto pb-40">
                        <Row>
                            <Col xs={12}>
                                <div className="text-wrap text-center">
                                    <h2 className="f-32 font-med mb-8">Great, <span className="text-transform-capitalize">{userData?.firstName.toLowerCase()}</span></h2>
                                    <p className="subtitle2">
                                        Before we begin, here is some information regarding the<br /> company license that will be created
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={10} className="offset-sm-1 mb-40">
                                <div className="box-card">
                                    <div className="box-section-shape">
                                        <img src={GLOBAL_IMAGES.TOP_BOX_SHAP} alt="TOP_BOX_SHAP" className="box-section-shape-top" />
                                        <img src={GLOBAL_IMAGES.BOTTOM_BOX_SHAP} alt="BOTTOM_BOX_SHAP" className="box-section-shape-bottom" />
                                        <img src={GLOBAL_IMAGES.HORIZONTAL_SHAP_DOT} alt="BOTTOM_BOX_SHAP" className="dot-shape-top" />
                                    </div>
                                    <div className="card-body-section">
                                        <div className="box-section-shape">
                                            <img src={GLOBAL_IMAGES.HORIZONTAL_SHAP_DOT} alt="BOTTOM_BOX_SHAP" className="dot-shape-top" />
                                        </div>
                                        <div className="fs-section-content">
                                            <div className="text-wrap">
                                                <h6 className="text-uppercase text-center mb-24 font-med subtitle1">TERMS AND CONDITIONS</h6>
                                                <Row className="justify-content-between">
                                                    <Col sm={6}>
                                                        <h5 className="font-med body1 border-b">SAEED INSTANT LICENSE T&C</h5>
                                                        <ul className="mt-24 terms-list">
                                                            <li className="body1">
                                                                The instant license is valid for a period of one year.
                                                            </li>
                                                            <li className="body1">
                                                                The company type will be Free Zone Establishment (FZE) with a single shareholder and limited liability.
                                                            </li>
                                                            <li className="body1">
                                                                Only one business activity is permitted under this license.
                                                            </li>
                                                            <li className="body1">
                                                                No visa allocation is included with this license.
                                                            </li>
                                                            <li className="body1">
                                                                A coworking space is provided as part of the license.
                                                            </li>
                                                            <li className="body1">
                                                                An establishment card is not available with this license.
                                                            </li>
                                                            <li className="body1">
                                                                The single shareholder will serve as the company's manager, director, and ultimate beneficial owner.
                                                            </li>
                                                            <li className="body1">
                                                                SPC Free Zone reserves the right to Suspend, Close, or request additional information for the license generated. The instant license is generated automatically, whereas other documents will be processed according to the standard Free Zone Service Level Agreement (SLA).
                                                            </li>
                                                            <li className="body1">
                                                                The total price for this license is 6,885 AED.
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                    <Col sm={5}>
                                                        <h5 className="font-med body1  mb-24 border-b">SPC Terms and Conditions</h5>
                                                        <p className="body2 line-h-6">
                                                            By clicking "I Agree", you hereby agree and acknowledge your responsibility to remain in compliance, at all times, with all applicable undertakings and declarations, as may be required by SPC. You further reaffirm your acceptance of SPC Terms and Conditions and the laws of the United Arab Emirates. Neither SPC nor its affiliates shall assume liability for any actual or purported failure to comply with applicable laws and regulations, whether by the company’s ultimate beneficial owner(s), manager(s), agents or otherwise.
                                                        </p>
                                                        <a className="primary-color body1 span-text-underline" href={`${process.env.PUBLIC_URL}/pdf/saeed-spc-terms-and-conditions.pdf`} target="_blank">SPC Terms and Conditions</a>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="fs-footer-wrap">
                    <div className="btn-wrap btn-flex-end">
                        <Btn
                            variant="primary"
                            size="lg"
                            label={'I Agree'}
                            style={'btn-primary-gradient'}
                            leftIcon={<img src={GLOBAL_IMAGES.LIKE} alt={GLOBAL_IMAGES.LIKE} className="btn-icon-left" />}
                            onClick={handleClick}
                        />
                    </div>
                </div>
            </div>
            {
                isAudioPlay && <TextSpeechVoice voiceText={voiceText} ref={textSpeechRef} />
            }
        </React.Fragment>
    );
};

export default ApplicationStartUp;
