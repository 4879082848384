export const LOCAL_URL = "http://192.168.0.101:3001";
export const LOCAL_SOCEKT_URL = "ws://192.168.0.101:8080";
export const URL = {
  // DEVELOPMENT URL (development)
  BASE_URL_DEVELOPMENT: `${LOCAL_URL}/api/v1`,
  BASE_URL_EMAIL_VERIFICATION_DEVELOPMENT: `${LOCAL_URL}/api/v1`,
  // BASE_URL_AZURE_DEVELOPMENT: "https://instantlicense.azurewebsites.net/api",
  BASE_URL_AZURE_DEVELOPMENT: "https://sharooqaiservice.azurewebsites.net/api",
  WEB_SOCKET_URL_DEVELOPMENT: LOCAL_SOCEKT_URL,
  // BASE_URL_DEVELOPMENT: "",
  // STAGING URL (staging)
  BASE_URL_STAGING: "https://uat.saeedinstantlicense.com/services/api/v1",
  BASE_URL_EMAIL_VERIFICATION_STAGING: "https://uat.saeedinstantlicense.com/services/api/v1",
  BASE_URL_AZURE_STAGING: "https://sharooqaiservice.azurewebsites.net/api",
  WEB_SOCKET_URL_STAGING: "wss://uat.saeedinstantlicense.com/websocket/",
  // PRODUCTION URL (production)
  BASE_URL_PRODUCTION: "https://saeedinstantlicense.com/services/api/v1",
  BASE_URL_EMAIL_VERIFICATION_PRODUCTION:
    "https://saeedinstantlicense.com/services/api/v1",
  BASE_URL_AZURE_PRODUCTION: "https://sharooqaiservice.azurewebsites.net/api",
  WEB_SOCKET_URL_PRODUCTION: "wss://saeedinstantlicense.com/websocket/",
};
export const ENVIRONMENT = process.env.REACT_APP_ENV;