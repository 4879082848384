import React, { useEffect, useRef, useState } from "react";


//framework
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";

//plugin
import { Formik, useFormikContext } from "formik";
import { toast, ToastContainer } from "react-toastify";

//components
import { Btn, Dialog, IconButton, Input, Spinner } from "../../../components";
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";
import EndSession from "../../../components/end-session";
import TextSpeechVoice from "../../../components/text-to-speech-voice";

//css
import "./index.scss";
import 'react-toastify/dist/ReactToastify.css';

import { companySearchApi, companyValidationApi, extractArabicNameFunctionApi } from "../../../utils/helpers/API/USER_JOURNEY";
import { SERVER_ERROR_DIALOG_DATA } from "../../../services/static-content";
import useDebounce from "../../../utils/common/useDebounce";
import { getData, storeData } from "../../../utils/common/storage-utils";

const CompanyName = ({ onBack, onContinue }) => {

    const nameToolTipRef = useRef(null);
    const [isShowNameToolTip, setIsShowNameToolTip] = useState(false);
    const [violate, setViolate] = useState(false)
    const textSpeechRef = useRef(null);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogData, setDialogData] = useState({});
    const [isloading, setIsloading] = useState(false);
    const [companyValue, setCompanyValue] = useState(null)
    const debounceDelay = 500; // Delay in milliseconds

    const [isAudioPlay, setIsAudioPlay] = useState(null);
    const isInitialMount = useRef(true);

    const [company1Error, setCompany1Error] = useState(null);
    const [company2Error, setCompany2Error] = useState(null);
    const [company3Error, setCompany3Error] = useState(null);
    const [isInputLoader1, setIsInputLoader1] = useState(false);
    const [isInputLoader2, setIsInputLoader2] = useState(false);
    const [isInputLoader3, setIsInputLoader3] = useState(false);

    const companyTranslation = [
        { value: 'Direct' },
        { value: 'Phonetic' }
    ];
    const defaultTranslation = companyTranslation[0].value;


    let setFieldValueFunction = null;

    const handleSelectFieldObject = (setFieldValue) => {
        setFieldValueFunction = setFieldValue
    }

    // handle submit 
    const handleSubmit = (values) => {
        console.log(values, "values");
        setCompanyValue(values);

        let _payload = {
            "companies": [values.company1+" FZE", values.company2+" FZE", values.company3+" FZE"],
            "arabicCompanies": [values.arabicCompany1+" م.م.ح", values.arabicCompany2+" م.م.ح", values.arabicCompany3+" م.م.ح"],
            "radioOption": [values.translationType1, values.translationType2, values.translationType3]
        }

        storeData("companyNamePayload", _payload);

        const _payload2 = {
            key1: `${values.company1+" FZE"}|${values.company2+" FZE"}|${values.company3+" FZE"}`,
            key2: `${values.arabicCompany1+" م.م.ح"}|${values.arabicCompany2+" م.م.ح"}|${values.arabicCompany3+" م.م.ح"}`,
            key3: `${values.company1+" FZE"}|${values.arabicCompany1+" م.م.ح"}`,
        };

        console.log(_payload2, "_payload2")
        companySearch(_payload, _payload2);
        // handleContinueClick()
        // onHandleSubmit(values);
    };

    // handle tooltip
    const handleClickOutside = (event) => {
        if (nameToolTipRef.current && !nameToolTipRef.current.contains(event.target)) {
            // Toggle the tooltip visibility
            setIsShowNameToolTip(false);
        }
    }

    const handleIconClick = () => {
        setIsShowNameToolTip((prev) => !prev);
    };

    // dialog handle
    const handleOpen = () => { setShowDialog(true) };
    const handleClose = () => { setShowDialog(false) };

    useEffect(() => {
        // document.addEventListener("mousedown", handleClickOutside);
        if (isShowNameToolTip) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isShowNameToolTip]);

    const handleSpeechPause = () => {
        if (textSpeechRef.current) {
            textSpeechRef.current.pauseSpeech();
        }
    };

    const handleBackClick = () => {
        handleSpeechPause();
        onBack();
    };
    const handleContinueClick = () => {
        handleSpeechPause();
        onContinue();
        // setViolate(true);
    };
    const submitNewName = () => {
        handleSpeechPause();
        setViolate(false);
    };
    const submitApproval = () => {
        handleSpeechPause();
        onContinue();
    };

    const notifyError = (message) => toast.error(message, {
        autoClose: 10000, // 3 seconds
    });

    // api extractCategoriesFunctionApi
    const getExtractArabicName = async (data, type) => {
        // setIsloading(true);
        try {
            const response = await extractArabicNameFunctionApi(data, type);

            if (response.status === 200) {
                // setIsloading(false);
                return response.data.arabicName
            }
        } catch (error) {
            console.log(error);
            notifyError("Something went wrong. Please try again");
            // handleOpen();
            // setDialogData(SERVER_ERROR_DIALOG_DATA)
            // setIsloading(false);
        }
    };
    
    const getCompanyValidation = async (data) => {
        // setIsloading(true);
        try {
            const response = await companyValidationApi(data);

            if (response.status === 200) {
                return response.data.status
                // setIsloading(false);
            }
        } catch (error) {
            console.log(error);
            notifyError("Something went wrong. Please try again");
        }
    };

    // api extractCategoriesFunctionApi
    const companySearch = async (data, data2) => {
        setIsloading(true);
        // onContinue();
        try {
            const response = await companySearchApi(data);
            if (response.status === 200) {
                console.log(response.data, "companySearch");
                const _response = response.data.data;
                if (_response.length > 0) {
                    const _availableCompany = _response[0]
                    storeData("companyList", response.data);
                    const userData = getData("userData") || {};

                    userData.companiesData = {
                        proposedCompanyNames: data2.key1,
                        proposedCompanyArabicNames: data2.key2,
                        approvedName: `${_availableCompany.companyName}|${_availableCompany.arabicCompanyName}`
                    };
                    storeData("userData", userData);

                    setIsloading(false);
                    handleContinueClick();
                } else {
                    setIsloading(false);
                    notifyError('Company names not available');
                    //show error
                }
            } else {
                setIsloading(false);
            }
        } catch (error) {
            console.log(error);
            notifyError("Something went wrong. Please try again");
            setIsloading(false);
        }
    };

    // Debounced API call
    /* const debouncedApiCall = useDebounce(async (fieldValue, fieldName) => {
        const arabicName = await getExtractArabicName(fieldValue); // Your API call here
        setFieldValueFunction(fieldName, arabicName); // Set the corresponding Arabic name
    }, debounceDelay); */

    const onBlurAPiCall = async (data) => {

        console.log(data, data.inputValue, data.inputArabicName, data.radioValueType, "onBlurAPiCall");
        if(data.inputValue != "") {

            if(data.inputName === "company1") {
                setIsInputLoader1(true);
            } else if(data.inputName === "company2") {
                setIsInputLoader2(true);
            } else if(data.inputName === "company3") {
                setIsInputLoader3(true);
            }

            try {
                
                const arabicNameResponse = await getExtractArabicName(data.inputValue, data.radioValueType.charAt(0)); 
                const arabicName = arabicNameResponse == undefined ? "" : arabicNameResponse;
                const companyValidation = await getCompanyValidation(data.inputValue); 

                
    
                console.log(companyValidation, typeof companyValidation , "companyValidation")
                setFieldValueFunction(data.inputArabicName, arabicName);
                
                if (companyValidation === "false") {
                    // Show error message
                    console.log("companyValidation Yes");
                    
                    // setFieldValueFunction(data.inputName, "");
                    
                    if(data.inputName === "company1") {
                        let _errorPayload = {
                            errorField: data.inputName,
                            error: "Input cannot be accepted"
                        }
                        setCompany1Error(_errorPayload);
                        setIsInputLoader1(false);
                    } else if(data.inputName === "company2") {
                        let _errorPayload = {
                            errorField: data.inputName,
                            error: "Input cannot be accepted"
                        }
                        setCompany2Error(_errorPayload);
                        setIsInputLoader2(false);
                    } else if(data.inputName === "company3") {
                        let _errorPayload = {
                            errorField: data.inputName,
                            error: "Input cannot be accepted"
                        }
                        setCompany3Error(_errorPayload);
                        setIsInputLoader3(false);
                    }

                    // setFieldErrorFuntion({ [data.inputName]: 'Company validation failed. Please check the entered value.' });
                    // setErrorFuntion(data.inputName, 'Company validation failed. Please check the entered value.' );
                } else {
                    // Clear error if validation is successful
                    console.log("companyValidation No")
                    // setErrorFuntion(data.inputName, "");
                    if(data.inputName === "company1") {
                        setCompany1Error(null);
                        setIsInputLoader1(false);
                    } else if(data.inputName === "company2") {
                        setCompany2Error(null);
                        setIsInputLoader2(false);
                    } else if(data.inputName === "company3") {
                        setCompany3Error(null);
                        setIsInputLoader3(false);
                    }
                }
                
                

            } catch (error) {
                console.error("Error in onBlurAPiCall:", error);
                // Optionally set a global error or handle error
            }
        } else {
            setFieldValueFunction(data.inputArabicName, "");
            // setErrorFuntion(data.inputName, "");
            if(data.inputName === "company1") {
                setCompany1Error(null);
                setIsInputLoader1(false);
            } else if(data.inputName === "company2") {
                setCompany2Error(null);
                setIsInputLoader2(false);
            } else if(data.inputName === "company3") {
                setCompany3Error(null);
                setIsInputLoader3(false);
            }
        }

        // getCompanyValidation(data.inputValue, data.selectionValue);
    }

    const handleRadioChange = (fieldValue, fieldName) => {
        setFieldValueFunction(fieldName, fieldValue);
    }

    const voiceText = violate ? 'It appears the name you have entered does not meet our guidelines. Please choose one of the options below to proceed.' : 'Great! Could you please provide the name you would like to use for your company?';

    // handle the audio pause and restart
    const handleResume = (data) => {
        if (textSpeechRef.current) {
            textSpeechRef.current.restartSpeech();
        }
    };

    const handleAudio = (data) => {
        setIsAudioPlay(data);
        if(!data) {
            handleSpeechPause();
        } else {
            handleResume();
        }
        storeData("isAduioPlay", data);
    }

    const getStoreAudioStatus = () => {
        const getAudioStatus = getData("isAduioPlay");
        console.log(getAudioStatus, "getAudioStatus_startup")
        setIsAudioPlay(getAudioStatus);
    }

    useEffect(()=> {
        if (isInitialMount.current) {
            getStoreAudioStatus();

            isInitialMount.current = false; // Set flag to prevent future calls   

            const _getCompanyNamePayload = getData("companyNamePayload");
            if(_getCompanyNamePayload) {
                setFieldValueFunction("company1", _getCompanyNamePayload.companies[0].replace("FZE", "").trim());  
                setFieldValueFunction("company2", _getCompanyNamePayload.companies[1].replace("FZE", "").trim());  
                setFieldValueFunction("company3", _getCompanyNamePayload.companies[2].replace("FZE", "").trim()); 
                 
                setFieldValueFunction("arabicCompany1", _getCompanyNamePayload.arabicCompanies[0].replace("م.م.ح", "").trim());  
                setFieldValueFunction("arabicCompany2", _getCompanyNamePayload.arabicCompanies[1].replace("م.م.ح", "").trim());  
                setFieldValueFunction("arabicCompany3", _getCompanyNamePayload.arabicCompanies[2].replace("م.م.ح", "").trim());  

                setFieldValueFunction("translationType1", _getCompanyNamePayload.radioOption[0]);  
                setFieldValueFunction("translationType2", _getCompanyNamePayload.radioOption[1]);  
                setFieldValueFunction("translationType3", _getCompanyNamePayload.radioOption[2]);  
            }
        }
    
    }, []);

    useEffect(()=> {
        // get isAudio is set
        if(!isAudioPlay) {
            handleSpeechPause();
        } else {
            handleResume();
        }
    }, [isAudioPlay])

    const handleTranslationChange = (inputValue, inputName, inputArabicName, radioValueType, ) => {
        const _payload = {
            inputValue,
            inputName,
            inputArabicName,
            radioValueType
        }
        
        onBlurAPiCall(_payload);
    }

    return (
        <React.Fragment>
            <div className="fullscreen-view-wrap fullscreen-view-overlap bg-tertiary-color">
                <div className="fs-header-wrap">
                    <div className="d-flex justify-content-end">
                        <IconButton
                            src={isAudioPlay ? GLOBAL_IMAGES.SPEAKER_ON_ICON : GLOBAL_IMAGES.SPEAKER_OFF_ICON}
                            style="btn-outline-primary no-hover square-btn mr-20"
                            onClick={()=>{handleAudio(!isAudioPlay)}}
                        />
                        <EndSession handleSessionClose={handleSpeechPause}/>
                    </div>
                    {
                        isloading && (
                            <div className="loader-container">
                                <div className="loader-wrap loader-with-text">
                                    <div className="loader-static">
                                        <Spinner></Spinner>
                                    </div>
                                    <div className="text-wrap pt-20">
                                        <p className="subtitle2 white-color">Updating your company name preferences.</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <Formik
                    initialValues={{
                        company1: "",
                        company2: "",
                        company3: "",
                        arabicCompany1: "",
                        arabicCompany2: "",
                        arabicCompany3: "",
                        translationType1 : "Direct",
                        translationType2 : "Direct",
                        translationType3 : "Direct",
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.company1) {
                            errors.company1 = 'Name is required';
                        }
                        if (!values.company2) {
                            errors.company2 = 'Name is required';
                        }
                        if (!values.company3) {
                            errors.company3 = 'Name is required';
                        }
                        if (values.company1 && values.company2 && values.company1 === values.company2) {
                            // errors.company1 = 'Company 1 must be unique from Company 2';
                            // errors.company2 = 'Company 2 must be unique from Company 1';
                            errors.company1 = 'Name must be unique';
                            errors.company2 = 'Name must be unique';

                        }
                          
                        if (values.company1 && values.company3 && values.company1 === values.company3) {
                            // errors.company1 = 'Company 1 must be unique from Company 3';
                            // errors.company3 = 'Company 3 must be unique from Company 1';
                            errors.company1 = 'Name must be unique';
                            errors.company3 = 'Name must be unique';
                        }
                    
                        if (values.company2 && values.company3 && values.company2 === values.company3) {
                            // errors.company2 = 'Company 2 must be unique from Company 3';
                            // errors.company3 = 'Company 3 must be unique from Company 2';
                            errors.company2 = 'Name must be unique';
                            errors.company3 = 'Name must be unique';
                        }

                        if (!values.arabicCompany1) {
                            errors.arabicCompany1 = 'Name is required';
                        }
                        if (!values.arabicCompany2) {
                            errors.arabicCompany2 = 'Name is required';
                        }
                        if (!values.arabicCompany3) {
                            errors.arabicCompany3 = 'Name is required';
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                    validateOnMount={true}
                    enableReinitialize={true}
                >
                    {({
                        handleChange, handleBlur, handleSubmit, errors, touched, values, setFieldValue, setFieldError, setErrors, setFieldTouched, dirty, isValid
                    }) => (
                        <React.Fragment>
                            <Form className="form-wrap" style={{flex: 1, display: 'flex'}} onSubmit={(e) => { handleSubmit(e); }}>
                                <div className="fs-body-wrap fs-body-wrap-content pt-0" style={{marginBottom: 0}}>
                                    <div className="content-wrap position-relative height-100">
                                        <Container>
                                            <Row>
                                                <Col sm={12}>
                                                    <div className="conversation-list">
                                                        {
                                                            violate ? (
                                                                <div className="conversation-item">
                                                                    <div className="media-item">
                                                                        <div className="circle-logo-small text-center">
                                                                            <img src={GLOBAL_IMAGES.CIRCLE_LOGO} alt="CIRCLE_LOGO" />
                                                                        </div>
                                                                        <div className="media-content">
                                                                            <div className="text-wrap">
                                                                                <p className="subtitle2 font-med mb-2">
                                                                                    {companyValue.company1} <br />
                                                                                    {companyValue.company2} <br />
                                                                                    {companyValue.company3}
                                                                                </p>
                                                                                <p className="subtitle2">
                                                                                    It appears the name you've entered does not meet our guidelines. Please choose one of the options below to proceed.
                                                                                </p>

                                                                                <div className="d-flex">
                                                                                    <Btn
                                                                                        variant="primary"
                                                                                        size="md"
                                                                                        style={'btn-ts-blue-gradient mt-28 mr-20 no-border min-w-160'}
                                                                                        label={'Submit for approval'}
                                                                                        onClick={submitApproval}
                                                                                    />
                                                                                    <Btn
                                                                                        variant="primary"
                                                                                        size="md"
                                                                                        style={'btn-primary-gradient mt-28 min-w-160'}
                                                                                        label={'Submit New Name'}
                                                                                        onClick={submitNewName}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="conversation-item full-width">
                                                                    <div className="media-item">
                                                                        <div className="circle-logo-small text-center mb-40">
                                                                            <img src={GLOBAL_IMAGES.CIRCLE_LOGO} alt="CIRCLE_LOGO" />
                                                                        </div>
                                                                        <div className="media-content">
                                                                            <div className="text-wrap">
                                                                                <p className="subtitle2">{voiceText}</p>
                                                                            </div>
                                                                            <div className="activities-panel pb-0">
                                                                                <div className="mb-32-0">
                                                                                    <img src={GLOBAL_IMAGES.ACTIVITIES_HEADER_SHAPE} alt="ACTIVITIES_HEADER_SHAPE" className="w-100 opacity-50" />
                                                                                </div>
                                                                                <div className="activities-wrap">
                                                                                    <div className="activities-header">
                                                                                        <div className="text-wrap mb-20">
                                                                                                <div className="d-flex justify-content-between tooltip-wrap mb-32" ref={nameToolTipRef}>
                                                                                                    <h4 className="font-med" >Company Names
                                                                                                        <img
                                                                                                            src={GLOBAL_IMAGES.INFO_CIRCLE_ICON}
                                                                                                            className="align-middle cursor-pointer ml-8"
                                                                                                            alt="info-icon"
                                                                                                            onClick={(event) => { handleIconClick(event) }}
                                                                                                        />
                                                                                                    </h4>
                                                                                                    {
                                                                                                        isShowNameToolTip && (
                                                                                                            <div className="tooltip-content">
                                                                                                                <img src={GLOBAL_IMAGES.INFO_WARNING_ICON} className="align-middle" alt="info-icon" />
                                                                                                                <div className="text-wrap">
                                                                                                                    <p className="body1">Company names must appear as entered (same case) and cannot include geographical names, business terms, religious references, animal names, product indications, or any political, cultural, offensive.</p>
                                                                                                                    <p className="body1 mt-20">Refer <a className="white-color span-text-underline" href="https://instantlicense.blob.core.windows.net/emailerimages/company-name-guidelines.pdf" target="_blank">this document</a> for more details.</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="activities-body company-body-form customScroll-shade-grey">

                                                                                        {handleSelectFieldObject(setFieldValue)}
                                                                                        <Container>
                                                                                                <Row>
                                                                                                    <Col sm={12} lg={12}>
                                                                                                        <div className="text-wrap">
                                                                                                            <h6 className="caption text-transform-uppercase mb-8 badge m-0">Preferred Name 1</h6>
                                                                                                        </div>
                                                                                                        <div className="panel-layout mb-24">
                                                                                                            <Row className="d-flex justify-content-between align-items-start">
                                                                                                                <Col sm={6} lg={6} className="d-flex justify-content-start align-items-center">
                                                                                                                    <div className="d-flex justify-align-center">
                                                                                                                        <Input
                                                                                                                            label="Name"
                                                                                                                            type="text"
                                                                                                                            value={values.company1}
                                                                                                                            id="company1"
                                                                                                                            placeholder=""
                                                                                                                            // containerStyle={`mb-32 input-tertiary-color ${(company1Error != null && company1Error.errorField == "company1") && 'input-isInvalid'}` }
                                                                                                                            containerStyle={`input-tertiary-color fw-320`}
                                                                                                                            onChange={async (e) => {
                                                                                                                                handleChange(e); // Update Formik's value
                                                                                                                                const trimmedValue = e.target.value.trimStart(); // Trim leading spaces on change
                                                                                                                                setFieldValue("company1", trimmedValue);
                                                                                                                                setFieldValue("arabicCompany1", "")
                                                                                                                                // debouncedApiCall(e.target.value, "arabicCompany1");
                                                                                                                            }}
                                                                                                                            onBlur={(e)=> {
                                                                                                                                handleBlur(e);

                                                                                                                                const trimmedValue = e.target.value.trim(); // Trim leading and trailing spaces on blur
                                                                                                                                setFieldValue("company1", trimmedValue);
                                                                                                                                const _payload = {
                                                                                                                                    inputValue: trimmedValue,
                                                                                                                                    inputName: "company1",
                                                                                                                                    inputArabicName: "arabicCompany1",
                                                                                                                                    radioValueType: values.translationType1
                                                                                                                                }
                                                                                                                                
                                                                                                                                onBlurAPiCall(_payload);
                                                                                                                            }}
                                                                                                                            // onBlur={handleBlur("company1")}
                                                                                                                            disabled={isInputLoader1}
                                                                                                                            rightContent={
                                                                                                                                <InputGroup.Text className="right-content-group right-spinner-group " >
                                                                                                                                    {
                                                                                                                                        isInputLoader1 ? (
                                                                                                                                            <div className="input-spinner-wrap">
                                                                                                                                                <Spinner/>
                                                                                                                                            </div>
                                                                                                                                        ) : (
                                                                                                                                            <div className="input-spinner-wrap"></div>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                </InputGroup.Text>
                                                                                                                            }

                                                                                                                            isInvalid={
                                                                                                                                company1Error != null && company1Error.errorField == "company1" ? true : errors.company1 && touched.company1
                                                                                                                            }
                                                                                                                            helperText={
                                                                                                                                
                                                                                                                                company1Error != null && company1Error.errorField == "company1" ? "Input cannot be accepted" : errors.company1 && touched.company1 && errors.company1
                                                                                                                            }
                                                                                                                        />
                                                                                                                        <div className={`text-wrap ${company1Error != null && company1Error.errorField == "company1" ? true : errors.company1 && touched.company1 && 'text-error' }`} >
                                                                                                                            <p className="body1 silver-color text-nowrap">&nbsp; FZE</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                                {/* {
                                                                                                                    (company1Error != null && company1Error.errorField == "company1") && (
                                                                                                                        <div className="false invalid-feedback input-error-intouch mt-0">
                                                                                                                            {company1Error.error }
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                } */}
                                                                                                                <Col sm={6} lg={6} className="d-flex justify-content-end align-items-center flex-column">
                                                                                                                    <div className="d-flex justify-content-end w-100">
                                                                                                                        <div className="text-wrap" style={{paddingTop: '2rem'}} >
                                                                                                                            <p className="body1 silver-color">م.م.ح &nbsp;</p>
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            <Input
                                                                                                                                label="اسم"
                                                                                                                                type="text"
                                                                                                                                value={values.arabicCompany1}
                                                                                                                                id="arabicCompany1"
                                                                                                                                placeholder=""
                                                                                                                                readOnly={true}
                                                                                                                                containerStyle={"mb-32 input-tertiary-color text-silver text-right font-sans-reg fw-320"}
                                                                                                                                onChange={handleChange("arabicCompany1")}
                                                                                                                                onBlur={handleBlur("arabicCompany1")}
                                                                                                                                // isInvalid={errors.arabicCompany1 && touched.arabicCompany1}
                                                                                                                                // helperText={errors.arabicCompany1 && touched.arabicCompany1 && errors.arabicCompany1}
                                                                                                                            />
                                                                                                                            <div className="radio-check fw-260">
                                                                                                                                <div className="form-radio-check">
                                                                                                                                    {companyTranslation.map((translation) => (
                                                                                                                                        <Form.Check
                                                                                                                                            key={translation.value}
                                                                                                                                            type="radio"
                                                                                                                                            id={`company1-${translation.value}`}
                                                                                                                                            name="translationType1"
                                                                                                                                            label={translation.value}
                                                                                                                                            value={translation.value}
                                                                                                                                            onChange={(e) => {
                                                                                                                                                handleChange(e);
                                                                                                                                                setFieldValue("arabicCompany1", "")
                                                                                                                                                handleRadioChange(e.target.value, "translationType1");
                                                                                                                                                handleTranslationChange(values.company1, 'company1', 'arabicCompany1', e.target.value)
                                                                                                                                            }}
                                                                                                                                            checked={values.translationType1 === translation.value}
                                                                                                                                        />
                                                                                                                                    ))}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                                
                                                                                                                
                                                                                                            </Row>
                                                                                                        </div>
                                                                                                    </Col>

                                                                                                    <Col sm={12} lg={12}>
                                                                                                            <div className="text-wrap">
                                                                                                                <h6 className="caption text-transform-uppercase mb-8 badge m-0">Preferred Name 2</h6>
                                                                                                            </div>
                                                                                                            <div className="panel-layout mb-24">
                                                                                                                <Row className="d-flex justify-align-between">
                                                                                                                    <Col sm={6} lg={6} className="d-flex justify-content-start align-items-center">
                                                                                                                        <div className="d-flex justify-align-center">
                                                                                                                            <Input
                                                                                                                                label="Name"
                                                                                                                                type="text"
                                                                                                                                value={values.company2}
                                                                                                                                id="company2"
                                                                                                                                placeholder=""
                                                                                                                                containerStyle={`mb-32 fw-320 input-tertiary-color ${(company2Error != null && company2Error.errorField == "company2") && 'input-isInvalid'}` }
                                                                                                                                onChange={async (e) => {
                                                                                                                                    handleChange(e); // Update Formik's value
                                                                                                                                    const trimmedValue = e.target.value.trimStart(); // Trim leading spaces on change
                                                                                                                                    setFieldValue("company2", trimmedValue);
                                                                                                                                    setFieldValue("arabicCompany2", "")
                                                                                                                                    // debouncedApiCall(e.target.value, "arabicCompany2");
                                                                                                                                }}
                                                                                                                                onBlur={(e)=> {
                                                                                                                                    handleBlur(e);
                                                                                                                                    const trimmedValue = e.target.value.trim(); // Trim leading and trailing spaces on blur
                                                                                                                                    setFieldValue("company2", trimmedValue);
                                                                                                                                    const _payload = {
                                                                                                                                        inputValue: trimmedValue,
                                                                                                                                        inputName: "company2",
                                                                                                                                        inputArabicName: "arabicCompany2",
                                                                                                                                        radioValueType: values.translationType2
                                                                                                                                    }
                                                                                                                                    
                                                                                                                                    onBlurAPiCall(_payload);
                                                                                                                                }}
                                                                                                                                disabled={isInputLoader2}
                                                                                                                                rightContent={
                                                                                                                                    <InputGroup.Text className="right-content-group right-spinner-group " >
                                                                                                                                        {
                                                                                                                                            isInputLoader2 ? (
                                                                                                                                                <div className="input-spinner-wrap">
                                                                                                                                                    <Spinner/>
                                                                                                                                                </div>
                                                                                                                                            ) : (
                                                                                                                                                <div className="input-spinner-wrap"></div>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    </InputGroup.Text>
                                                                                                                                }
                                                                                                                                isInvalid={
                                                                                                                                    company2Error != null && company2Error.errorField == "company2" ? true : errors.company2 && touched.company2
                                                                                                                                }
                                                                                                                                helperText={
                                                                                                                                    
                                                                                                                                    company2Error != null && company2Error.errorField == "company2" ? "Input cannot be accepted" : errors.company2 && touched.company2 && errors.company2
                                                                                                                                }
                                                                                                                            />
                                                                                                                            <div className={`text-wrap ${company2Error != null && company2Error.errorField == "company2" ? true : errors.company2 && touched.company2 && 'text-error' }`} >
                                                                                                                                <p className="body1 silver-color text-nowrap">&nbsp; FZE</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                    {/* {
                                                                                                                        (company2Error != null && company2Error.errorField == "company2") && (
                                                                                                                            <div className="false invalid-feedback input-error-intouch mt-0">
                                                                                                                                {company2Error.error }
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    } */}
                                                                                                                    <Col sm={6} lg={6} className="d-flex justify-content-end align-items-center flex-column">
                                                                                                                        <div className="d-flex justify-content-end  w-100">
                                                                                                                            <div className="text-wrap" style={{paddingTop: '2rem'}}>
                                                                                                                                <p className="body1 silver-color text-nowrap">م.م.ح &nbsp;</p>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <Input
                                                                                                                                    label="اسم"
                                                                                                                                    type="text"
                                                                                                                                    value={values.arabicCompany2}
                                                                                                                                    id="arabicCompany2"
                                                                                                                                    placeholder=""
                                                                                                                                    readOnly={true}
                                                                                                                                    containerStyle={"mb-32 fw-320 input-tertiary-color text-silver text-right font-sans-reg"}
                                                                                                                                    onChange={handleChange("arabicCompany2")}
                                                                                                                                    onBlur={handleBlur("arabicCompany2")}
                                                                                                                                    // isInvalid={errors.arabicCompany2 && touched.arabicCompany2}
                                                                                                                                    // helperText={errors.arabicCompany2 && touched.arabicCompany2 && errors.arabicCompany2}
                                                                                                                                />
                                                                                                                                <div className="radio-check fw-260">
                                                                                                                                    <div className="form-radio-check">
                                                                                                                                        {companyTranslation.map((translation) => (
                                                                                                                                            <Form.Check
                                                                                                                                                key={translation.value}
                                                                                                                                                type="radio"
                                                                                                                                                id={`company2-${translation.value}`}
                                                                                                                                                name="translationType2"
                                                                                                                                                label={translation.value}
                                                                                                                                                value={translation.value}
                                                                                                                                                onChange={(e) => {
                                                                                                                                                    handleChange(e);
                                                                                                                                                    // setFieldValue("company2", "")
                                                                                                                                                    setFieldValue("arabicCompany2", "")
                                                                                                                                                    handleRadioChange(e.target.value, "translationType2");
                                                                                                                                                    handleTranslationChange(values.company2, 'company2', 'arabicCompany2', e.target.value)
                                                                                                                                                }}
                                                                                                                                                checked={values.translationType2 === translation.value}
                                                                                                                                            />
                                                                                                                                        ))}
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </div>
                                                                                                        </Col>

                                                                                                        <Col sm={12} lg={12}>
                                                                                                            <div className="text-wrap">
                                                                                                                <h6 className="caption text-transform-uppercase mb-8 badge m-0">Preferred Name 3</h6>
                                                                                                            </div>
                                                                                                            <div className="panel-layout mb-24">
                                                                                                                <Row className="d-flex justify-align-between">
                                                                                                                    <Col sm={6} lg={6} className="d-flex justify-content-start align-items-center">
                                                                                                                        <div className="d-flex justify-align-center">
                                                                                                                            <Input
                                                                                                                                label="Name"
                                                                                                                                type="text"
                                                                                                                                value={values.company3}
                                                                                                                                id="company3"
                                                                                                                                placeholder=""
                                                                                                                                containerStyle={`mb-32 input-tertiary-color fw-320` }
                                                                                                                                onChange={async (e) => {
                                                                                                                                    handleChange(e); // Update Formik's value
                                                                                                                                    const trimmedValue = e.target.value.trimStart(); // Trim leading spaces on change
                                                                                                                                    setFieldValue("company3", trimmedValue);
                                                                                                                                    setFieldValue("arabicCompany3", "")
                                                                                                                                    // debouncedApiCall(e.target.value, "arabicCompany3");
                                                                                                                                }}
                                                                                                                                onBlur={(e)=> {
                                                                                                                                    handleBlur(e);
                                                                                                                                    const trimmedValue = e.target.value.trim(); // Trim leading and trailing spaces on blur
                                                                                                                                    setFieldValue("company3", trimmedValue);
                                                                                                                                    const _payload = {
                                                                                                                                        inputValue: trimmedValue,
                                                                                                                                        inputName: "company3",
                                                                                                                                        inputArabicName: "arabicCompany3",
                                                                                                                                        radioValueType: values.translationType3
                                                                                                                                    }
                                                                                                                                    
                                                                                                                                    onBlurAPiCall(_payload);
                                                                                                                                }}
                                                                                                                                disabled={isInputLoader3}
                                                                                                                                rightContent={
                                                                                                                                    <InputGroup.Text className="right-content-group right-spinner-group " >
                                                                                                                                        {
                                                                                                                                            isInputLoader3 ? (
                                                                                                                                                <div className="input-spinner-wrap">
                                                                                                                                                    <Spinner/>
                                                                                                                                                </div>
                                                                                                                                            ) : (
                                                                                                                                                <div className="input-spinner-wrap"></div>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    </InputGroup.Text>
                                                                                                                                }
                                                                                                                                isInvalid={
                                                                                                                                    company3Error != null && company3Error.errorField == "company3" ? true : errors.company3 && touched.company3
                                                                                                                                }
                                                                                                                                helperText={
                                                                                                                                    
                                                                                                                                    company3Error != null && company3Error.errorField == "company3" ? "Input cannot be accepted" : errors.company3 && touched.company3 && errors.company3
                                                                                                                                }
                                                                                                                            />
                                                                                                                            <div className={`text-wrap ${company3Error != null && company3Error.errorField == "company3" ? true : errors.company3 && touched.company3 && 'text-error' }`} >
                                                                                                                                <p className="body1 silver-color text-nowrap">&nbsp; FZE</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                    {/* {
                                                                                                                        (company3Error != null && company3Error.errorField == "company3") && (
                                                                                                                            <div className="false invalid-feedback input-error-intouch mt-0">
                                                                                                                                {company3Error.error }
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    } */}
                                                                                                                    <Col sm={6} lg={6} className="d-flex justify-content-end align-items-center flex-column">
                                                                                                                        <div className="d-flex justify-content-end w-100">
                                                                                                                            <div className="text-wrap" style={{paddingTop: '2rem'}}>
                                                                                                                                <p className="body1 silver-color text-nowrap">م.م.ح &nbsp;</p>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <Input
                                                                                                                                    label="اسم"
                                                                                                                                    type="text"
                                                                                                                                    value={values.arabicCompany3}
                                                                                                                                    id="arabicCompany3"
                                                                                                                                    placeholder=""
                                                                                                                                    readOnly={true}
                                                                                                                                    containerStyle={"mb-32 fw-320 input-tertiary-color text-silver text-right font-sans-reg"}
                                                                                                                                    onChange={handleChange("arabicCompany3")}
                                                                                                                                    onBlur={handleBlur("arabicCompany3")}
                                                                                                                                    // isInvalid={errors.arabicCompany3 && touched.arabicCompany3}
                                                                                                                                    // helperText={errors.arabicCompany3 && touched.arabicCompany3 && errors.arabicCompany3}
                                                                                                                                />
                                                                                                                                <div className="radio-check fw-260">
                                                                                                                                    <div className="form-radio-check">
                                                                                                                                        {companyTranslation.map((translation) => (
                                                                                                                                            <Form.Check
                                                                                                                                                key={translation.value}
                                                                                                                                                type="radio"
                                                                                                                                                id={`company3-${translation.value}`}
                                                                                                                                                name="translationType3"
                                                                                                                                                label={translation.value}
                                                                                                                                                value={translation.value}
                                                                                                                                                onChange={(e) => {
                                                                                                                                                    handleChange(e);
                                                                                                                                                    // setFieldValue("company3", "")
                                                                                                                                                    setFieldValue("arabicCompany3", "")
                                                                                                                                                    handleRadioChange(e.target.value, "translationType3");
                                                                                                                                                    handleTranslationChange(values.company3, 'company3', 'arabicCompany3', e.target.value)
                                                                                                                                                }}
                                                                                                                                                checked={values.translationType3 === translation.value}
                                                                                                                                            />
                                                                                                                                        ))}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                    
                                                                                                                </Row>
                                                                                                            </div>
                                                                                                        </Col>      
                                                                                                            
                                                                                            </Row>
                                                                                        </Container>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                                <div className="fs-footer-wrap">
                                    <div className="btn-wrap btn-flex-between">
                                        <Btn
                                            size="lg"
                                            label={'Back'}
                                            type={"button"}
                                            iconPosition="left"
                                            style="btn-outline-primary no-hover"
                                            leftIcon={<img src={GLOBAL_IMAGES.ARROW_LEFT_ICON} alt={GLOBAL_IMAGES.ARROW_LEFT_ICON} className="btn-icon-left" />}
                                            onClick={()=>{handleBackClick()}}
                                        />
                                        <Btn
                                            variant="primary"
                                            style={'btn-primary-gradient'}
                                            size="lg"
                                            type={"submit"}
                                            label={'Continue'}
                                            disabled={ !(values.company1.length != 0 && values.company2.length != 0 && values.company3.length != 0) 
                                                || !(values.arabicCompany1.length != 0 && values.arabicCompany2.length != 0 && values.arabicCompany3.length != 0)
                                                || !(values.company1 != values.company2 && values.company1 != values.company3 && values.company2 != values.company3)
                                                || (company1Error != null || company2Error != null || company3Error != null) 
                                                || !(!isInputLoader1 && !isInputLoader2 && !isInputLoader3)}
                                            rightIcon={<img src={GLOBAL_IMAGES.ARROW_RIGHT_ICON} alt={GLOBAL_IMAGES.ARROW_RIGHT_ICON} className="btn-icon-right" />}
                                        />
                                    </div>
                                </div>
                            </Form>
                            {/* {
                                console.log(!(values.company1.length != 0 && values.company2.length != 0 && values.company3.length != 0) 
                                || (company1Error != null || company2Error != null || company3Error != null) 
                                || !(!isInputLoader1 && !isInputLoader2 && !isInputLoader3),
                                !(values.company1.length != 0 && values.company2.length != 0 && values.company3.length != 0) 
                                , (company1Error != null || company2Error != null || company3Error != null) 
                                , !(!isInputLoader1 && !isInputLoader2 && !isInputLoader3), !isValid, "button")
                            } */}
                        </React.Fragment>
                    )}
                </Formik>
            </div>
            <Dialog
                show={showDialog}
                close={handleClose}
                title={dialogData.title}
                description={dialogData.description}
                backdrop="static"
                closeButton={true}
                showFooter={false}
                style={'custom-modal'}
                onClick={handleClose}
                btnLeftIcon={dialogData.btnIcon}
                btnLabel={dialogData.btnLabel}
                btnStyle={'btn-primary-gradient mt-32'}
            />
            {
                isAudioPlay && <TextSpeechVoice voiceText={voiceText} ref={textSpeechRef} />
            }
            <ToastContainer />
        </React.Fragment>
    );
};

export default CompanyName;
