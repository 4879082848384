import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Col, Container, InputGroup, Row } from 'react-bootstrap';
import { BackgroundBlock, Btn, InfoAlertCard, InputFile, Spinner } from "../../components";
import { GLOBAL_IMAGES } from '../../assets/images/global-images';
import './upload-document.scss';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import passportDetails from "../../utils/helpers/API/FETCH_PASSPORT";
import { ENVIRONMENT, URL } from '../../utils/config/constants';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { USER_PASSPORT_ERROR_DATA } from '../../services/static-content';
import { getCountries } from '../../utils/helpers/API/COUNTRIES_API';
const UploadDocument = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState("");
    const [searchParams] = useSearchParams();
    const [mail, setmail] = useState(null)
    const [userId, setUserId] = useState(null)
    const [action, setAction] = useState(null)
    const [isPassportError, setIsPassportError] = useState(false);
    const [passportFiles, setPassportFiles] = useState({
        passportFile: null,
    });
    const notifyError = () => toast.error('Check passport expiry date, upload new passport', {
        autoClose: 3000, // 3 seconds
    });

    const [passporturl, setPassporturl] = useState('')

    useEffect(() => {
        setmail(searchParams.get('mail'));
        setUserId(searchParams.get('userId'));
        setAction(searchParams.get('action'));


    }, [])


    const validate = values => {
        const errors = {};
        if (!values.file) {
            errors.file = 'A file is required';
        }
        return errors;
    };
    const dataProcess = (data) => {
        const output = {};

        for (const key in data) {
            if (key === "MachineReadableZone") continue;

            if (key === "ArabicName") {
                output[key] = data[key];
            } else {
                let formattedKey = key.charAt(0).toLowerCase() + key.slice(1);
                let value = data[key].value;

                // Format date fields
                if (formattedKey === "dateOfBirth" || formattedKey === "dateOfExpiration" || formattedKey === "dateOfIssue") {
                    value = value;
                }

                // Use content for Nationality instead of value
                if (formattedKey === "nationality") {
                    value = data[key].content;
                }
                if (formattedKey === "placeOfBirth") {
                    value = data[key].content;
                }

                output[formattedKey] = value;
            }
        }

        let arabicNames = output.ArabicName.split(' / ');
        let processedData = {
            firstName: output.firstName,
            lastName: output.lastName,
            gender: output.sex === 'M' ? 'Male' : 'Female',
            dateOfBirth: output.dateOfBirth,
            nationality: output.nationality,
            passportNumber: output.documentNumber.replace(/\s+/g, ''),
            issuingAuthority: output.issuingAuthority,
            country: output.countryRegion,
            firstNameArabic: arabicNames[0],
            lastNameArabic: arabicNames[1],
            dateOfExpiration: output.dateOfExpiration,
            dateOfExpiration: output.dateOfExpiration,
            dateOfIssue: output.dateOfIssue,
            address: output.placeOfBirth,
            placeOfBirth: output.placeOfBirth,
            city: output.city,
        };

        return processedData;
    };



    const checkPassportExpiry = (date) => {
        const expirationDate = moment(date);
        const today = moment();
        const diffInMonths = expirationDate.diff(today, 'months', true);
        const isSixMonthsOrMore = diffInMonths >= 6;
        console.log('isSixMonthsOrMore', isSixMonthsOrMore);
        return isSixMonthsOrMore;
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', values.file);
        const passportDetailsData = {
            document: values.file
        };
        setPassportFiles({
            passportFile: values.file,
        })

        try {
            const response = await passportDetails(passportDetailsData);
            console.log('API Response:', response);

            if (response.status === 200) {
                const getPassportResponse = response.data?.analysisResult;
                const finalData = dataProcess(getPassportResponse);
                const nationalityRes = await searchCountry(finalData?.country);
                finalData.country = nationalityRes.label
                finalData.nationality = nationalityRes.label
                finalData.issuingAuthority = finalData?.issuingAuthority ? finalData?.issuingAuthority : nationalityRes.label;
                finalData.countryData = nationalityRes
                formData.append('firstName', finalData.firstName);
                formData.append('lastName', finalData.lastName);
                formData.append('gender', finalData.gender);
                formData.append('dateOfBirth', finalData.dateOfBirth);
                formData.append('nationality', nationalityRes.label);
                formData.append('passportNumber', finalData.passportNumber);
                formData.append('issuingAuthority', finalData.issuingAuthority);
                formData.append('country', nationalityRes.label);
                formData.append('firstNameArabic', finalData.firstNameArabic);
                formData.append('lastNameArabic', finalData.lastNameArabic);
                formData.append('dateOfExpiration', finalData.dateOfExpiration);
                formData.append('dateOfIssue', finalData.dateOfIssue);
                formData.append('address', finalData.address);
                formData.append('placeOfBirth', finalData.placeOfBirth);
                formData.append('countryData', JSON.stringify(finalData.countryData));
                console.log('FinalData:', finalData);
                
                

                const checkPassportValid = checkPassportExpiry(finalData.dateOfExpiration);

                if (checkPassportValid) {
                    const result = await axios.post(`${URL["BASE_URL_" + ENVIRONMENT]}/document/documentStatus/${userId}/${action}/${mail}`, formData, {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                    });
                    console.log('API Response:', result.data);
                    setIsLoading(false);

                    // Load the HTML content using axios
                    const htmlResponse = await axios.get('./template/document-upload-success.html');
                    document.write(htmlResponse.data);
                } else {
                    setIsLoading(false);
                    // notifyError();
                    // show The passport uploaded by you has expired
                    setIsPassportError(true);
                    
                }


            } else {
                setIsLoading(false);

                // Load the failure HTML content using axios
                const htmlResponse = await axios.get('./template/document-upload-failed.html');
                document.write(htmlResponse.data);
            }

        } catch (error) {
            setIsLoading(false);
            console.log('Error:', error);

            // Load the error HTML content using axios
            const htmlResponse = await axios.get('./template/document-upload-failed.html');
            document.write(htmlResponse.data);
        }

        setSubmitting(false);


        setSubmitting(false);
    };
    
    const handleUpload = () => {
        setIsPassportError(false);
    }

    const searchCountry = async (value) => {
        try {
            const response = await getCountries(value); // Pass any necessary props here
            if (response?.data?.status === 200) {
                if (response.data.data.length > 0) {
                    console.log('response?.data?', response?.data?.data[0]?.Country);
                    //setNationality(response?.data?.data[0]?.Country);
                    // registerFormData.country = nationality;
                    return { label: response?.data?.data[0]?.Country, value: response?.data?.data[0]?.Id }
                }
            }
        } catch (error) {
            console.error("Error fetching countries:", error);
        } finally {
            setIsLoading(false); // Stop loading state
        }
    };

    return (
        <React.Fragment>
            <div className="screen-wrap bg-tertiary-color">
                <Container>
                    <Row>
                        <Col sm={6}>
                            <div className="login-card-wrap h-100 shape-position-wrap upload-mobile">
                                <div className="logo-wrap">
                                    <img src={GLOBAL_IMAGES.LOGO} alt="LOGO" />
                                </div>
                                <div className="card-wrap z-1 zoom-1">
                                    <div className="card-header">
                                        <div className="login-img-wrap">
                                            <img src={GLOBAL_IMAGES.USER_BAND} alt="USER_BAND" className="w-100" />
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <Formik
                                            initialValues={{
                                                file: null
                                            }}
                                            validate={validate}
                                            onSubmit={handleSubmit}
                                        >
                                            {({
                                                handleChange,
                                                handleBlur,
                                                setFieldValue,
                                                errors,
                                                touched,
                                                isValid,
                                                dirty,
                                                isSubmitting
                                            }) => (
                                                <Form className="form-wrap">
                                                    <Row>
                                                        <Col sm={12}>
                                                            <InputFile
                                                                leftContent={
                                                                    <div className="left-content-group">
                                                                        <img src={GLOBAL_IMAGES.GALERY_ICON} alt="USER_ICON" />
                                                                    </div>
                                                                }
                                                                label="Upload Document"
                                                                type="file"
                                                                id="file"
                                                                containerStyle={"mb-32 input-tertiary-color input-with-group input-overflow-hidden"}
                                                                onChange={(event) => {
                                                                    setFieldValue('file', event.currentTarget.files[0]);
                                                                    setFileName(event.currentTarget.files[0]?.name || "");
                                                                }}
                                                                onBlur={handleBlur}
                                                                isInvalid={errors.file && touched.file}
                                                                helperText={errors.file && touched.file && errors.file}
                                                                value={fileName}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <div className="btn-wrap">
                                                                <Btn
                                                                    variant="secondary"
                                                                    style="btn-primary-gradient w-100"
                                                                    size={"lg"}
                                                                    type="submit"
                                                                    label="Upload Document"
                                                                    disabled={!isValid || !dirty || isSubmitting}
                                                                    rightIcon={<img src={GLOBAL_IMAGES.LINK_ICON} className="btn-icon-right" />}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                                <div className="shape-position-content zoom-1">
                                    <img src={GLOBAL_IMAGES.SQUARE_SHAPE_DOTS} alt="SQUARE_SHAPE_DOTS" className="top-left-corner" />
                                    <img src={GLOBAL_IMAGES.L_SHAPE_DOTS} alt="L_SHAPE_DOTS" className="bottom-right-corner" />
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} className="px-0 d-none d-sm-block">
                            <BackgroundBlock src={GLOBAL_IMAGES.BG_BUILDING} />
                        </Col>
                    </Row>
                </Container>
                {
                    isLoading && (
                        <div className="loader-container">
                            <div className="loader-wrap loader-with-text">
                                <div className="loader-static">
                                    <Spinner></Spinner>
                                </div>
                                <div className="text-wrap pt-20">
                                    <p className="subtitle2 white-color">Fetching passport details</p>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    isPassportError && (
                        <div className="fullscreen-view-wrap fullscreen-view-overlap bg-tertiary-color">
                            <div className={`fs-header-wrap`}>
                                <div className="logo-wrap mb-0">
                                    <img src={GLOBAL_IMAGES.LOGO} alt="LOGO" />
                                </div>
                            </div>
                            <div className="fs-body-wrap">
                                <InfoAlertCard 
                                    data={USER_PASSPORT_ERROR_DATA}
                                    contentStyle="mx-auto mt-40" 
                                />
                            </div>
                            <div className="fs-footer-wrap">
                                <div className="btn-wrap btn-flex-end">
                                    <div>
                                        <Btn
                                            variant="primary"
                                            style="btn-primary-gradient"
                                            size="lg"
                                            label="Upload Again"
                                            leftIcon={<img src={GLOBAL_IMAGES.REDO_ICON} alt="REDO_ICON" className="btn-icon-left" />}
                                            onClick={() => handleUpload()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default UploadDocument;
