import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// framework
import { Container, Row, Col, Card, Form, InputGroup } from "react-bootstrap";

// plugin
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

//components
import { Btn, IconButton, Input, InputDate, Select, Spinner } from "../../../components";
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";
import EndSession from "../../../components/end-session";

// services
import { GENDER_LIST_DATA, DUMMY_LIST_DATA } from "../../../services/static-content";

//css
import "./index.scss";
import TextSpeechVoice from "../../../components/text-to-speech-voice";
import documentLicenseApi from "../../../utils/helpers/API/LICENSE_DOCUMENT";
import { getData, storeData } from "../../../utils/common/storage-utils";

const ApplicationLicense = ({ }) => {

    const [isLoading, setIsLoading] = useState(false);
    // const document = "https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf";
    const userData = getData("userData") || {}; 
    const textSpeechRef = useRef(null);
    const containerRef = useRef(null); // Use ref to access the container

    const [isAudioPlay, setIsAudioPlay] = useState(true);
    const isInitialMount = useRef(true);

    const [document, setDocument] = useState('')
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const navigate = useNavigate();

    const licenseDocument = getData('licenseDocument');

    const handleSpeechPause = () => {
        if (textSpeechRef.current) {
            textSpeechRef.current.pauseSpeech();
        }
    };

    const handleContinueClick = () => {
        handleSpeechPause();
        navigate("/");
    };

    const calculateScale = (page) => {
        if (containerRef.current && page) {
            const containerWidth = containerRef.current.clientWidth;
            const viewport = page.getViewport({ scale: 1 });
            return containerWidth / viewport.width;
        }
        return 1;
    };

    // handle the audio pause and restart
    const handleResume = (data) => {
        if (textSpeechRef.current) {
            textSpeechRef.current.restartSpeech();
        }
    };
    
    const handleAudio = (data) => {
        setIsAudioPlay(data);
        if(!data) {
            handleSpeechPause();
        } else {
            handleResume();
        }
        storeData("isAduioPlay", data);
    }

    const getStoreAudioStatus = () => {
        const getAudioStatus = getData("isAduioPlay");
        console.log(getAudioStatus, "getAudioStatus_startup")
        setIsAudioPlay(getAudioStatus);
    }

    useEffect(()=> {
        if (isInitialMount.current) {
            getStoreAudioStatus();
            
            isInitialMount.current = false; // Set flag to prevent future calls   
        }
    }, []);

    useEffect(()=> {
        // get isAudio is set
        if(!isAudioPlay) {
            handleSpeechPause();
        } else {
            handleResume();
        }
    }, [isAudioPlay])

    useEffect(() => {
        if (licenseDocument) {
            setDocument(licenseDocument);
        }
    }, [licenseDocument]);

    // useEffect(() => {
    //     fetchLicenseData();
    // }, []);
    // // Function to fetch countries
    // const fetchLicenseData = async () => {
    //     const spcUserId = userData?.spcUserId
    //     try {
    //         const response = await documentLicenseApi(spcUserId); // Pass any necessary props here
    //         console.log('response', response?.data?.data?.url);
    //         if (response?.data?.status === 200) {
    //             console.log(response?.data?.data?.url)
    //             setDocument(response?.data?.data?.url);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching countries:", error);
    //     } finally {
    //         setIsLoading(false); // Stop loading state
    //     }
    // };

    const voiceText = 'Congratulations! Your license has been generated.';
    
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

    return (
        <React.Fragment>
            <div className="fullscreen-view-wrap fullscreen-view-overlap bg-tertiary-color">
                <div className="fs-header-wrap">
                    <div className="d-flex justify-content-end">
                        <IconButton
                            src={isAudioPlay ? GLOBAL_IMAGES.SPEAKER_ON_ICON : GLOBAL_IMAGES.SPEAKER_OFF_ICON}
                            style="btn-outline-primary no-hover square-btn mr-20"
                            onClick={()=>{handleAudio(!isAudioPlay)}}
                        />
                        <EndSession handleSessionClose={handleSpeechPause}/>
                    </div>
                </div>
                <div className="fs-body-wrap fs-body-hidden fs-body-wrap-content pt-0">
                    <div className="content-wrap position-relative height-100">
                        <div className="conversation-list h-100 w-100">
                            {/* <div className="conversation-list h-100"> */}
                                {/* <div className="conversation-item full-width"> */}
                                    <div className="media-item" style={{display: 'flex', padding: '0 3rem 1rem', gap: '1rem'}}>
                                        <div className="circle-logo-small text-center mb-40">
                                            <img src={GLOBAL_IMAGES.CIRCLE_LOGO} alt="CIRCLE_LOGO" />
                                        </div>
                                        <div className="media-content" style={{display: 'flex', flexDirection: 'column', flex: 1, gap: '1rem'}}>
                                            <div className="text-wrap">
                                                <p className="subtitle2">{voiceText}</p>
                                            </div>
                                            <div className="texture-wrap mw-100" style={{display: 'flex', flexDirection: 'column', minHeight: '0', flex: 1}}>
                                                <div className="texture-header">
                                                    <div className="texture-img-wrap"></div>
                                                </div>
                                                <div className="bg-blue-tin-dark-color" style={{minHeight: '0', flex: 1}}>
                                                    <div className="pdf-preview-wrap h-100">
                                                        <div ref={containerRef} className="pdf-wrap">
                                                            {
                                                                document && (
                                                                    
                                                                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                                                                        <Viewer
                                                                            fileUrl={document}
                                                                            defaultScale={1.75}
                                                                            plugins={[defaultLayoutPluginInstance, zoomPluginInstance]}
                                                                            onDocumentLoadSuccess={({ numPages, getPage }) => {
                                                                                const firstPage = getPage(1);
                                                                                firstPage.then((page) => {
                                                                                    const scale = calculateScale(page);
                                                                                    Viewer.setScale(scale);
                                                                                });
                                                                            }}
                                                                        />
                                                                        {/* <Viewer 
                                                                        fileUrl={document} 
                                                                        defaultScale={1}
                                                                        plugins={[defaultLayoutPluginInstance]}
                                                                    /> */}
                                                                    </Worker>
                                                                )
                                                            }
                                                        </div>

                                                        {/* PDF ZOOM BUTTONS */}
                                                        <div className="texture-header" style={{
                                                                alignItems: 'center',
                                                                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                padding: '4px',
                                                            }}>
                                                            <ZoomOutButton />
                                                            {/* <ZoomPopover /> */}
                                                            <ZoomInButton />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                {/* </div> */}
                            {/* </div> */}

                        </div>
                        {/* <Row>
                            <Col sm={12} className="p-0"> */}
                            {/* </Col>
                        </Row> */}
                    </div>
                </div>
                <div className="fs-footer-wrap">
                    <div className="btn-wrap btn-flex-end">
                        <Btn
                            variant="primary"
                            size="lg"
                            style={'btn-primary-gradient'}
                            label={'End Session'}
                            leftIcon={<img src={GLOBAL_IMAGES.TICK_CIRCLE} alt={GLOBAL_IMAGES.TICK_CIRCLE} className="btn-icon-left" />}
                            onClick={handleContinueClick}
                        />
                    </div>
                </div>
            </div>
            <TextSpeechVoice voiceText={voiceText} ref={textSpeechRef} />
            {
                isLoading && (
                    <div className="loader-container">
                        <Spinner></Spinner>
                    </div>
                )
            }
        </React.Fragment>
    );
};

export default ApplicationLicense;
