const GLOBAL_ANIMATION = {
    SOUND_WAVE: require("./sound-wave.json"),
    SUCCESS: require("./success.json"),
    DOCUMENT: require("./document-prepare.json"),
    PROGRESS: require("./progress-bar.json"),
}

export {
    GLOBAL_ANIMATION,
}
