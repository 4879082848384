
import React, { useState } from "react";
import PropTypes from "prop-types";

// framework
import { Form, InputGroup } from "react-bootstrap";

const InfoAlertCard = (props) => {

    const {
        data = null
    } = props

    return (
        <div className={`${props.contentStyle} info-alert-card-wrap`}>
            {
                data.icon && (
                    <div className="img-wrap">
                        <img src={data.icon} alt="info-alert-icon"/>
                    </div>
                )
            }
            
            <div className="text-wrap">
                {data.title && 
                    <p className="f-32 white-color font-med">
                        {data.titleIcon && <img src={data.titleIcon} alt="info-alert-icon" className="text-icon"/>}
                        {data.title}
                    </p>
                }
                {data.description && <h4 className="mt-20 white-color">{data.description}</h4>}
            </div>
        </div>
    )

}

InfoAlertCard.propTypes = {
    data: PropTypes.object,
};

export default InfoAlertCard