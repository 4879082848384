import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, InputGroup } from "react-bootstrap";

const InputFile = ({
  label = "",
  id = "",
  containerStyle = "",
  helperText = "",
  disabled = false,
  isInvalid = false,
  onChange = () => { },
  onBlur = () => { },
  leftContent,
  fileName = "" // Handle fileName as a prop
}) => {
  const [currentFileName, setCurrentFileName] = useState(fileName); // Manage fileName state

  const handleChange = (event) => {
    const file = event.target.files[0];
    setCurrentFileName(file ? file.name : ""); // Update file name state
    onChange(event);
  };

  return (
    <Form.Group
      controlId={id}
      className={`${containerStyle} ${isInvalid && "input-isInvalid"} form-group`}
    >
      {label && (
        <Form.Label>
          {label}
        </Form.Label>
      )}
      <div className="form-control-wrap custom-file-upload">
        {leftContent}
        <InputGroup>
          <Form.Control
            type="file"
            accept=".jpeg, .jpg, .png, .pdf"
            disabled={disabled}
            isInvalid={isInvalid}
            onChange={handleChange}
            onBlur={onBlur}
            className="form-control"
          />
        </InputGroup>
        {!currentFileName ? (
          <div className="input-label">Choose file</div>
        ) : (
            <div className="file-name">{currentFileName}</div> // Display selected file name
        )}
      </div>

      {helperText && (
        <Form.Control.Feedback type="invalid">
          {helperText}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

InputFile.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  containerStyle: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  fileName: PropTypes.string,
  leftContent: PropTypes.object,
};

export default InputFile;
