import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';

//framework
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";

//component
import { GLOBAL_IMAGES } from "../../assets/images/global-images";
import { Btn, Input, InputDate, SelectBox, Spinner } from "../../components";
import { GENDER_LIST_DATA } from "../../services/static-content";
import { toast, ToastContainer } from "react-toastify";

//api
import { registerUserApi } from "../../utils/helpers/API/REGISTER_USER_API";


//utils
import { getData, storeData } from "../../utils/common/storage-utils";

//css
import "./stakeholder.scss";
import 'react-toastify/dist/ReactToastify.css';
import { getCountries } from "../../utils/helpers/API/COUNTRIES_API";
import { extractArabicNameFunctionApi } from "../../utils/helpers/API/USER_JOURNEY";



const StakeholderDetailsForm = () => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const registerFormData = location.state?.params || {};
    console.log('registerFormData', registerFormData);
    const [gender, setGender] = useState(registerFormData.gender ?? null);
    const navigate = useNavigate();
    const [isPassportActive, setIsPassportActive] = useState(null);
    const [isEighteenOrOlder, setIsEighteenOrOlder] = useState(null);
    const textSpeechRef = useRef(null);
    const [nationality, setNationality] = useState('');
    const [isInputLoader1, setIsInputLoader1] = useState(false);
    const [isInputLoader2, setIsInputLoader2] = useState(false);
    const passportError = (message) => toast.error(message, {
        autoClose: 3000, // 7 seconds
    });

    useEffect(() => {
        console.log('gender', gender)
    }, [])


    const handleSubmit = async (values) => {
        setIsLoading(true);
        const userData = getData("userData") || {}; // Ensure userData is initialized

        const dateOfBirth = new Date(values?.dateOfBirth);
        const isEighteenOrOlder = (dob) => {
            const today = new Date();
            const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
            return dob <= eighteenYearsAgo;
        };

        if (!isEighteenOrOlder(dateOfBirth)) {
            passportError('You must be 18 years or older.');
            setIsLoading(false);
            return;
        }

        const passportActive = checkPassportExpiry(registerFormData?.dateOfExpiration);
        const passportExpiryDate = registerFormData?.dateOfExpiration;
        const passportIssueDate = registerFormData?.dateOfIssue;
        const countryData = registerFormData?.countryData

        setIsPassportActive(passportActive);

        // Prepare the updated values
        const firstName = values?.firstName;
        const lastName = values?.lastName;
        const shortName = firstName.charAt(0) + lastName.charAt(0);
        const passportFile = registerFormData?.passportFile;
        const placeOfBirth = registerFormData?.placeOfBirth;

        const updatedValues = {
            ...values,
            dateOfBirth: formatDateToDDMMYYYY(dateOfBirth),
            gender: values['gender'] == "Male" ? 1 : values['gender'] == "Female" ? 2 : '',
            email: userData?.email ? userData?.email : '',
            phone: userData?.mobile ? userData?.mobile : '',
            deviceType: '3',
            file: passportFile,
            placeOfBirth: placeOfBirth,
            isUAEResident: values?.country === 'ARE' ? true : false,
            passportIssueDate: formatDateToDDMMYYYY(values?.dateOfIssue),
            passportExpiryDate: formatDateToDDMMYYYY(values?.dateOfExpiration),
            city: userData?.city ? userData?.city : null,
            nationality: countryData?.value ? countryData?.value : '',
            country: countryData?.value ? countryData?.value : '',
        };
        const firstNameArabic = updatedValues.firstNameArabic;
        const lastNameArabic = updatedValues.lastNameArabic;
        // Remove unnecessary fields
        delete updatedValues.dateOfIssue;
        delete updatedValues.dateOfExpiration;

        // Convert updatedValues to FormData
        const formData = new FormData();
        Object.keys(updatedValues).forEach(key => {
            formData.append(key, updatedValues[key]);
        });

        console.log('FormData payload', formData);

        if (passportActive) {
            try {
                const response = await registerUserApi(formData, userData.newUser);
                const nationalityRes = await searchCountry(countryData?.value);

                if (!userData?.nationalityRes) {
                    userData['nationalityRes'] = nationalityRes;
                    storeData('userData', userData);
                } 
                if (response?.data?.status === 200) {
                    if (userData.newUser) {
                        const spcUserId = response?.data?.data?.id;
                        console.log('response?.data', response?.data);
                        if (spcUserId) {
                            // Update userData with new information
                            const userDataToStore = {
                                ...userData,
                                ...updatedValues,
                                spcUserId,
                                passportActive,
                                passportExpiryDate,
                                passportIssueDate,
                                shortName,
                                firstNameArabic,
                                lastNameArabic
                            };

                            storeData("userData", userDataToStore);
                            console.log('response?.data?', userDataToStore);
                        }

                        setIsLoading(false);
                        navigate("/user-journey");
                    } else {
                        const userData = getData("userData") || {};
                        let newUserData = response?.data?.data?.userData;
                        // Update userData with new information
                        const userDataToStore = {
                            ...userData,
                            ...newUserData,
                            shortName,
                            passportFile
                        };
                        storeData("userData", userDataToStore);
                        console.log('', userDataToStore);
                        setIsLoading(false);
                        navigate("/user-journey");
                    }
                } else {
                    setIsLoading(false);
                    notifyError("Something went wrong. Please try again");
                    console.error("failed", response.data);
                    passportError(response?.data?.message);
                }
            } catch (error) {
                setIsLoading(false);
                notifyError("Something went wrong. Please try again");
                console.error("Error during registration", error);
            }
        } else {
            if (!passportActive) {
                passportError('Passport expiration date is less than 6 months from today.');
            }
            setIsLoading(false);
        }
    };

    const searchCountry = async (value) => {
        try {
            const response = await getCountries(value); // Pass any necessary props here
            if (response?.data?.status === 200) {
                if (response.data.data.length > 0) {
                    console.log('response?.data?', response?.data?.data[0]?.Country);
                    //setNationality(response?.data?.data[0]?.Country);
                    // registerFormData.country = nationality;
                    return { label: response?.data?.data[0]?.Country, value: response?.data?.data[0]?.Id }
                }
            }
        } catch (error) {
            console.error("Error fetching countries:", error);
        } finally {
            setIsLoading(false); // Stop loading state
        }
    };

    const formatDateToDDMMYYYY = (date) => {
        if (!(date instanceof Date) || isNaN(date)) {
            return 'Invalid date';
        }

        // Get the day, month, and year from the date object
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();

        // Return the formatted date as DD/MM/YYYY
        return `${day}/${month}/${year}`;
    };

    const handleGenderChange = (e, setFieldValue) => {
        setFieldValue("gender", e);
        setGender(e);
    };
    const checkPassportExpiry = (date) => {
        const expirationDate = moment(date);
        const today = moment();
        const diffInMonths = expirationDate.diff(today, 'months', true);
        const isSixMonthsOrMore = diffInMonths >= 6;
        console.log('isSixMonthsOrMore', isSixMonthsOrMore);
        return isSixMonthsOrMore;
    };
    const onBack = () => {
        navigate("/new-user");
    };

    let setFieldValueFunction = null;

    const handleSelectFieldObject = (setFieldValue) => {
        setFieldValueFunction = setFieldValue
    }
    const parseDateString = (dateString) => {
        // Define the possible formats
        const formats = ['YYYY-MM-DDTHH:mm:ss.SSSZ', 'DD/MM/YYYY'];

        // Parse the date string using moment with the possible formats
        const dateF = moment(dateString, formats, true);
        console.log('dateString', dateString, new Date(dateF))

        // Return the formatted date in DD/MM/YYYY
        return dateF.isValid() ? new Date(dateF) : 'Invalid date';
    };
    const handleSpeechPause = () => {
        if (textSpeechRef.current) {
            textSpeechRef.current.pauseSpeech();
        }
    };
    const handleBackClick = () => {
        handleSpeechPause();
        onBack();
    };

    const notifyError = (message) => toast.error(message, {
        autoClose: 10000, // 3 seconds
    });

    // api extractCategoriesFunctionApi
    const getExtractArabicName = async (data, type) => {
        // setIsloading(true);
        try {
            const response = await extractArabicNameFunctionApi(data, type);

            if (response.status === 200) {
                // setIsloading(false);
                return response.data.arabicName
            }
        } catch (error) {
            console.log(error);
            notifyError("Something went wrong. Please try again");
        }
    };

    const onBlurAPiCall = async (data) => {

        // console.log(data, data.inputValue, data.inputArabicName, "onBlurAPiCall");
        if(data.inputValue != "") {

            if(data.inputName === "firstName") {
                setIsInputLoader1(true);
            } else if(data.inputName === "lastName") {
                setIsInputLoader2(true);
            }

            try {
                const arabicNameResponse = await getExtractArabicName(data.inputValue, "D"); 
                const arabicName = arabicNameResponse == undefined ? "" : arabicNameResponse;

                setFieldValueFunction(data.inputArabicName, arabicName);

                if(data.inputName === "firstName") {
                    setIsInputLoader1(false);
                } else if(data.inputName === "lastName") {
                    setIsInputLoader2(false);
                }
                
            } catch (error) {
                console.error("Error in onBlurAPiCall:", error);
                // Optionally set a global error or handle error
            }
        } else {
            setFieldValueFunction(data.inputArabicName, "");
            if(data.inputName === "firstName") {
                setIsInputLoader1(false);
            } else if(data.inputName === "lastName") {
                setIsInputLoader2(false);
            }
        }
    }


    return (
        <div className="fullscreen-view-wrap bg-tertiary-color">
            <div className="fs-header-wrap">
                <div className="logo-wrap mb-0">
                    <img src={GLOBAL_IMAGES.LOGO} alt="LOGO" />
                </div>
            </div>
            <Formik
                initialValues={{
                    firstName: registerFormData.firstName || "",
                    lastName: registerFormData.lastName || "",
                    firstNameArabic: registerFormData.firstNameArabic || "",
                    lastNameArabic: registerFormData.lastNameArabic || "",
                    gender: gender,
                    dateOfBirth: registerFormData.dateOfBirth ? parseDateString(registerFormData.dateOfBirth) : null,
                    nationality: registerFormData.nationality || "",
                    passportNumber: registerFormData.passportNumber || "",
                    issuingAuthority: registerFormData.issuingAuthority || "",
                    country: registerFormData.country || "",
                    dateOfIssue: registerFormData.dateOfIssue ? parseDateString(registerFormData.dateOfIssue) : null,
                    dateOfExpiration: registerFormData.dateOfExpiration ? parseDateString(registerFormData.dateOfExpiration) : null,
                    address: registerFormData.address || "",
                }}
                validate={(values) => {
                    const errors = {};
                    if (!values.firstName) {
                        errors.firstName = 'First name is required';
                    }
                    if (!values.lastName) {
                        errors.lastName = 'Last name is required';
                    }
                    if (!values.firstNameArabic) {
                        errors.firstNameArabic = 'First name is required';
                    }
                    if (!values.lastNameArabic) {
                        errors.lastNameArabic = 'Last name is required';
                    }
                    if (!values.gender) {
                        errors.gender = 'Gender is required';
                    }
                    if (!values.dateOfBirth) {
                        errors.dateOfBirth = 'Date of birth is required';
                    }
                    // if (!values.dateOfIssue) {
                    //     errors.dateOfIssue = 'Date of passport issue is required';
                    // }
                    // if (!values.dateOfExpiration) {
                    //     errors.dateOfExpiration = 'Date of passport expiry is required';
                    // }
                    /* if (!values.nationality) {
                        errors.nationality = 'Nationality is required';
                    } */
                    if (!values.passportNumber) {
                        errors.passportNumber = 'Passport Number is required';
                    }
                    if (!values.issuingAuthority) {
                        errors.issuingAuthority = 'Authority is required';
                    }
                    if (!values.address) {
                        errors.address = 'Address is required';
                    }
                    /* if (!values.country) {
                        errors.country = 'Country is required';
                    } */
                    return errors;
                }}
                onSubmit={handleSubmit}
                validateOnMount={true}
            >
                {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched,
                    values,
                    setFieldValue,
                    setFieldTouched,
                    dirty,
                    isValid
                }) => (
                    <Form
                        className="form-wrap"
                        onSubmit={(e) => {
                            setFieldTouched("phoneNumber", true);
                            handleSubmit(e);
                        }}
                    >
                        {handleSelectFieldObject(setFieldValue)}
                        <React.Fragment>
                            <div className="fs-body-wrap fs-body-hidden">
                                <Container>
                                    <Row>
                                        <Col>
                                            <div className="text-wrap">
                                                <h2 className="white-color font-med mb-20 text-uppercase text-center">Stakeholder Details</h2>
                                            </div>
                                            <div className="texture-wrap mx-auto mb-40">
                                                <div className="texture-header">
                                                    <div className="texture-img-wrap"></div>
                                                </div>
                                                <div className="texture-body customScroll-shade-grey">
                                                    <Container>
                                                        <Row>
                                                            <Col lg={6} sm={12}>
                                                                <Input
                                                                    label="First Name"
                                                                    type="text"
                                                                    value={values.firstName}
                                                                    id="firstName"
                                                                    placeholder=""
                                                                    containerStyle={"mb-32 input-tin-blue-color"}
                                                                    onChange={async (e) => {
                                                                        handleChange(e); // Update Formik's value
                                                                        const trimmedValue = e.target.value.trimStart(); // Trim leading spaces on change
                                                                        setFieldValue("firstName", trimmedValue);
                                                                        setFieldValue("firstNameArabic", "")
                                                                        // debouncedApiCall(e.target.value, "arabicCompany1");
                                                                    }}
                                                                    onBlur={(e)=> {
                                                                        handleBlur(e);

                                                                        const trimmedValue = e.target.value.trim(); // Trim leading and trailing spaces on blur
                                                                        setFieldValue("firstName", trimmedValue);
                                                                        const _payload = {
                                                                            inputValue: trimmedValue,
                                                                            inputName: "firstName",
                                                                            inputArabicName: "firstNameArabic",
                                                                        }
                                                                        
                                                                        onBlurAPiCall(_payload);
                                                                    }}
                                                                    rightContent={
                                                                        <InputGroup.Text className="right-content-group right-spinner-group " >
                                                                            {
                                                                                isInputLoader1 ? (
                                                                                    <div className="input-spinner-wrap">
                                                                                        <Spinner/>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="input-spinner-wrap"></div>
                                                                                )
                                                                            }
                                                                        </InputGroup.Text>
                                                                    }
                                                                    isInvalid={errors.firstName && touched.firstName}
                                                                    helperText={
                                                                        errors.firstName &&
                                                                        touched.firstName &&
                                                                        errors.firstName
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col lg={6} sm={12}>
                                                                <Input
                                                                    label="Last Name"
                                                                    type="text"
                                                                    value={values.lastName}
                                                                    id="lastName"
                                                                    placeholder=""
                                                                    containerStyle={"mb-32 input-tin-blue-color"}
                                                                    onChange={async (e) => {
                                                                        handleChange(e); // Update Formik's value
                                                                        const trimmedValue = e.target.value.trimStart(); // Trim leading spaces on change
                                                                        setFieldValue("lastName", trimmedValue);
                                                                        setFieldValue("lastNameArabic", "")
                                                                        // debouncedApiCall(e.target.value, "arabicCompany1");
                                                                    }}
                                                                    onBlur={(e)=> {
                                                                        handleBlur(e);

                                                                        const trimmedValue = e.target.value.trim(); // Trim leading and trailing spaces on blur
                                                                        setFieldValue("lastName", trimmedValue);
                                                                        const _payload = {
                                                                            inputValue: trimmedValue,
                                                                            inputName: "lastName",
                                                                            inputArabicName: "lastNameArabic",
                                                                        }
                                                                        
                                                                        onBlurAPiCall(_payload);
                                                                    }}
                                                                    rightContent={
                                                                        <InputGroup.Text className="right-content-group right-spinner-group " >
                                                                            {
                                                                                isInputLoader2 ? (
                                                                                    <div className="input-spinner-wrap">
                                                                                        <Spinner/>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="input-spinner-wrap"></div>
                                                                                )
                                                                            }
                                                                        </InputGroup.Text>
                                                                    }
                                                                    isInvalid={errors.lastName && touched.lastName}
                                                                    helperText={
                                                                        errors.lastName &&
                                                                        touched.lastName &&
                                                                        errors.lastName
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} sm={12}>
                                                                <Input
                                                                    label="الاسم الأول"
                                                                    type="text"
                                                                    value={values.firstNameArabic}
                                                                    id="firstNameArabic"
                                                                    placeholder=""
                                                                    readOnly={true}
                                                                    containerStyle={"mb-32 input-tin-dark-color text-right font-sans-reg"}
                                                                    onChange={handleChange("firstNameArabic")}
                                                                    onBlur={handleBlur("firstNameArabic")}
                                                                    isInvalid={errors.firstNameArabic && touched.firstNameArabic}
                                                                    helperText={
                                                                        errors.firstNameArabic &&
                                                                        touched.firstNameArabic &&
                                                                        errors.firstNameArabic
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col lg={6} sm={12}>
                                                                <Input
                                                                    label="اسم العائلة"
                                                                    type="text"
                                                                    value={values.lastNameArabic}
                                                                    id="lastNameArabic"
                                                                    placeholder=""
                                                                    readOnly={true}
                                                                    containerStyle={"mb-32 input-tin-dark-color text-right font-sans-reg"}
                                                                    onChange={handleChange("lastNameArabic")}
                                                                    onBlur={handleBlur("lastNameArabic")}
                                                                    isInvalid={errors.lastNameArabic && touched.lastNameArabic}
                                                                    helperText={
                                                                        errors.lastNameArabic &&
                                                                        touched.lastNameArabic &&
                                                                        errors.lastNameArabic
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} sm={12}>
                                                                <SelectBox
                                                                    label="Gender"
                                                                    type="text"
                                                                    id="gender"
                                                                    value={values.gender}
                                                                    placeholder=""
                                                                    containerStyle={"mb-32 input-tin-blue-color icon-right-absolute"}
                                                                    onChange={(e) => handleGenderChange(e, setFieldValue)}
                                                                    onBlur={handleBlur("gender")}
                                                                    isInvalid={errors.gender && touched.gender}
                                                                    helperText={
                                                                        errors.gender &&
                                                                        touched.gender &&
                                                                        errors.gender
                                                                    }
                                                                    data={GENDER_LIST_DATA}
                                                                    rightContent={
                                                                        <InputGroup.Text className="right-content-group">
                                                                            <img src={GLOBAL_IMAGES.ARROW_DOWN_ICON} alt="ARROW_DOWN_ICON" />
                                                                        </InputGroup.Text>
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} sm={12}>
                                                                <InputDate
                                                                    initialDate={values.dateOfBirth}
                                                                    label="Date of Birth"
                                                                    id="dateOfBirth"
                                                                    dateFormat="DD/MM/YYYY"
                                                                    disableDay={(date) => date > new Date()}
                                                                    includeTime={false}
                                                                    onChange={(event) => { setFieldValueFunction("dateOfBirth", event); }}
                                                                    showToday={false}
                                                                    onBlur={handleBlur("dateOfBirth")}
                                                                    containerStyle={"mb-32 input-tin-blue-color"}
                                                                    isInvalid={errors.dateOfBirth && touched.dateOfBirth}
                                                                    helperText={
                                                                        errors.dateOfBirth &&
                                                                        touched.dateOfBirth &&
                                                                        errors.dateOfBirth
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} sm={12}>
                                                                <Input
                                                                    label="Nationality"
                                                                    type="text"
                                                                    value={values.nationality}
                                                                    id="nationality"
                                                                    placeholder=""
                                                                    containerStyle={"mb-32 input-tin-dark-color"}
                                                                    onChange={handleChange("nationality")}
                                                                    onBlur={handleBlur("nationality")}
                                                                    isInvalid={errors.nationality && touched.nationality}
                                                                    helperText={
                                                                        errors.nationality &&
                                                                        touched.nationality &&
                                                                        errors.nationality
                                                                    }
                                                                    readOnly={true}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} sm={12}>
                                                                <Input
                                                                    label="Passport Number"
                                                                    type="text"
                                                                    value={values.passportNumber}
                                                                    id="passportNumber"
                                                                    placeholder=""
                                                                    containerStyle={"mb-32 input-tin-blue-color"}
                                                                    onChange={handleChange("passportNumber")}
                                                                    onBlur={handleBlur("passportNumber")}
                                                                    isInvalid={errors.passportNumber && touched.passportNumber}
                                                                    helperText={
                                                                        errors.passportNumber &&
                                                                        touched.passportNumber &&
                                                                        errors.passportNumber
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col lg={6} sm={12}>
                                                                <Input
                                                                    label="Issuing Authority"
                                                                    type="text"
                                                                    value={values.issuingAuthority}
                                                                    id="issuingAuthority"
                                                                    placeholder=""
                                                                    containerStyle={"mb-32 input-tin-blue-color"}
                                                                    onChange={handleChange("issuingAuthority")}
                                                                    onBlur={handleBlur("issuingAuthority")}
                                                                    isInvalid={errors.issuingAuthority && touched.issuingAuthority}
                                                                    helperText={
                                                                        errors.issuingAuthority &&
                                                                        touched.issuingAuthority &&
                                                                        errors.issuingAuthority
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} sm={12}>
                                                                <Input
                                                                    label="Country"
                                                                    type="text"
                                                                    value={values.country}
                                                                    id="country"
                                                                    placeholder=""
                                                                    containerStyle={"mb-32 input-tin-dark-color"}
                                                                    onChange={handleChange("country")}
                                                                    onBlur={handleBlur("country")}
                                                                    isInvalid={errors.country && touched.country}
                                                                    helperText={
                                                                        errors.country &&
                                                                        touched.country &&
                                                                        errors.country
                                                                    }
                                                                    readOnly={true}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} sm={12}>
                                                                <InputDate
                                                                    initialDate={values.dateOfIssue}
                                                                    label="Issue Date"
                                                                    id="dateOfIssue"
                                                                    dateFormat="DD/MM/YYYY"
                                                                    disableDay={(date) => date > new Date()}
                                                                    includeTime={false}
                                                                    onChange={(event) => { setFieldValueFunction("dateOfIssue", event); }}
                                                                    showToday={false}
                                                                    onBlur={handleBlur("dateOfIssue")}
                                                                    containerStyle={"mb-32 input-tin-dark-color"}
                                                                    isInvalid={errors.dateOfIssue && touched.dateOfIssue}
                                                                    helperText={
                                                                        errors.dateOfIssue &&
                                                                        touched.dateOfIssue &&
                                                                        errors.dateOfIssue
                                                                    }
                                                                    readOnly={true}
                                                                />
                                                            </Col>
                                                            <Col lg={6} sm={12}>
                                                                <InputDate
                                                                    initialDate={values.dateOfExpiration}
                                                                    label="Expiry Date"
                                                                    id="dateOfExpiration"
                                                                    dateFormat="DD/MM/YYYY"
                                                                    disableDay={(date) => date > new Date()}
                                                                    includeTime={false}
                                                                    onChange={(event) => { setFieldValueFunction("dateOfExpiration", event); }}
                                                                    showToday={false}
                                                                    onBlur={handleBlur("dateOfExpiration")}
                                                                    containerStyle={"mb-32 input-tin-dark-color"}
                                                                    isInvalid={errors.dateOfExpiration && touched.dateOfExpiration}
                                                                    helperText={
                                                                        errors.dateOfExpiration &&
                                                                        touched.dateOfExpiration &&
                                                                        errors.dateOfExpiration
                                                                    }
                                                                    readOnly={true}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6} sm={12}>
                                                                <Input
                                                                    label="Address"
                                                                    type="text"
                                                                    value={values.address}
                                                                    id="address"
                                                                    placeholder=""
                                                                    containerStyle={"mb-32 input-tin-blue-color"}
                                                                    onChange={handleChange("address")}
                                                                    onBlur={handleBlur("address")}
                                                                    isInvalid={errors.address && touched.address}
                                                                    helperText={
                                                                        errors.address &&
                                                                        touched.address &&
                                                                        errors.address
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className="fs-footer-wrap">
                                <div className={`btn-wrap btn-flex-between `}>
                                    <Btn
                                        size="lg"
                                        label={'Back'}
                                        iconPosition="left"
                                        style="btn-outline-primary no-hover"
                                        leftIcon={<img src={GLOBAL_IMAGES.ARROW_LEFT_ICON} alt={GLOBAL_IMAGES.ARROW_LEFT_ICON} className="btn-icon-left" />}
                                        onClick={handleBackClick}
                                    />
                                    <Btn
                                        variant="primary"
                                        style="btn-primary-gradient"
                                        size="lg"
                                        label="Confirm"
                                        leftIcon={<img src={GLOBAL_IMAGES.REDO_ICON} alt="REDO_ICON" className="btn-icon-left" />}
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </React.Fragment>

                    </Form>
                )}
            </Formik>
            {
                isLoading && (
                    <div className="loader-container">
                        <div className="loader-wrap loader-with-text">
                            <div className="loader-static">
                                <Spinner></Spinner>
                            </div>
                            <div className="text-wrap pt-20">
                                <p className="subtitle2 white-color">Saving passport details.</p>
                            </div>
                        </div>
                    </div>
                )
            }
            <ToastContainer />
        </div>
    );
};

export default StakeholderDetailsForm;
