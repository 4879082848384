import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
// framework
import { Container, Row, Col, Card, Form, InputGroup } from "react-bootstrap";

// plugin
import { Formik } from "formik";

//components
import { Btn, Dialog, IconButton, Input, InputDate, SelectBox, Spinner } from "../../../components";
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";
import EndSession from "../../../components/end-session";
import { toast, ToastContainer } from "react-toastify";

// services
import { GENDER_LIST_DATA, DUMMY_LIST_DATA, } from "../../../services/static-content";
import { getData, storeData } from "../../../utils/common/storage-utils";

//css
import "./index.scss";
import TextSpeechVoice from "../../../components/text-to-speech-voice";
import AllActivities from "../recomended-activities/all-activities";
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import MultiSelect from "../../../components/multi-select";
import { updateUserApi } from "../../../utils/helpers/API/REGISTER_USER_API";
import { fetchCountries } from "../../../utils/helpers/API/COUNTRIES_API";
import saveDataApi from "../../../utils/helpers/API/SAVE_DATA";
import { extractArabicNameFunctionApi } from "../../../utils/helpers/API/USER_JOURNEY";


const ApplicationPreview = ({ onBack, onContinue }) => {
    const [isEditForm, toggleEditForm] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [isContinue, setIsContinue] = useState(true);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const userData = getData("userData") || {}; 
    const chatInfoData = getData("chatInfo") || {}; 
    const textSpeechRef = useRef(null);
    const [isEighteenOrOlder, setIsEighteenOrOlder] = useState(null);
    const [gender, setGender] = useState(userData.gender ?? null);
    const [isLoading, setIsLoading] = useState(false);

    const [isAudioPlay, setIsAudioPlay] = useState(null);
    const isInitialMount = useRef(true);

    const [isInputLoader1, setIsInputLoader1] = useState(false);
    const [isInputLoader2, setIsInputLoader2] = useState(false);

    const [countries, setCountries] = useState([])
    // Define the default selected options in the parent
    const customerObjects = chatInfoData?.customerCountries?.map(country => ({
        label: country,
        value: country
    })) || [];

    const suppliersObjects = chatInfoData?.supplierCountries?.map(country => ({
        label: country,
        value: country
    })) || [];

    const defaultCustomerCountries = customerObjects.length > 0 ? customerObjects : [];
    const defaultSupplierCountries = suppliersObjects.length > 0 ? suppliersObjects : [];

    const [selectedCustomerCountries, setSelectedCustomerCountries] = useState(defaultCustomerCountries);
    const [selectedSupplierCountries, setSelectedSupplierCountries] = useState(defaultSupplierCountries);
    const [formDisable, setFormDisable] = useState(false);

    const notifySuccess = (message) => toast.success(message, {
        autoClose: 3000, // 3 seconds
    });
    const passportError = (message) => toast.error(message, {
        autoClose: 3000, // 7 seconds
    });

    const navigate = useNavigate();

    let setFieldValueFunction = null;

    const handleSelectFieldObject = (setFieldValue) => {
        setFieldValueFunction = setFieldValue
    }

    // dialog handle
    const handleOpen = () => {setShowDialog(true)};
    const handleClose = () => {
        setShowDialog(false);
        setIsContinue(true)
    };

    // handle submit
    // const handleSubmit = async (values) => {
    //     console.log('asfd');
    // }

    useEffect(() => {
        fetchCountryData();
    }, []);
    // Function to fetch countries
    const fetchCountryData = async () => {
        try {
            const response = await fetchCountries({}); // Pass any necessary props here
            if (response.status === 200) {
                const countryData = response.data.data; // Adjust based on your API's response structure
                setCountries(countryData);
            }
        } catch (error) {
            console.error("Error fetching countries:", error);
        } finally {
            setIsLoading(false); // Stop loading state
        }
    };

    const handleSubmit = async (values) => {
        console.log('values', values);
        setIsLoading(true);

        const userData = getData("userData") || {}; // Ensure userData is initialized

        // Parse and format the date
        const dateOfBirth = new Date(values?.dateOfBirth);
        console.log('Date of Birth:', dateOfBirth);

        // Function to check if the user is 18 years old or older
        const isEighteenOrOlder = (dob) => {
            const today = new Date();
            const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
            return dob <= eighteenYearsAgo;
        };

        // Check if the user is 18 or older
        if (!isEighteenOrOlder(dateOfBirth)) {
            passportError('You must be 18 years or older.');
            setIsLoading(false);
            return; // Stop further execution if the user is under 18
        }

        console.log('FormData payload 1', values.dateOfBirth);

        const updatedValues = {
            firstName: values.firstName,
            lastName: values.lastName,
            firstNameArabic: values.firstNameArabic,
            lastNameArabic: values.lastNameArabic,
            dateOfBirth: formatDateToDDMMYYYY(dateOfBirth),
            gender: values.gender === "Male" ? 1 : 2,
        };

        const firstNameArabic = updatedValues.firstNameArabic;
        const lastNameArabic = updatedValues.lastNameArabic;
        // Remove unnecessary fields
        // delete updatedValues.firstNameArabic;
        // delete updatedValues.lastNameArabic;

        console.log('FormData payload', updatedValues);
        setIsLoading(false);

        try {
            const response = await updateUserApi(updatedValues, userData.spcUserId);
            console.log('response', response);
            setIsLoading(false);

            if (response?.data?.status === 200) {
                const userDataToStore = {
                    ...userData,
                    ...updatedValues,
                    firstNameArabic,
                    lastNameArabic
                };
                storeData("userData", userDataToStore);
                console.log('response?.data?', userDataToStore);
                notifySuccess(response?.data?.message);
                toggleEditForm(true);
            } else {
                console.error("failed", response.data);
                passportError(response?.data?.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error during registration", error);
            passportError('An error occurred during registration. Please try again.');
        }
    };
    const formatDateToDDMMYYYY = (date) => {
        if (!(date instanceof Date) || isNaN(date)) {
            return 'Invalid date';
        }

        // Get the day, month, and year from the date object
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();

        // Return the formatted date as DD/MM/YYYY
        return `${day}/${month}/${year}`;
    };
    // handleGenderChange
    const handleGenderChange = (e, setFieldValue) => {
        setFieldValueFunction("gender", e);
        setGender(e)
    }

    const handleSpeechPause = () => {
        if (textSpeechRef.current) {
            textSpeechRef.current.pauseSpeech();
        }
    };
    const handleContinueClick = () => {
        handleSpeechPause();
        saveData()
    };
    const editForm = () => {
        toggleEditForm(!toggleEditForm);
    };

    const handleActivites = (data) => {
        setSelectedActivity(data);
        setIsContinue(false);
    }

    const saveActivitesData = () => {
        console.log("handleActivites", selectedActivity);
        const chatInfo = getData("chatInfo") || {};
        // console.log("chatInfo123", chatInfo, selectedActivity.activitycode);
        chatInfo.activityCode = selectedActivity.activitycode.toString();
        chatInfo.activityName = selectedActivity.englishname;
        storeData("chatInfo", chatInfo);

        const userData = getData("userData") || {};
        userData.activities.activitycode = selectedActivity.activitycode.toString();
        userData.activities.englishname = selectedActivity.englishname;
        storeData("userData", userData);

        getSelectedActivity();
    }

    const getSelectedActivity = () => {
        console.log(selectedActivity, "selectedActivity");
        const _businessActivityText = `${selectedActivity.activitycode} - ${selectedActivity.englishname}`;
        // console.log(_businessActivityText, "_businessActivityText");
        setFieldValueFunction("businessActivity", _businessActivityText)
        handleClose();
    }
    
    const parseDateString = (dateString) => {
        // Define the possible formats
        const formats = ['YYYY-MM-DDTHH:mm:ss.SSSZ', 'DD/MM/YYYY'];

        // Parse the date string using moment with the possible formats
        const dateF = moment(dateString, formats, true);
        // Return the formatted date in DD/MM/YYYY
        return dateF.isValid() ? new Date(dateF) : 'Invalid date';
    };


    const handleBackClick = () => {
        handleSpeechPause();
        onBack();
    };

    const getSuppliers = (selected) => {
        setSelectedSupplierCountries(selected);
        // Extract the 'label' values from the selected array
        const labels = selected.map(item => item.label);
        console.log('Selected countries:', labels);

        const chatInfo = getData("chatInfo") || {};
        chatInfo.supplierCountries = labels;
        storeData("chatInfo", chatInfo);
    };

    const getCustomers = (selected) => {
        setSelectedCustomerCountries(selected);
        // Extract the 'label' values from the selected array
        const labels = selected.map(item => item.label);
        console.log('Selected countries:', labels);

        const chatInfo = getData("chatInfo") || {};
        chatInfo.customerCountries = labels;
        storeData("chatInfo", chatInfo);
    };
    const showErrorCountry = (e) => {
        console.log('eeeeeeee', e)
        setFormDisable(e)
    }

    const saveData = async () => {
        console.log('saveData');
        const chatInfo = getData("chatInfo") || {};
        const activityCode = chatInfo?.activityCode;

        const customerCountries = chatInfo?.customerCountries;
        const supplierCountries = chatInfo?.supplierCountries;

        // Filter the countries array to find matches for customerCountries and map to get the value property
        const customerCountryValues = countries
            .filter((item) => customerCountries.includes(item.label))
            .map((item) => item.id);

        // Filter the countries array to find matches for supplierCountries and map to get the value property
        const supplierCountryValues = countries
            .filter((item) => supplierCountries.includes(item.label))
            .map((item) => item.id);

        console.log("Customer Country Values:", customerCountryValues);
        console.log("Supplier Country Values:", supplierCountryValues);


        const payload = {
            "userId": userData?.spcUserId,
            "proposedCompanyNames": userData?.companiesData?.proposedCompanyNames,
            "proposedCompanyArabicNames": userData?.companiesData?.proposedCompanyArabicNames,
            "approvedName": userData?.companiesData?.approvedName,
            "nobListUpTo5KeyCustomersMarket": customerCountryValues,
            "nobListUpTo5KeySuppliersMarket": supplierCountryValues,
            "businessActivities": activityCode
        }
        storeData('saveFormData', payload);
        navigate("/payment-initiation");
    };



    // handle the audio pause and restart
    const handleResume = (data) => {
        if (textSpeechRef.current) {
            textSpeechRef.current.restartSpeech();
        }
    };

    const handleAudio = (data) => {
        setIsAudioPlay(data);
        if(!data) {
            handleSpeechPause();
        } else {
            handleResume();
        }
        storeData("isAduioPlay", data);
    }

    const getStoreAudioStatus = () => {
        const getAudioStatus = getData("isAduioPlay");
        console.log(getAudioStatus, "getAudioStatus_startup")
        setIsAudioPlay(getAudioStatus);
    }

    useEffect(()=> {
        if (isInitialMount.current) {
            // getStoreAudioStatus()
            
            isInitialMount.current = false; // Set flag to prevent future calls
        }
    }, []);

    const notifyError = (message) => toast.error(message, {
        autoClose: 10000, // 3 seconds
    });

    // api extractCategoriesFunctionApi
    const getExtractArabicName = async (data, type) => {
        // setIsloading(true);
        try {
            const response = await extractArabicNameFunctionApi(data, type);

            if (response.status === 200) {
                // setIsloading(false);
                return response.data.arabicName
            }
        } catch (error) {
            console.log(error);
            notifyError("Something went wrong. Please try again");
        }
    };

    const onBlurAPiCall = async (data) => {

        // console.log(data, data.inputValue, data.inputArabicName, "onBlurAPiCall");
        if(data.inputValue != "") {

            if(data.inputName === "firstName") {
                setIsInputLoader1(true);
            } else if(data.inputName === "lastName") {
                setIsInputLoader2(true);
            }

            try {
                const arabicNameResponse = await getExtractArabicName(data.inputValue, "D"); 
                const arabicName = arabicNameResponse == undefined ? "" : arabicNameResponse;

                setFieldValueFunction(data.inputArabicName, arabicName);

                if(data.inputName === "firstName") {
                    setIsInputLoader1(false);
                } else if(data.inputName === "lastName") {
                    setIsInputLoader2(false);
                }
                
            } catch (error) {
                console.error("Error in onBlurAPiCall:", error);
                // Optionally set a global error or handle error
            }
        } else {
            setFieldValueFunction(data.inputArabicName, "");
            if(data.inputName === "firstName") {
                setIsInputLoader1(false);
            } else if(data.inputName === "lastName") {
                setIsInputLoader2(false);
            }
        }
    }

    const voiceText = isEditForm ? 'Please review your application before you submit it.' : 'Which section of your application would you like to edit?';

    return (
        <React.Fragment>
            <div className="fullscreen-view-wrap fullscreen-view-overlap bg-tertiary-color">
                <div className="fs-header-wrap">
                    <div className="d-flex justify-content-end">
                        {/* <IconButton
                            src={isAudioPlay ? GLOBAL_IMAGES.SPEAKER_ON_ICON : GLOBAL_IMAGES.SPEAKER_OFF_ICON}
                            style="btn-outline-primary no-hover square-btn mr-20"
                            onClick={()=>{handleAudio(!isAudioPlay)}}
                        /> */}
                        <EndSession handleSessionClose={handleSpeechPause}/>
                    </div>
                </div>
                <Formik
                    initialValues={{
                        firstName: userData.firstName || "",
                        lastName: userData.lastName || "",
                        firstNameArabic: userData.firstNameArabic || "",
                        lastNameArabic: userData.lastNameArabic || "",
                        // gender: gender,
                        gender: userData.gender == 1 ? "Male" : userData.gender == 2 ? "Female" : '',
                        dateOfBirth: userData.dateOfBirth ? parseDateString(userData.dateOfBirth) : null,
                        nationality: userData.nationalityRes.label || "",
                        passportNumber: userData.passportNumber || "",
                        issuingAuthority: userData.issuingAuthority || "",
                        country: userData.nationalityRes.label || "",
                        dateOfIssue: userData.passportIssueDate ? parseDateString(userData.passportIssueDate) : null,
                        dateOfExpiration: userData.passportExpiryDate ? parseDateString(userData.passportExpiryDate) : null,
                        address: userData.address || "",
                        businessActivity: `${userData?.activities?.activitycode} - ${userData?.activities?.englishname}`,
                        supplier: selectedSupplierCountries,
                        customer: selectedCustomerCountries,
                        companyName: userData?.companiesData?.approvedName.split('|')[0]
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.firstName) {
                            errors.firstName = 'First name is required';
                        }
                        if (!values.lastName) {
                            errors.lastName = 'Last name is required';
                        }
                        if (!values.firstNameArabic) {
                            errors.firstNameArabic = 'First name is required';
                        }
                        if (!values.lastNameArabic) {
                            errors.lastNameArabic = 'Last name is required';
                        }
                        if (!values.gender) {
                            errors.gender = 'Gender is required';
                        }
                        if (!values.dateOfBirth) {
                            errors.dateOfBirth = 'Date of birth is required';
                        }
                        if (!values.dateOfIssue) {
                            errors.dateOfIssue = 'Date of passport issue is required';
                        }
                        if (!values.dateOfExpiration) {
                            errors.dateOfExpiration = 'Date of passport expiry is required';
                        }
                        /* if (!values.nationality) {
                            errors.nationality = 'Nationality is required';
                        } */
                        if (!values.passportNumber) {
                            errors.passportNumber = 'Passport Number is required';
                        }
                        if (!values.issuingAuthority) {
                            errors.issuingAuthority = 'Authority is required';
                        }
                        if (!values.address) {
                            errors.address = 'Address is required';
                        }
                        if (!values.businessActivity) {
                            errors.businessActivity = 'Select business activity';
                        }
                        if (!values.companyName) {
                            errors.companyName = 'Company name is required';
                        }
                        /* if (!values.country) {
                            errors.country = 'Country is required';
                        } */
                        return errors;
                    }}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                    validateOnMount={true}
                >
                    {({
                        handleChange, handleBlur, handleSubmit, errors, touched, values, setFieldValue, dirty, isValid
                    }) => (
                        <Form className="form-wrap" style={{flex: 1, display: 'flex', flexDirection: 'column'}}
                            onSubmit={(e) => {
                                e.preventDefault(); // Prevent the default form submission if you're handling it manually
                                handleSubmit(e);
                            }}
                        > 
                            {handleSelectFieldObject(setFieldValue)}
                            <React.Fragment>
                                <div className="fs-body-wrap fs-body-hidden fs-body-wrap-content pt-0" style={{margin: 0}}>
                                    <div className="content-wrap position-relative height-100 " style={{alignItems: 'initial', padding: '0 3rem'}}>
                                        <Row>
                                            <Col sm={12} className="p-0">
                                                <div className="conversation-list">
                                                    <div className="conversation-item full-width">
                                                        <div className="media-item">
                                                            <div className="circle-logo-small text-center mb-40">
                                                                <img src={GLOBAL_IMAGES.CIRCLE_LOGO} alt="CIRCLE_LOGO" />
                                                            </div>
                                                            <div className="media-content">
                                                                <div className="text-wrap mb-24">
                                                                    <p className="subtitle2">{voiceText}</p>
                                                                </div>
                                                                <div className="texture-wrap mw-100 mb-40">
                                                                    <div className="texture-header">
                                                                        <div className="texture-img-wrap" style={{height: '60px'}}></div>
                                                                    </div>
                                                                    <div className="texture-section-wise bg-blue-tin-dark-color">
                                                                        <div className="text-wrap mb-24 d-flex justify-content-between align-items-center">
                                                                            <h4 className="white-color">Application Review</h4> 
                                                                            {
                                                                                isEditForm ? (
                                                                                    <Btn
                                                                                        size="md"
                                                                                        label={'Edit'}
                                                                                        iconPosition="left"
                                                                                        type={"button"}
                                                                                        style="btn-outline-primary no-hover"
                                                                                        leftIcon={<img src={GLOBAL_IMAGES.EDIT_ICON} alt="EDIT_ICON" className="btn-icon-left" />}
                                                                                        onClick={() => { editForm() }}
                                                                                    />
                                                                                ) : (
                                                                                    <Btn
                                                                                        size="md"
                                                                                        label={'Save'}
                                                                                        iconPosition="left"
                                                                                            type={"button"}
                                                                                        style="btn-primary-gradient"
                                                                                            disabled={formDisable}
                                                                                        leftIcon={<img src={GLOBAL_IMAGES.SAVE_ICON} alt="EDIT_ICON" className="btn-icon-left" />}
                                                                                            onClick={handleSubmit}
                                                                                    />
                                                                                )
                                                                            }
                                                                            
                                                                        </div>
                                                                        <div className="texture-body customScroll-shade-blue">
                                                                            <Container className="p-0">
                                                                                <Row>
                                                                                    <Col sm={12}>
                                                                                        <div className="text-wrap divider-wrap text-uppercase d-flex align-items-center">
                                                                                            <img src={GLOBAL_IMAGES.PERSONAL_CARD_ICON} alt="personal-card-icon" className="mr-8"/> STAKEHOLDER DETAILS
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <Input
                                                                                            label="First Name"
                                                                                            type="text"
                                                                                            value={values.firstName}
                                                                                            id="firstName"
                                                                                            placeholder=""
                                                                                            containerStyle={`mb-32 ${isEditForm ? 'input-tin-dark-color' : 'input-tin-blue-color'}`}
                                                                                            onChange={async (e) => {
                                                                                                handleChange(e); // Update Formik's value
                                                                                                const trimmedValue = e.target.value.trimStart(); // Trim leading spaces on change
                                                                                                setFieldValue("firstName", trimmedValue);
                                                                                                setFieldValue("firstNameArabic", "")
                                                                                                // debouncedApiCall(e.target.value, "arabicCompany1");
                                                                                            }}
                                                                                            onBlur={(e)=> {
                                                                                                handleBlur(e);

                                                                                                const trimmedValue = e.target.value.trim(); // Trim leading and trailing spaces on blur
                                                                                                setFieldValue("firstName", trimmedValue);
                                                                                                const _payload = {
                                                                                                    inputValue: trimmedValue,
                                                                                                    inputName: "firstName",
                                                                                                    inputArabicName: "firstNameArabic",
                                                                                                }
                                                                                                
                                                                                                onBlurAPiCall(_payload);
                                                                                            }}
                                                                                            rightContent={
                                                                                                <InputGroup.Text className="right-content-group right-spinner-group " >
                                                                                                    {
                                                                                                        isInputLoader1 ? (
                                                                                                            <div className="input-spinner-wrap">
                                                                                                                <Spinner/>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <div className="input-spinner-wrap"></div>
                                                                                                        )
                                                                                                    }
                                                                                                </InputGroup.Text>
                                                                                            }
                                                                                            isInvalid={errors.firstName && touched.firstName}
                                                                                            helperText={
                                                                                                errors.firstName &&
                                                                                                touched.firstName &&
                                                                                                errors.firstName
                                                                                            }
                                                                                            readOnly={isEditForm}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <Input
                                                                                            label="Last Name"
                                                                                            type="text"
                                                                                            value={values.lastName}
                                                                                            id="lastName"
                                                                                            placeholder=""
                                                                                            containerStyle={`mb-32 ${isEditForm ? 'input-tin-dark-color' : 'input-tin-blue-color'}`}
                                                                                            onChange={async (e) => {
                                                                                                handleChange(e); // Update Formik's value
                                                                                                const trimmedValue = e.target.value.trimStart(); // Trim leading spaces on change
                                                                                                setFieldValue("lastName", trimmedValue);
                                                                                                setFieldValue("lastNameArabic", "")
                                                                                                // debouncedApiCall(e.target.value, "arabicCompany1");
                                                                                            }}
                                                                                            onBlur={(e)=> {
                                                                                                handleBlur(e);

                                                                                                const trimmedValue = e.target.value.trim(); // Trim leading and trailing spaces on blur
                                                                                                setFieldValue("lastName", trimmedValue);
                                                                                                const _payload = {
                                                                                                    inputValue: trimmedValue,
                                                                                                    inputName: "lastName",
                                                                                                    inputArabicName: "lastNameArabic",
                                                                                                }
                                                                                                
                                                                                                onBlurAPiCall(_payload);
                                                                                            }}
                                                                                            rightContent={
                                                                                                <InputGroup.Text className="right-content-group right-spinner-group " >
                                                                                                    {
                                                                                                        isInputLoader2 ? (
                                                                                                            <div className="input-spinner-wrap">
                                                                                                                <Spinner/>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <div className="input-spinner-wrap"></div>
                                                                                                        )
                                                                                                    }
                                                                                                </InputGroup.Text>
                                                                                            }
                                                                                            isInvalid={errors.lastName && touched.lastName}
                                                                                            helperText={
                                                                                                errors.lastName &&
                                                                                                touched.lastName &&
                                                                                                errors.lastName
                                                                                            }
                                                                                            readOnly={isEditForm}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <Input
                                                                                            label="الاسم الأول"
                                                                                            type="text"
                                                                                            value={values.firstNameArabic}
                                                                                            id="firstNameArabic"
                                                                                            placeholder=""
                                                                                            readOnly={true}
                                                                                            containerStyle={"mb-32 input-tin-dark-color text-right font-sans-reg"}
                                                                                            onChange={handleChange("firstNameArabic")}
                                                                                            onBlur={handleBlur("firstNameArabic")}
                                                                                            isInvalid={errors.firstNameArabic && touched.firstNameArabic}
                                                                                            helperText={
                                                                                                errors.firstNameArabic &&
                                                                                                touched.firstNameArabic &&
                                                                                                errors.firstNameArabic
                                                                                            }
                                                                                        />
                                                                                    </Col>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <Input
                                                                                            label="اسم العائلة"
                                                                                            type="text"
                                                                                            value={values.lastNameArabic}
                                                                                            id="lastNameArabic"
                                                                                            placeholder=""
                                                                                            readOnly={true}
                                                                                            containerStyle={"mb-32 input-tin-dark-color text-right font-sans-reg"}
                                                                                            onChange={handleChange("lastNameArabic")}
                                                                                            onBlur={handleBlur("lastNameArabic")}
                                                                                            isInvalid={errors.lastNameArabic && touched.lastNameArabic}
                                                                                            helperText={
                                                                                                errors.lastNameArabic &&
                                                                                                touched.lastNameArabic &&
                                                                                                errors.lastNameArabic
                                                                                            }
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <SelectBox
                                                                                            label="Gender"
                                                                                            type="text"
                                                                                            id="gender"
                                                                                            value={values.gender}
                                                                                            placeholder=""
                                                                                            containerStyle={`mb-32 ${isEditForm ? 'input-tin-dark-color' : 'input-tin-blue-color'} icon-right-absolute`}
                                                                                            onChange={(e) => handleGenderChange(e, setFieldValue)}
                                                                                            onBlur={handleBlur("gender")}
                                                                                            isInvalid={errors.gender && touched.gender}
                                                                                            helperText={
                                                                                                errors.gender &&
                                                                                                touched.gender &&
                                                                                                errors.gender
                                                                                            }
                                                                                            data={GENDER_LIST_DATA}
                                                                                            rightContent={
                                                                                                <InputGroup.Text className="right-content-group">
                                                                                                    <img src={GLOBAL_IMAGES.ARROW_DOWN_ICON} alt="ARROW_DOWN_ICON" />
                                                                                                </InputGroup.Text>
                                                                                            }
                                                                                            readOnly={isEditForm}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <InputDate
                                                                                            initialDate={values.dateOfBirth}
                                                                                            label="Date of Birth"
                                                                                            id="dateOfBirth"
                                                                                            dateFormat="DD/MM/YYYY"
                                                                                            disableDay={(date) => date > new Date()}
                                                                                            includeTime={false}
                                                                                            onChange={(event) => { setFieldValueFunction("dateOfBirth", event); }}
                                                                                            showToday={false}
                                                                                            onBlur={handleBlur("dateOfBirth")}
                                                                                            containerStyle={`mb-32 ${isEditForm ? 'input-tin-dark-color' : 'input-tin-blue-color'}`}
                                                                                            isInvalid={errors.dateOfBirth && touched.dateOfBirth}
                                                                                            helperText={
                                                                                                errors.dateOfBirth &&
                                                                                                touched.dateOfBirth &&
                                                                                                errors.dateOfBirth
                                                                                            }
                                                                                            readOnly={isEditForm}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <Input
                                                                                            label="Nationality"
                                                                                            type="text"
                                                                                            value={values.nationality}
                                                                                            id="nationality"
                                                                                            placeholder=""
                                                                                            containerStyle={"mb-32 input-tin-dark-color"}
                                                                                            onChange={handleChange("nationality")}
                                                                                            onBlur={handleBlur("nationality")}
                                                                                            isInvalid={errors.nationality && touched.nationality}
                                                                                            helperText={
                                                                                                errors.nationality &&
                                                                                                touched.nationality &&
                                                                                                errors.nationality
                                                                                            }
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <Input
                                                                                            label="Passport Number"
                                                                                            type="text"
                                                                                            value={values.passportNumber}
                                                                                            id="passportNumber"
                                                                                            placeholder=""
                                                                                            containerStyle={"mb-32 input-tin-dark-color"}
                                                                                            onChange={handleChange("passportNumber")}
                                                                                            onBlur={handleBlur("passportNumber")}
                                                                                            isInvalid={errors.passportNumber && touched.passportNumber}
                                                                                            helperText={
                                                                                                errors.passportNumber &&
                                                                                                touched.passportNumber &&
                                                                                                errors.passportNumber
                                                                                            }
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <Input
                                                                                            label="Issuing Authority"
                                                                                            type="text"
                                                                                            value={values.issuingAuthority}
                                                                                            id="issuingAuthority"
                                                                                            placeholder=""
                                                                                            containerStyle={"mb-32 input-tin-dark-color"}
                                                                                            onChange={handleChange("issuingAuthority")}
                                                                                            onBlur={handleBlur("issuingAuthority")}
                                                                                            isInvalid={errors.issuingAuthority && touched.issuingAuthority}
                                                                                            helperText={
                                                                                                errors.issuingAuthority &&
                                                                                                touched.issuingAuthority &&
                                                                                                errors.issuingAuthority
                                                                                            }
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <Input
                                                                                            label="Country"
                                                                                            type="text"
                                                                                            value={values.country}
                                                                                            id="country"
                                                                                            placeholder=""
                                                                                            containerStyle={"mb-32 input-tin-dark-color"}
                                                                                            onChange={handleChange("country")}
                                                                                            onBlur={handleBlur("country")}
                                                                                            isInvalid={errors.country && touched.country}
                                                                                            helperText={
                                                                                                errors.country &&
                                                                                                touched.country &&
                                                                                                errors.country
                                                                                            }
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <InputDate
                                                                                            initialDate={values.dateOfIssue}
                                                                                            label="Issue Date"
                                                                                            id="dateOfIssue"
                                                                                            dateFormat="DD/MM/YYYY"
                                                                                            disableDay={(date) => date > new Date()}
                                                                                            includeTime={false}
                                                                                            onChange={(event) => { setFieldValueFunction("dateOfIssue", event); }}
                                                                                            showToday={false}
                                                                                            onBlur={handleBlur("dateOfIssue")}
                                                                                            containerStyle={"mb-32 input-tin-dark-color"}
                                                                                            isInvalid={errors.dateOfIssue && touched.dateOfIssue}
                                                                                            helperText={
                                                                                                errors.dateOfIssue &&
                                                                                                touched.dateOfIssue &&
                                                                                                errors.dateOfIssue
                                                                                            }
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <InputDate
                                                                                            initialDate={values.dateOfExpiration}
                                                                                            label="Expiry Date"
                                                                                            id="dateOfExpiration"
                                                                                            dateFormat="DD/MM/YYYY"
                                                                                            disableDay={(date) => date > new Date()}
                                                                                            includeTime={false}
                                                                                            onChange={(event) => { setFieldValueFunction("dateOfExpiration", event); }}
                                                                                            showToday={false}
                                                                                            onBlur={handleBlur("dateOfExpiration")}
                                                                                            containerStyle={`mb-32 input-tin-dark-color`}
                                                                                            isInvalid={errors.dateOfExpiration && touched.dateOfExpiration}
                                                                                            helperText={
                                                                                                errors.dateOfExpiration &&
                                                                                                touched.dateOfExpiration &&
                                                                                                errors.dateOfExpiration
                                                                                            }
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <Input
                                                                                            label="Address"
                                                                                            type="text"
                                                                                            value={values.address}
                                                                                            id="address"
                                                                                            placeholder=""
                                                                                            containerStyle={`mb-32 ${isEditForm ? 'input-tin-dark-color' : 'input-tin-blue-color'}`}
                                                                                            onChange={handleChange("address")}
                                                                                            onBlur={handleBlur("address")}
                                                                                            isInvalid={errors.address && touched.address}
                                                                                            helperText={
                                                                                                errors.address &&
                                                                                                touched.address &&
                                                                                                errors.address
                                                                                            }
                                                                                            readOnly={isEditForm}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col sm={12}>
                                                                                        <div className="text-wrap divider-wrap text-uppercase d-flex align-items-center">
                                                                                            <img src={GLOBAL_IMAGES.LIST} alt="personal-card-icon" className="mr-8"/> COMPANY DETAILS
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <div onClick={!isEditForm ? handleOpen : undefined}>
                                                                                        {/* <div> */}
                                                                                            <Input
                                                                                                label="Business Activity"
                                                                                                type="text"
                                                                                                value={values.businessActivity}
                                                                                                id="businessActivity"
                                                                                                placeholder=""
                                                                                                containerStyle={`mb-32 ${isEditForm ? 'input-tin-dark-color' : 'input-tin-blue-color'}`}
                                                                                                onChange={handleChange("businessActivity")}
                                                                                                onBlur={handleBlur("businessActivity")}
                                                                                                isInvalid={errors.businessActivity && touched.businessActivity}
                                                                                                helperText={
                                                                                                    errors.businessActivity &&
                                                                                                    touched.businessActivity &&
                                                                                                    errors.businessActivity
                                                                                                }
                                                                                                readOnly={isEditForm}
                                                                                                rightContent={
                                                                                                    <InputGroup.Text className="right-content-group">
                                                                                                        <img src={GLOBAL_IMAGES.EDIT_GREY_ICON} alt="EDIT_GREY_ICON" />
                                                                                                    </InputGroup.Text>
                                                                                                }
                                                                                            />
                                                                                        </div>

                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <MultiSelect
                                                                                            label={'Suppliers'}
                                                                                            countryList={countries}
                                                                                            defaultValues={selectedSupplierCountries}
                                                                                            onSelectionChange={getSuppliers}
                                                                                            isDisabled={isEditForm} 
                                                                                            showError={showErrorCountry}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <MultiSelect
                                                                                            label={'Customers'}
                                                                                            countryList={countries}
                                                                                            defaultValues={selectedCustomerCountries}
                                                                                            onSelectionChange={getCustomers}
                                                                                            isDisabled={isEditForm} 
                                                                                            showError={showErrorCountry}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col lg={6} sm={12}>
                                                                                        <Input
                                                                                            label="Company name"
                                                                                            type="text"
                                                                                            value={values.companyName}
                                                                                            id="companyName"
                                                                                            placeholder=""
                                                                                            containerStyle={`mb-32 input-tin-dark-color`}
                                                                                            onChange={handleChange("companyName")}
                                                                                            onBlur={handleBlur("companyName")}
                                                                                            isInvalid={errors.companyName && touched.companyName}
                                                                                            helperText={
                                                                                                errors.companyName &&
                                                                                                touched.companyName &&
                                                                                                errors.companyName
                                                                                            }
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Container>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="activities-panel">
                                                                    <div className="mb-32">
                                                                        <img src={GLOBAL_IMAGES.ACTIVITIES_HEADER_SHAPE} alt="ACTIVITIES_HEADER_SHAPE" className="w-100 opacity-50" />
                                                                    </div>
                                                                    <div className="activities-wrap">
                                                                        <div className="activities-header">
                                                                            <div className="text-wrap">
                                                                                <div className="d-flex justify-content-between">
                                                                                    <h4 className="font-med">Application Review</h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="activities-body">

                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="fs-footer-wrap">
                                    <div className="btn-wrap btn-flex-between">
                                        <Btn
                                            size="lg"
                                            label={'Back'}
                                            iconPosition="left"
                                            style="btn-outline-primary no-hover"
                                            leftIcon={<img src={GLOBAL_IMAGES.ARROW_LEFT_ICON} alt={GLOBAL_IMAGES.ARROW_LEFT_ICON} className="btn-icon-left" />}
                                            onClick={handleBackClick}
                                        />
                                        <Btn
                                            variant="primary"
                                            style={'btn-primary-gradient'}
                                            size="lg"
                                            label={'Continue'}
                                            rightIcon={<img src={GLOBAL_IMAGES.ARROW_RIGHT_ICON} alt="arrow-right-icon" className="btn-icon-right" />}
                                            onClick={handleContinueClick}
                                            disabled={!isEditForm}
                                            type={"button"}
                                        />

                                    </div>
                                </div>
                            </React.Fragment>
                            </Form>
                    )}
                </Formik>
            </div>
            {
                isAudioPlay && <TextSpeechVoice voiceText={voiceText} ref={textSpeechRef} />
            }
            <Dialog
                show={showDialog}
                close={handleClose}
                title={""}
                description={
                    <AllActivities activities={handleActivites}/>
                }
                backdrop="static"
                closeButton={true}
                showFooter={true}
                style={'custom-modal custom-modal-activities'}
                onClick={handleClose}
                actions={
                    <div className="btn-wrap d-flex justify-content-end">
                        <Btn
                            variant="primary"
                            style={'btn-primary-gradient'}
                            size="lg"
                            label={'Continue'}
                            rightIcon={<img src={GLOBAL_IMAGES.ARROW_RIGHT_ICON} alt={GLOBAL_IMAGES.ARROW_RIGHT_ICON} className="btn-icon-right" />}
                            onClick={()=>{saveActivitesData()}}
                            disabled={isContinue}
                        />
                    </div>
                }
                btnStyle={'btn-primary-gradient mt-32'}
                size="xl"
            />
            {
                isLoading && (
                    <div className="loader-container">
                        <Spinner></Spinner>
                    </div>
                )
            }
            <ToastContainer />
        </React.Fragment>
    );
};

export default ApplicationPreview;
