import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const secondaryColor = '#273E50';
const tertiaryColor = '#1E1E1E';
const white = '#ffffff';
const lightBlueColor = '#111A22';


const MultiSelect = ({ label, countryList, defaultValues, onSelectionChange, isDisabled, showError }) => {
    const [selectedOptions, setSelectedOptions] = useState(defaultValues);
    const [errorMessage, setErrorMessage] = useState('');
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        control: (provided) => ({
            ...provided,
            backgroundColor: secondaryColor,
            border: 'none',
            color: white,
            boxShadow: 'none',
            innerHeight: '50px',
            minHeight: '50px',
            whiteSpace: 'nowrap', // Prevents text from wrapping
            overflow: 'hidden', // Ensures content that overflows is hidden
            textOverflow: 'ellipsis', // Displays "..." when the text overflows
            '&:hover': {
                border: 'none',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: secondaryColor,
            color: white,
            zIndex: 10,
            minHeight: 140,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? lightBlueColor : secondaryColor,
            color: white,
            '&:hover': {
                backgroundColor: lightBlueColor,
                color: white,
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: isDisabled ? secondaryColor : lightBlueColor,
            lineHeight: 1.6,
            color: white,
            borderRadius: 6,
            fontSize: 16,
            marginRight: 2,
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: white,
            backgroundColor: isDisabled ? secondaryColor : lightBlueColor,
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: white,
            border: `1px solid ${white}`,
            borderRadius: 100,
            width: 20,
            height: 20,
            marginTop: 4,
            marginRight: 4,
            marginLeft: 4,
            '&:hover': {
                backgroundColor: secondaryColor,
                color: white
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: white,
        }),
        singleValue: (provided) => ({
            ...provided,
            color: white,
        }),
        input: (provided) => ({
            ...provided,
            color: white, // Typing text color
        }),
    };

    useEffect(() => {
        // Update selected options when defaultValues changes
        setSelectedOptions(defaultValues);
    }, [defaultValues]);

    const handleChange = (selected) => {
        if (selected.length === 0) {
            setErrorMessage('At least one company should be selected.');
            showError(true);
        } else if (selected.length <= 5) {
            setSelectedOptions(selected);
            setErrorMessage(''); // Clear error message if selection is valid
            onSelectionChange(selected);
            showError(false);
        } else {
            setErrorMessage('You cannot select more than 5 options.');
            showError(true);
        }
    };

    return (
        <div>
            <div className={`input-tertiary-color input-with-group form-group`}>
                <label className="form-label">{label}</label>
                <Select 
                    isClearable={false}
                    isMulti
                    options={countryList}
                    value={selectedOptions}
                    onChange={handleChange}
                    placeholder="Select options..."
                    styles={customStyles}
                    isDisabled={isDisabled}
                    className='field-select'
                />
                {errorMessage && <p className="invalid-feedback">{errorMessage}</p>}

            </div>

        </div>
    );
};

export default MultiSelect;
