import React, { useEffect, useState } from "react";
import "./index.scss";
import { GLOBAL_IMAGES } from "../../assets/images/global-images";



const VoiceAnimation = ({ isRecording }) => {
    const [recordingState, setRecordingState] = useState(isRecording);


    useEffect(() => {
        console.log('isRecording', isRecording)
        setRecordingState(isRecording);
    }, [isRecording]);

    return (
        <div className="voice-animation">
            <h6>{recordingState ? 'Recording now' : 'Recording ready'}</h6>
            {recordingState ? (
                <div id="bars">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            ) : (
                <div className="recording-ready">
                    <img src={GLOBAL_IMAGES.RECORDING_WAVE} alt="RECORDING_WAVE" />
                </div>
            )}
        </div>
    );
};

export default VoiceAnimation;
