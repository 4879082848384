import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
// plugin
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Formik } from "formik";
import { PhoneInput } from 'react-international-phone';
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "./new-user.scss";

// styles
import 'react-international-phone/style.css';

// images
import { GLOBAL_IMAGES } from "../../assets/images/global-images";

// components
import { Btn, Input, Spinner } from "../../components";
import UserPassportPreview from "./user-passport-preview";
import UserPassportLink from "./user-passport-link";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// service
import { UNEXPECTED_RESPONSE, UPLOAD_OPTION_DATA, USER_IMG_UNSUPPORT_DATA, USER_PASSPORT_ERROR_DATA } from "../../services/static-content";
import TextSpeechVoice from "../../components/text-to-speech-voice";
import passportDetails from "../../utils/helpers/API/FETCH_PASSPORT";
import { getData, storeData } from "../../utils/common/storage-utils";
import sendUploadViaLinkEmail from "../../utils/helpers/API/UPLOAD_DOCUMENT_VIA_EMAIL";
import { getCountries } from "../../utils/helpers/API/COUNTRIES_API";



const UserCard = ({ onHandleSubmit }) => {
    const voiceText = "Welcome! To get started, please provide your phone number and upload a copy of your passport.";
    const UPLOAD_OPTION = UPLOAD_OPTION_DATA;
    const [isLoading, setIsLoading] = useState(false);
    const [isShowUpload, setIsShowUpload] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [documentType, setDocumentType] = useState(null);
    const [isUploadViaLink, setisUploadViaLink] = useState(false);
    const [isPassportError, setIsPassportError] = useState(false);
    const [userPassportError, setUserPassportError] = useState(null);
    const [uploadViaType, setUploadViaType] = useState("");
    const [fileName, setFileName] = useState("");
    const userData = getData('userData') || {}; // Fallback to an empty object if null or undefined
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [mobilenumber, setMobilenumber] = useState('')
    const uploadRef = useRef(null);
    const fileInputRef = useRef(null);
    const [passportwithnumber, setPassportwithnumber] = useState({
        mobile: '',
        passportFile: null,
    });


    const fileInputCamera = useRef(null);

    const navigate = useNavigate();
    const [isFocused, setIsFocused] = useState(false);

    let setFieldValueFunction = null;

    const handleSelectFieldObject = (setFieldValue) => {
        setFieldValueFunction = setFieldValue
    }


    useEffect(() => {
        // document.addEventListener("mousedown", handleClickOutside);
        if (isShowUpload) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isShowUpload]);

    useEffect(() => {
        // setUploadViaType("UPLOAD_AGAIN_UNSUPPORT");
        // setUserPassportError(USER_IMG_UNSUPPORT_DATA);

        // setShowPreview(true);
        // setIsPassportError(true);

        // setUploadViaType("UPLOAD_VIA_CAMERA")
        // setUserPassportError(USER_PASSPORT_BLUR_DATA);

        // setShowPassportError();

    }, [])

    // handle submit 
    const handleSubmit = (values) => {
        console.log(values, "values");

        // onHandleSubmit(values);
        setShowPreview(true);
        setPassportwithnumber({
            mobile: values.phoneNumber,
            passportFile: values.passportFile,
        });
    };

    // handle upload click
    const handleClickOutside = (event) => {
        if (uploadRef.current && !uploadRef.current.contains(event.target)) {
            setIsShowUpload(false);
        } else {
            setIsShowUpload(true);
        }
    };

    // handle upload click
    const handleUpload = (data) => {
        setUploadViaType(data.type);
        uploadActionVia(data.type);
        console.log("data.type", data)
    }
    const notifySuccess = () => toast.success('Email sent successfully!', {
        autoClose: 3000, // 3 seconds
    });
    const notifyError = () => toast.error('Check passport expiry date, upload new passport', {
        autoClose: 3000, // 3 seconds
    });
    // check action type of upload
    const uploadActionVia = async (data) => {
        console.log("data.type", data)
        if (data == "UPLOAD_VIA_CAMERA") {
            fileInputCamera.current.click();
        } else if (data == "UPLOAD_VIA_DEVICE") {
            fileInputRef.current.click();
        } else if (data == "UPLOAD_VIA_LINK") {
            setisUploadViaLink(true)
            let UniqueId = getData('verifyingUserId');
            let verificationData = { userEmail: userData.email, userId: UniqueId }
            //notifySuccess();
            try {
                const response = await sendUploadViaLinkEmail(verificationData); // Call the login API with form values
                if (response?.status !== 200) {
                    setIsLoading(false);
                    console.log("API Response sendUploadViaLinkEmail:", response);
                }
                console.log("API Response sendUploadViaLinkEmail:", response);
            } catch (error) {
                setIsLoading(false);
            }
        } else if (data == "UPLOAD_ANOTHER_METHOD") {
            setFieldValueFunction("passportFile", null);
            setShowPreview(false);
            setIsPassportError(false);
        } else if (data == "UPLOAD_REVIEW") {
            //navigate("/stakeholder-details");
            reviewPassport();
        }
    }

    // handle file change 
    const handleFileChange = (e, setFieldValue) => {
        const file = e.target.files[0];


        const validExtensions = ["image/jpeg", "image/png", "application/pdf"];
        setSelectedDocument(null);
        setFieldValueFunction("passportFile", null);
        setFieldValueFunction("passportDummyFile", "");
        setPassportwithnumber({
            passportFile: file,
            mobile: userData.mobile
        });

        if (file) {
            console.log("handleFileChange", file, validExtensions.includes(file.type))
            if (validExtensions.includes(file.type)) {
                /* if(file.type == "application/pdf") {
                    setSelectedDocument(e.target.files[0]);
                } else {
                    setSelectedDocument(URL.createObjectURL(e.target.files[0]));
                } */

                setSelectedDocument(URL.createObjectURL(e.target.files[0]));

                setDocumentType(file.type)
                // Set the file name in Formik's state and store the file data in the ref
                setFieldValueFunction("passportDummyFile", file.name);
                setFileName(file.name);
                setFieldValueFunction("passportFile", file); // Store the file object for further processing
            } else {
                console.log("Invalid file format. Please upload a .jpg, .png, or .pdf file.");
            }
        } else {
            console.error('No file selected');
        }
        setIsPassportError(false);
        // setUserPassportError(null);
        // setUploadViaType("")

    };

    const dataProcess = (data) => {
        const output = {};

        for (const key in data) {
            if (key === "MachineReadableZone") continue;

            if (key === "ArabicName") {
                output[key] = data[key];
            } else {
                let formattedKey = key.charAt(0).toLowerCase() + key.slice(1);
                let value = data[key].value;
                console.log('formattedKey', formattedKey, value)

                // Format date fields
                if (formattedKey === "dateOfBirth" || formattedKey === "dateOfExpiration" || formattedKey === "dateOfIssue") {
                    value = value;
                }

                // Use content for Nationality instead of value
                if (formattedKey === "nationality") {
                    value = data[key].content;
                }
                if (formattedKey === "placeOfBirth") {
                    value = data[key].content;
                }
                if (formattedKey === "city") {
                    value = data[key].content;
                }

                output[formattedKey] = value;
                console.log('formattedKey', formattedKey);
            }
        }

        let arabicNames = output.ArabicName.split(' / ');
        let processedData = {
            firstName: output.firstName,
            lastName: output.lastName,
            gender: output.sex === 'M' ? 'Male' : output.sex === 'F' ? 'Female' : '',
            dateOfBirth: output.dateOfBirth,
            nationality: output.nationality,
            passportNumber: output.documentNumber.replace(/\s+/g, ''),
            issuingAuthority: output.issuingAuthority,
            country: output.countryRegion,
            firstNameArabic: arabicNames[0],
            lastNameArabic: arabicNames[1],
            passportFile: passportwithnumber?.passportFile,
            dateOfExpiration: output.dateOfExpiration,
            dateOfIssue: output.dateOfIssue,
            address: output.placeOfBirth,
            placeOfBirth: output.placeOfBirth,
            city: output.city
        };
        console.log('processedData', processedData);
        return processedData;
    };
    const checkPassportExpiry = (date) => {
        const expirationDate = moment(date);
        const today = moment();
        const diffInMonths = expirationDate.diff(today, 'months', true);
        const isSixMonthsOrMore = diffInMonths >= 6;
        console.log('isSixMonthsOrMore', isSixMonthsOrMore);
        return isSixMonthsOrMore;
    };

    const reviewPassport = async () => {
        setIsLoading(true);
        const passportDetailsData = {
            document: passportwithnumber?.passportFile
        };

        try {
            const response = await passportDetails(passportDetailsData);
            if (response.status === 200) {
                const getPassportResponse = response.data?.analysisResult;
                const finalData = dataProcess(getPassportResponse);
                console.log('finalData', finalData);
                const nationalityRes = await searchCountry(finalData?.country);

                // If nationalityRes.value is empty, navigate to the user-journey with 'highrisk' as a param
                if (nationalityRes.value === '') {
                    navigate(`/user-journey`, { state: { params: 'highrisk' } });
                } else {
                    finalData.country = nationalityRes.label;
                    finalData.nationality = nationalityRes.label;
                    finalData.issuingAuthority = finalData?.issuingAuthority ? finalData?.issuingAuthority : nationalityRes.label;
                    finalData.countryData = nationalityRes;
                    console.log('nationalityRes', nationalityRes);
                    const checkPassportValid = checkPassportExpiry(finalData.dateOfExpiration);
                    if (checkPassportValid) {
                        console.log('checkPassportValid', checkPassportValid);
                        console.log('passportwithnumber', passportwithnumber);
                        if (userData) {
                            console.log('passportwithnumber', passportwithnumber);
                            userData['mobile'] = passportwithnumber?.mobile;
                            userData['passportFile'] = passportwithnumber?.passportFile;
                            userData['nationalityRes'] = nationalityRes;
                            storeData('userData', userData);
                        } else {
                            console.error('userData is null or undefined');
                        }

                        setIsLoading(false);
                        // Navigate with the processed data
                        navigate("/stakeholder-details", { state: { params: finalData } });
                    } else {
                        setIsLoading(false);
                        setShowPassportError();
                        setPassportwithnumber({
                            passportFile: null,
                        });
                        // setShowPreview(false);
                        
                    }
                }
            } 
             else {
                setIsLoading(false);
                setIsPassportError(true);
                setUserPassportError(UNEXPECTED_RESPONSE);
                setUploadViaType("UPLOAD_AGAIN_UNSUPPORT");
                setPassportwithnumber({
                    passportFile: null,
                });
            }

        } catch (error) {
            console.log('error', error);
            setIsLoading(false);
            // Handle errors, including status 500
        }
    };


    const searchCountry = async (value) => {
        try {
            const response = await getCountries(value); // Pass any necessary props here
            if (response?.data?.status === 200) {
                if (response.data.data.length > 0) {
                    console.log('response?.data?', response?.data?.data[0]?.Country);
                    //setNationality(response?.data?.data[0]?.Country);
                    // registerFormData.country = nationality;
                    return { label: response?.data?.data[0]?.Country, value: response?.data?.data[0]?.Id }
                } else {
                    return { label: 'No results found', value: '' }
                }
            }
        } catch (error) {
            console.error("Error fetching countries:", error);
        } finally {
            setIsLoading(false); // Stop loading state
        }
    };

    // check condition from preview
    const handlePreviewAction = async (data) => {
        console.log(data, "handlePreviewAction");
        console.log('passportwithnumber.passportFile', passportwithnumber);
        uploadActionVia(data);
        setFieldValueFunction("passportFile", "");
        setFieldValueFunction("passportDummyFile", "");
        // setSelectedDocument(null)
    };

    useEffect(() => {
        console.log(selectedDocument, "selectedDocument");
    }, [selectedDocument])


    const handleFileCameraChange = (e) => {
        const file = e.target.files[0];

        if(file) {
            setShowPreview(true);
            setSelectedDocument(URL.createObjectURL(e.target.files[0]));
    
            setDocumentType("UPLOAD_VIA_CAMERA");
            setFieldValueFunction("passportDummyFile", "Capture via camera");
            setFileName("Capture via camera");
            setFieldValueFunction("passportFile", file);
            console.log('passportFile', file);
            console.log('passportFile', mobilenumber, userData.mobile);
            setPassportwithnumber({
                passportFile: file,
                mobile: userData.mobile ? userData.mobile : mobilenumber
            });
        }
        
    }

    const setShowPassportError = () => {
        setShowPreview(true);
        setIsPassportError(true);
        setUserPassportError(USER_PASSPORT_ERROR_DATA);
        setUploadViaType("UPLOAD_TRY_ANOTHER_UNSUPPORT")
    }

    return (
        <React.Fragment>
            <div className="card-wrap z-1 zoom-1">
                <div className="card-header">
                    <div className="login-img-wrap">
                        <img src={GLOBAL_IMAGES.USER_BAND} alt="USER_BAND" className="w-100" />
                    </div>
                </div>
                <div className="card-body">
                    <div className="text-wrap mb-20">
                        <h5 className="white-color text-center">Welcome!</h5>
                        <p className="white-color body1 text-center mt-8">To get started, please provide your phone number and upload a copy of your passport.</p>
                    </div>
                    <Formik
                        initialValues={{
                            phoneNumber: "+971",
                            passportDummyFile: fileName,
                            passportFile: ""
                        }}
                        validate={(values) => {
                            const errors = {};
                            if (values.phoneNumber) {
                                const phoneNumberString = parsePhoneNumberFromString(values.phoneNumber);
                                if (!phoneNumberString || !phoneNumberString.isValid()) {
                                    errors.phoneNumber = 'Enter valid phone number';
                                }
                            }
                            if (!values.passportFile) {
                                errors.passportFile = 'Passport file is required';
                            }
                            let userData = getData("userData") || {};

                            const mobile = values.phoneNumber;
                            if (mobile) {
                                userData.mobile = mobile;
                                storeData("userData", userData);
                                setMobilenumber(mobile);
                            }
                            return errors;
                        }}
                        onSubmit={(values) => {
                            handleSubmit(values);
                        }}
                        validateOnMount={true}
                    >
                        {({
                            handleChange, handleBlur, handleSubmit, errors, touched, values, setFieldValue, setFieldTouched, dirty, isValid
                        }) => (
                            <Form className="form-wrap" onSubmit={(e) => {
                                setFieldTouched("phoneNumber", true);
                                handleSubmit(e);
                            }}>
                                {handleSelectFieldObject(setFieldValue)}
                                <Row>
                                    <Col sm={12}>
                                        <div
                                            className={`input-tertiary-color input-with-group form-group ${touched.phoneNumber && isFocused ? "input-focused" : ""} ${errors.phoneNumber && touched.phoneNumber ? "invalid-input" : ""}`}
                                        >
                                            <label className="form-label">Phone Number</label>
                                            <PhoneInput
                                                autoFocus
                                                defaultCountry="ae"
                                                value={values.phoneNumber}
                                                id="phoneNumber"
                                                onChange={(phone) => {
                                                    setFieldValue("phoneNumber", phone);
                                                    if (!touched.phoneNumber) {
                                                        setFieldTouched("phoneNumber", true);
                                                    }
                                                }}
                                                onFocus={() => setIsFocused(true)}
                                                onBlur={() => {
                                                    setFieldTouched("phoneNumber", true);
                                                    setIsFocused(false);
                                                }}
                                                className={errors.phoneNumber && touched.phoneNumber ? "red" : ""}
                                            />
                                            {touched.phoneNumber && errors.phoneNumber && (
                                                <div className="invalid-feedback d-block">
                                                    {errors.phoneNumber}
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div onClick={() => { setIsShowUpload(!isShowUpload) }} ref={uploadRef}>
                                            <Input
                                                disabled={!!errors.phoneNumber}
                                                leftContent={
                                                    <InputGroup.Text className="left-content-group">
                                                        <img src={GLOBAL_IMAGES.GALERY_ICON} alt="USER_ICON" />
                                                    </InputGroup.Text>
                                                }
                                                label="Passport"
                                                type="text"
                                                value={values.passportDummyFile}
                                                id="passportDummyFile"
                                                placeholder="Add Image"
                                                containerStyle={"mb-32 input-tertiary-color input-with-group input-file"}
                                                readOnly={true}
                                                onChange={handleChange("passportDummyFile")}
                                                onBlur={handleBlur("passportDummyFile")}
                                                rightContent={
                                                    <InputGroup.Text className="right-content-group file-btn-wrap" >
                                                        <Btn
                                                            variant={"secondary"}
                                                            style={`btn-ts-blue-gradient w-100 ${!!errors.phoneNumber && 'user-disable'}`}
                                                            size={"md"}
                                                            type="button"
                                                            label="Upload"
                                                            disabled={!!errors.phoneNumber}
                                                        />
                                                        {
                                                            (isShowUpload && !errors.phoneNumber) && (
                                                                <ul className="file-option-wrap">
                                                                    {
                                                                        UPLOAD_OPTION.map((item, index) => {
                                                                            return (
                                                                                <li key={index}>
                                                                                    <Btn
                                                                                        variant={"primary"}
                                                                                        style="btn-text btn-transparent w-100"
                                                                                        size={"md"}
                                                                                        type="button"
                                                                                        label={item.label}
                                                                                        leftIcon={<img src={item.icon} alt={item.label} className="btn-icon-left" />}
                                                                                        onClick={() => { handleUpload(item) }}
                                                                                    />
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            )
                                                        }

                                                    </InputGroup.Text>
                                                }
                                                isInvalid={errors.passportFile && touched.passportFile}
                                                helperText={
                                                    errors.passportFile &&
                                                    touched.passportFile &&
                                                    errors.passportFile
                                                }
                                            />

                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                accept=".jpeg, .jpg, .png, .pdf"
                                                ref={fileInputRef}
                                                style={{ display: "none" }}
                                                id="passportFile"
                                                onChange={(e) => {
                                                    handleFileChange(e, setFieldValue);
                                                }} // Properly handle file change
                                                onBlur={handleBlur("passportFile")}
                                            />
                                            <input
                                                type="file"
                                                ref={fileInputCamera}
                                                style={{ display: "none" }}
                                                id="passportFile"
                                                capture="environment"
                                                onChange={(e) => {
                                                    handleFileCameraChange(e)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <div className="btn-wrap">
                                            <Btn
                                                style="btn-primary-gradient w-100"
                                                size={"lg"}
                                                type="submit"
                                                label="Submit"
                                                disabled={!isValid || !dirty}
                                                rightIcon={<img src={GLOBAL_IMAGES.ARROW_RIGHT_ICON} alt="ARROW_RIGHT_ICON" className="btn-icon-right" />}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            {showPreview && (
                <Container>
                    <Row>
                        <Col sm={12}>
                            <UserPassportPreview
                                documentType={documentType}
                                document={selectedDocument}
                                uploadVia={uploadViaType}
                                onPreviewAction={handlePreviewAction}
                                isInvalid={isPassportError}
                                error={userPassportError}
                            />
                        </Col>
                    </Row>
                </Container>
            )
            }
            {isUploadViaLink && (
                <Container>
                    <Row>
                        <Col sm={12}>
                            <UserPassportLink 
                                closeLink={(e)=>{setisUploadViaLink(e)}}
                            />
                        </Col>
                    </Row>
                </Container>
            )
            }
            <TextSpeechVoice voiceText={voiceText} />
            {
                isLoading && (
                    <div className="loader-container">
                        <div className="loader-wrap loader-with-text">
                            <div className="loader-static">
                                <Spinner></Spinner>
                            </div>
                            <div className="text-wrap pt-20">
                                <p className="subtitle2 white-color">Fetching passport details</p>
                            </div>
                        </div>
                    </div>
                )
            }
            <ToastContainer />
        </React.Fragment>
    );
};

export default UserCard;
