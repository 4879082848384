import axios from "axios";

// BASE URL
import { URL, ENVIRONMENT } from "../../config/constants";

if (ENVIRONMENT !== "PRODUCTION") {
    console.log("Linked in SURVEY:", ENVIRONMENT);
    console.log("Link:", `${URL["BASE_URL_" + ENVIRONMENT]}/`);
}

const extractCategoriesFunctionApi = (message) => {
    return axios({
        method: "GET",
        url: `${URL["BASE_URL_AZURE_" + ENVIRONMENT]}/extractcategoriesfunction?prompt=`+message,
        headers: {
            "Content-Type": "application/json",
        },
        validateStatus: false,
    });
};

const customerInteractionApi = (message, userId) => {
    return axios({
        method: "GET",
        url: `${URL["BASE_URL_AZURE_" + ENVIRONMENT]}/customerinteractionfunction?prompt=`+message+`&userid=`+userId,
        headers: {
            "Content-Type": "application/json",
        },
        validateStatus: false,
    });
};


const extractCountriesFunctionApi = (message) => {
    return axios({
        method: "GET",
        url: `${URL["BASE_URL_AZURE_" + ENVIRONMENT]}/extractcountriesfunction?prompt=`+message,
        headers: {
            "Content-Type": "application/json",
        },
        validateStatus: false,
    });
};


const extractArabicNameFunctionApi = (message, type) => {
    return axios({
        method: "GET",
        url: `${URL["BASE_URL_AZURE_" + ENVIRONMENT]}/arabicnamefunction?name=`+message+`&type=`+type,
        headers: {
            "Content-Type": "application/json",
        },
        validateStatus: false,
    });
};

const companyValidationApi = (data) => {
    return axios({
        method: "GET",
        url: `${URL["BASE_URL_AZURE_" + ENVIRONMENT]}/companyvalidationfunction?companies=`+data,
        headers: {
            "Content-Type": "application/json",
        },
        validateStatus: false,
    });
};

const companySearchApi = (data) => {
    return axios({
        method: "POST",
        url: `${URL["BASE_URL_" + ENVIRONMENT]}/company/search`,
        headers: {
            "Content-Type": "application/json",
        },
        validateStatus: false,
        data: JSON.stringify(data),
    });
};

export {
    extractCategoriesFunctionApi,
    extractCountriesFunctionApi,
    extractArabicNameFunctionApi,
    companySearchApi,
    customerInteractionApi,
    companyValidationApi
};
