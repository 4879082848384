import React, { useEffect, useState } from "react";
import InfoNotify from "./information-notify";
import ApplicationStartUp from "./application-startup";
import RegisterCompany from "./register-company";
import RecommendedActivities from "./recomended-activities";
import CompanyOperations from "./company-operations";
import CompanyName from "./company-name";
import ApplicationPreview from "./application-preview";
import Payment from "./payment";
import ApplicationSummary from "./application-summary";
import ApplicationLicense from "./application-license";
import ApplicationChat from "./application-chat";
import { getData } from "../../utils/common/storage-utils";
import { useLocation } from "react-router-dom";

const UserJourney = () => {
    const location = useLocation();
    const userData = getData("userData") || {};
    const getBackURL = location.state?.params || {};

    const welcomeData = {
        title: `Welcome ${userData?.emailVerified ? 'back, ' : ''} ${(userData?.firstName || '').toLowerCase()}!`,
        description: "Are you ready to start a new application? Please press continue to move forward",
        voiceText: `Welcome ${userData?.emailVerified ? 'back ' : ''}${userData?.firstName}! Are you ready to start a new application? Please press continue to move forward`,
        sessionEnd: false
    };

    const highRiskData = {
        title: "Thank you!! for providing your details",
        description: "We have successfully collected your information. A representative will reach out to you shortly to proceed with the next steps.",
        voiceText: "Thank you for providing your details. We have successfully collected your information. A representative will reach out to you shortly to proceed with the next steps.",
        sessionEnd: true
    };

    const offlinePurchaseData = {
        title: "Thank you!! for submitting your application",
        description: "It will be reviewed by the Free Zone, and you will be contacted shortly.",
        voiceText: "Thank you for submitting your application. It will be reviewed by the Free Zone, and you will be contacted shortly.",
        sessionEnd: true
    };

    const [currentStep, setCurrentStep] = useState(0);
    const [allActivities, setAllActivities] = useState(false);
    const [activitiesLogData, setActivitiesLogData] = useState(null);

    const handleNext = () => {
        setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
        console.log('prevStep', currentStep);
    };

    const handleBack = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    const handleActivitiesData = (data) => {
        setActivitiesLogData(data);
        console.log("handleActivitiesData", data)
    };

    // Use getBackURL to set the initial data
    const initialData = getBackURL === 'highrisk' ? highRiskData : welcomeData;

    useEffect(() => {
        console.log('getBackURL', getBackURL);
        if (getBackURL === "preview") {
            const previewIndex = steps.findIndex(step => step.key === "preview");
            if (previewIndex !== -1) {
                setCurrentStep(previewIndex);
            }
        }
    }, [getBackURL]);

    const steps = [
        <InfoNotify key="info" data={initialData} onContinue={handleNext} />,
        <ApplicationStartUp key="app-start" onContinue={handleNext} />,
        <ApplicationChat key="register" onContinue={handleNext} onBack={handleBack} />,
        <CompanyName key="company" onContinue={handleNext} onBack={handleBack} />,
        <ApplicationPreview key="preview" onContinue={handleNext} onBack={handleBack} />,
    ];

    return (
        <div>
            {steps[currentStep]}
        </div>
    );
};

export default UserJourney;
