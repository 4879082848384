import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// framework

// plugin
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
/* import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`; */


// styles
import "./new-user.scss";

// images
import { GLOBAL_IMAGES } from "../../assets/images/global-images";

// components
import { Btn, InfoAlertCard, Input } from "../../components";
import { Col, Form, InputGroup, Row } from "react-bootstrap";

// email
import { EMAIL_ID_REGEX } from "../../utils/helpers/regex";

// services
import { UPLOAD_TYPE_ACTION_DATA } from "../../services/static-content";



const UserPassportPreview = (props) => {

    const {uploadVia, onPreviewAction, isInvalid, error, document, documentType} = props;

    const [uploadTypeAction, setUploadTypeAction] = useState(UPLOAD_TYPE_ACTION_DATA);
    const [numPages, setNumPages] = useState(null);
    const containerRef = useRef(null); // Use ref to access the container
    
    const defaultLayoutPluginInstance = defaultLayoutPlugin();


    const previewAction = (action) => {
        // Sending data to the parent component
        onPreviewAction(action);
    };

    /* const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    } */

    useEffect(() => {
        // console.log(uploadVia, document,documentType, "uploadViaType", uploadTypeAction[uploadVia]);
        console.log(uploadVia, document, "uploadViaType");
    },[])

    // Dynamically set the scale-factor CSS variable
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.style.setProperty('--scale-factor', 1); // Set to the initial zoom scale, e.g., 1 for 100%
        }
    }, [document]);

    return (
        <div className="fullscreen-view-wrap fullscreen-view-overlap bg-tertiary-color">
            <div className={`fs-header-wrap  ${uploadVia == "UPLOAD_VIA_CAMERA" && "fs-header-action"} `}>
                <div className="logo-wrap mb-0">
                    <img src={GLOBAL_IMAGES.LOGO} alt="LOGO" />
                </div>
                {
                    uploadVia == "UPLOAD_VIA_CAMERA" && (
                        <div>
                            <Btn
                                variant="primary"
                                style="btn-outline-primary no-hover"
                                size="lg"
                                label="Retake"
                                leftIcon={<img src={GLOBAL_IMAGES.REDO_ICON} alt="Redo" className="btn-icon-left" />}
                                onClick={() => previewAction("UPLOAD_VIA_CAMERA")}
                            />
                        </div>
                    )
                }
                
            </div>
            <div className="fs-body-wrap">
                {
                    isInvalid ? (
                        <InfoAlertCard 
                            data={error}
                            contentStyle="mx-auto mt-40" 
                        />
                    ) : (
                        <div className="image-preview-wrap">
                            {/* <img src={GLOBAL_IMAGES.PASSPORT_DUMMY} alt="PASSPORT_DUMMY" /> */}
                            {
                                documentType == "application/pdf" ? (
                                    <div 
                                        ref={containerRef} // Attach the ref to the container
                                        style={{ height: '750px' }}
                                    >
                                        {/* <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${window.PDFJS_VERSION}/build/pdf.worker.min.js`}> */}
                                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                                            <Viewer 
                                                fileUrl={document} 
                                                defaultScale={1}
                                                plugins={[defaultLayoutPluginInstance]} 
                                            />
                                        </Worker>
                                        {/* <Document
                                            file={document}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                            {Array.from(new Array(numPages), (el, index) => (
                                            <Page 
                                                key={`page_${index + 1}`} 
                                                pageNumber={index + 1} 
                                                renderTextLayer={false}
                                                renderAnnotationLayer={false}
                                            />
                                            ))}
                                        </Document> */}
                                    </div>
                                ) : (
                                    <img src={document} alt="PASSPORT_DUMMY" />
                                )
                            }
                            
                        </div>
                    )
                }
                
            </div>
            <div className="fs-footer-wrap">
                <div className={`btn-wrap btn-flex-between ${uploadTypeAction[uploadVia].length == 1 && 'btn-flex-end'}`}>
                    {uploadTypeAction[uploadVia].map((item, index) => {
                        return (
                            <Btn
                                key={index}
                                variant="primary"
                                style={item.style}
                                size="lg"
                                label={item.label}
                                leftIcon={item.iconPosition === "left" ? (
                                    <img src={item.icon} alt={item.action} className="btn-icon-left" />
                                ) : null}
                                rightIcon={item.iconPosition === "right" ? (
                                    <img src={item.icon} alt={item.action} className="btn-icon-right" />
                                ) : null}
                                onClick={() => previewAction(item.type)}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default UserPassportPreview;
