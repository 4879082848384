import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// framework
import { Container, Row, Col, Card, Form } from "react-bootstrap";

// plugin

// styles
import "./login.scss";

// images
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";

// components
import { BackgroundBlock } from "../../../components";
import LoginCard from "./login-card";


const Login = () => {

    const history = useNavigate();

    return (
        <div className="screen-wrap bg-tertiary-color">
            <Container>
                <Row>
                    <Col sm={6}>
                        <div className="login-card-wrap h-100 shape-position-wrap">
                            <div className="logo-wrap">
                                <img src={GLOBAL_IMAGES.LOGO} alt="LOGO"/>
                            </div>
                            <LoginCard/>
                            <div className="text-wrap position-relative mt-12 z-1">
                                {/* <a className="body1 primary-color span-text-underline" href="javascript:void(0)" target="_blank">Frequently Asked Questions ?</a> */}
                            </div>
                            <div className="shape-position-content zoom-1">
                                <img src={GLOBAL_IMAGES.SQUARE_SHAPE_DOTS} alt="SQUARE_SHAPE_DOTS" className="top-left-corner"/>
                                <img src={GLOBAL_IMAGES.L_SHAPE_DOTS} alt="L_SHAPE_DOTS" className="bottom-right-corner"/>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} className="px-0">
                        <BackgroundBlock
                            src={GLOBAL_IMAGES.BG_BUILDING}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;
