import React from 'react';
import PropTypes from 'prop-types';

// framework
import { Button } from 'react-bootstrap';

// styles
import './button.scss';

const Btn = (props) => {
	
	return (
		<Button
			variant={props.variant}
			size={props.size}
			className={`${props.style} ${props.icon != null ? 'btn-icon' : ''}`}
			href={props.href}
			disabled={props.disabled}
			onClick={props.onClick}
			htmlFor={props.for} 
			as={props.as}
			type={props.type}
		>
			{props.leftIcon && props.leftIcon}
			{props.label}
			{props.rightIcon && props.rightIcon}
		</Button>
	)
}


/**
 	* label : set button label text
	*	type : Defines HTML button type attribute. -->   'button' | 'reset' | 'submit' | null
	*	variant : One or more button variant combinations -->   
							'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'link'
							'outline-primary', 'outline-secondary', 'outline-success', 'outline-danger', 'outline-warning', 'outline-info', 'outline-dark', 'outline-light'
	*	size : Specifies a large or small button. -->		'sm' | 'lg'
	*	href : Providing a href will render an <a> element, styled as a button.
	*	style : Provide classname as style for button
	*	for : The for attribute specifies which form element a label is bound to.
	*	as : You can use a custom element type for this component.
	*	icon : Provide icon src
	*	disabled : Disables the Button, preventing mouse events, even if the underlying component is an <a> element
	*	onClick : handlers from firing regardless of the rendered element.
*/

Btn.propTypes = {
	label: PropTypes.string,
	type: PropTypes.string,
	variant: PropTypes.string,
	size: PropTypes.string,
	href: PropTypes.string,
	style: PropTypes.string,
	for: PropTypes.string,
	as: PropTypes.string,
	leftIcon: PropTypes.object,
	rightIcon: PropTypes.object,
	disabled: PropTypes.bool,
	onClick: PropTypes.func
};

/* Btn.defaultProps = {
	label: '',
	icon: null,
	variant: 'primary',
	size: '',
	href: null,
	style: '',
	for: '',
	as: '',
	disabled: false,
	type: 'button',
	onClick: () => { },
} */

export default Btn;