import axios from "axios";

// BASE URL
import { URL, ENVIRONMENT } from "../../config/constants";

if (ENVIRONMENT !== "PRODUCTION") {
    console.log("Linked in USER:", ENVIRONMENT);
    console.log("Link:", `${URL["BASE_URL_" + ENVIRONMENT]}/`);
}

const registerUserApi = (props, newUser) => {
    console.log("props", props);
    return axios({
        method: "POST",
        url: `${URL["BASE_URL_" + ENVIRONMENT]}/user/register?newUser=${newUser}`,
        headers: {
            // Content-Type will be set automatically when using FormData
            "Access-Control-Allow-Origin": "*",
        },
        validateStatus: false,
        data: props,  // Directly pass the FormData object
    });
};
const updateUserApi = (props, userId) => {
    console.log("props", props);
    return axios({
        method: "PUT",
        url: `${URL["BASE_URL_" + ENVIRONMENT]}/user/update/${userId}`,
        headers: {
            // Content-Type will be set automatically when using FormData
            "Access-Control-Allow-Origin": "*",
        },
        validateStatus: false,
        data: props,  // Directly pass the FormData object
    });
};

export {
    registerUserApi,
    updateUserApi
}
