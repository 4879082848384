import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// framework
import { Container, Row, Col, Card, Form } from "react-bootstrap";

// plugin

// styles
import "./new-user.scss";

// images
import { GLOBAL_IMAGES } from "../../assets/images/global-images";

// components
import { BackgroundBlock } from "../../components";
import UserCard from "./user-card";

import { ENVIRONMENT } from "../../utils/config/constants";

const NewUser = () => {
  //   const [refresh, setRefresh] = useState(false);
  //   const [ws, setWs] = useState(null);
  //   const [generatedUniqueId, setgeneratedUniqueId] = useState(null);

  //   const history = useNavigate();
  //   const generateUniqueId = () => {
  //     return Math.random().toString(36).substring(7);
  //   };
  // useEffect(() => {
  //     console.log('====================================');
  //     console.log("Running");
  //     console.log('====================================');
  //     setgeneratedUniqueId(generateUniqueId())
  //     // Connect to WebSocket server
  //     const ws = new WebSocket(`${URL["WEB_SOCKET_URL_" + ENVIRONMENT]}`);
  //     setWs(ws);
  //     // Send the userId to the server after connecting
  //     ws.onopen = () => {
  //         // const userId = generateUniqueId(); // Generate a unique ID
  //         ws.send(JSON.stringify({ generatedUniqueId }));
  //         console.log("WebSocket Opened for New-user journey")
  //         // Send the userId to the server
  //     };
  //     const handleWebSocketMessage = async (event) => {

  //         console.log("EVENT", event);

  //     };
  //     ws.onmessage = handleWebSocketMessage;
  //     return () => {
  //         // ws.close();
  //     };
  // }, [refresh]);

  // check user data
  const userData = (data) => {
    console.log("user data", data);
  };

  return (
    <div className="screen-wrap bg-tertiary-color">
      <Container>
        <Row>
          <Col sm={6}>
            <div className="login-card-wrap justify-align-center h-100 shape-position-wrap">
              <div className="logo-wrap">
                <img src={GLOBAL_IMAGES.LOGO} alt="LOGO" />
              </div>
              <UserCard
                onHandleSubmit={(data) => {
                  userData(data);
                }}
              />
              <div className="shape-position-content zoom-1">
                <img
                  src={GLOBAL_IMAGES.SQUARE_SHAPE_DOTS}
                  alt="SQUARE_SHAPE_DOTS"
                  className="top-left-corner"
                />
                <img
                  src={GLOBAL_IMAGES.L_SHAPE_DOTS}
                  alt="L_SHAPE_DOTS"
                  className="bottom-right-corner"
                />
              </div>
            </div>
          </Col>
          <Col sm={6} className="px-0 d-none d-sm-block">
            <BackgroundBlock src={GLOBAL_IMAGES.BG_BUILDING} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewUser;
