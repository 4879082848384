import React, { useState } from "react";
import PropTypes from "prop-types";

// framework
import { Form, InputGroup } from "react-bootstrap";

const Input = (props) => {

  const {
    as = "input",
    label = "",
    id = "",
    name = "",
    type = "text",
    placeholder = "",
    defaultValue = "",
    size = "",
    style = "",
    labelStyle = "",
    containerStyle = "",
    helperText = "",
    rows = null,
    maxLength = null,
    disabled = false,
    isValid = false,
    isInvalid = false,
    readOnly = false,
    required = false,
    showLength = false,
    plaintext = false,
    onChange = () => {},
    onBlur = () => {},
    leftContent,
    rightContent,
    autoFocus =false,
    value,
  } = props;
  
  const [count, setCount] = useState(defaultValue.length);
  const [checkValue, setCheckValue] = useState(defaultValue);

  // counter for maxLength
  const characterCount = (event) => {
    setCount(maxLength - event.target.value.length);
  };

  // handle on change of input
  const handleChange = (event) => {
    onChange(event);

    if (showLength) {
      characterCount(event);
    }

    setCheckValue(leftContent && event.target.value.length > 0)

    /* if (type === "input" && id === "email") {
      validateEmail(event.target.value);
    } */
  };

  // handle on change of input
  const handleBlur = (event) => {
    onBlur(event);
    /* if (type === "input" && id === "email") {
      validateEmail(event.target.value);
    } */
      setCheckValue(leftContent && event.target.value.length > 0)
  };

  /* const validateEmail = (email) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email && !emailRegex.test(email)) {
      setEmailError("Invalid email");
    } else {
      setEmailError("");
    }
    setFieldValue("email", email);
  }; */

  return (
    <Form.Group
      controlId={id}
      className={`${containerStyle} ${checkValue && 'input-has-value-'} ${isInvalid && "input-isInvalid"} form-group ${readOnly && "form-read-only"}`}
    >
      {label && (
        <Form.Label
          className={`${labelStyle} ${
            showLength ? "form-character-limit" : ""
          }`}
        >
          {label}
          {showLength && (
            <span>
              {count} out of {maxLength} Characters left
            </span>
          )}
        </Form.Label>
      )}
      <div className="form-control-wrap">
        <InputGroup className={readOnly && 'input-group-readonly'}>
          {leftContent}
          <Form.Control
            autoFocus={autoFocus}
            required={required}
            type={type}
            placeholder={placeholder}
            autoComplete="off"
            // defaultValue={defaultValue}
            disabled={disabled}
            isValid={isValid}
            isInvalid={isInvalid}
            readOnly={readOnly}
            size={size}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`${style}`}
            plaintext={plaintext}
            as={as}
            rows={rows}
            maxLength={maxLength}
            name={id}
            value={value}
          />
          {rightContent}
        </InputGroup>
      </div>
      {helperText && (
        <Form.Control.Feedback
          type={`${isValid && "valid"} ${isInvalid && "invalid"}`}
        >
          {helperText}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

/**
 * as: The underlying HTML element to use when rendering the FormControl. 'input' | 'textarea'
 * label: set label text
 * id: set element id
 * name: set element name
 * type: set the type of input
 * placeholder: placeholder of input
 * defaultValue: set defaultValue
 * size: Input size variants 'sm' | 'lg'
 * style: Manually style the control
 * labelStyle: Manually style the label
 * containerStyle: Manually style the wrapper
 * helperText:  providing helper text
 * rows: set rows size for textarea
 * maxLength: set maxLength of input
 * disabled: Make the control disabled
 * isValid : Manually style the input as valid
 * isInvalid : Manually style the input as invalid
 * readOnly : Readlonly element
 * required : set is required or not
 * showLength : show character count
 * plaintext: Render the input as plain text. Generally used along side readOnly.
 * onChange: handlers from firing regardless of the rendered element.
 * onBlur: handlers from firing regardless of the rendered element.
 */

Input.propTypes = {
  as: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.string,
  labelStyle: PropTypes.string,
  containerStyle: PropTypes.string,
  helperText: PropTypes.string,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  isValid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isInvalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  showLength: PropTypes.bool,
  plaintext: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

/* Input.defaultProps = {
  as: "input",
  label: "",
  id: "",
  type: "text",
  placeholder: "",
  defaultValue: "",
  size: "",
  style: "",
  labelStyle: "",
  containerStyle: "",
  helperText: "",
  rows: null,
  maxLength: null,
  disabled: false,
  isValid: false,
  isInvalid: false,
  readOnly: false,
  required: false,
  showLength: false,
  plaintext: false,
  onChange: () => {},
  onBlur: () => {},
}; */

export default Input;
