import axios from "axios";

// BASE URL
import { URL, ENVIRONMENT } from "../../config/constants";

if (ENVIRONMENT !== "PRODUCTION") {
    console.log("Linked in USER:", ENVIRONMENT);
    console.log("Link:", `${URL["BASE_URL_" + ENVIRONMENT]}/`);
}

const paymentStatus = (props) => {
    console.log("props", props);
    return axios({
        method: "POST",
        url: `${URL["BASE_URL_EMAIL_VERIFICATION_" + ENVIRONMENT]}/payment/status`,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        validateStatus: false,
        data: JSON.stringify(props),
    });
};
const paymentTransectionStatus = (props) => {
    console.log("props", props);
    return axios({
        method: "POST",
        url: `${URL["BASE_URL_EMAIL_VERIFICATION_" + ENVIRONMENT]}/payment/paymentTransactionStatus`,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        validateStatus: false,
        data: JSON.stringify(props),
    });
};

export {
    paymentStatus,
    paymentTransectionStatus
};
