import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import moment from 'moment';

// images
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";

// components
import { Btn, IconButton } from "../../../components";
import EndSession from "../../../components/end-session";
//style
import './index.scss'

import { GLOBAL_ANIMATION } from "../../../assets/animation/global-anime";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import TextSpeechVoice from "../../../components/text-to-speech-voice";
import { useNavigate } from "react-router-dom";
import { getData, removeItem, storeData } from "../../../utils/common/storage-utils";

const ApplicationSummary = ({ onContinue }) => {
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);

    const textSpeechRef = useRef(null);

    const [isAudioPlay, setIsAudioPlay] = useState(null);
    const isInitialMount = useRef(true);
    const applicationDate = moment().format("MMM D' YY");
    const applicationNumber = getData('companyId');


    useEffect(() => {
        // setTimeout(() => {
        //     nextPage()
        // }, 1000);
        removeItem('message');
    }, [])


    const nextPage = () => {
        //onContinue();
        navigate("/application-license");
    };
    const handleSpeechPause = () => {
        if (textSpeechRef.current) {
            textSpeechRef.current.pauseSpeech();
        }
    };
    const handleContinueClick = () => {
        handleSpeechPause();
        nextPage()
    };

    // handle the audio pause and restart
    const handleResume = (data) => {
        if (textSpeechRef.current) {
            textSpeechRef.current.restartSpeech();
        }
    };

    const handleAudio = (data) => {
        setIsAudioPlay(data);
        if(!data) {
            handleSpeechPause();
        } else {
            handleResume();
        }
        storeData("isAduioPlay", data);
    }

    const getStoreAudioStatus = () => {
        const getAudioStatus = getData("isAduioPlay");
        console.log(getAudioStatus, "getAudioStatus_startup")
        setIsAudioPlay(getAudioStatus);
    }

    useEffect(()=> {
        if (isInitialMount.current) {
            getStoreAudioStatus();
            
            isInitialMount.current = false; // Set flag to prevent future calls   
        }
    }, []);
    
            

    const voiceText = 'Thank you for your submission. Your license will be generated shortly and sent to your email.';

    return (
        <React.Fragment>
            <div className="fullscreen-view-wrap fullscreen-view-overlap bg-tertiary-color">
                <img src={GLOBAL_IMAGES.SQUARE_SHAPE_DOTS} alt="SQUARE_SHAPE_DOTS" className="opacity-50 top-left-corner position-absolute" />
                <div className="fs-header-wrap min-h">
                    <div className="d-flex justify-content-end">
                        <IconButton
                            src={isAudioPlay ? GLOBAL_IMAGES.SPEAKER_ON_ICON : GLOBAL_IMAGES.SPEAKER_OFF_ICON}
                            style="btn-outline-primary no-hover square-btn"
                            onClick={()=>{handleAudio(!isAudioPlay)}}
                        />
                    </div>
                </div>
                <div className="fs-body-wrap fs-body-wrap-content customScroll-shade-grey">
                    <div className="content-wrap">
                        <div className="fs-body-logo-waves d-flex flex-column align-items-center">
                            <div className="circle-logo">
                                <img src={GLOBAL_IMAGES.CIRCLE_LOGO} alt="CIRCLE_LOGO" />
                            </div>
                            <div className="summary-wrap">
                                <Row>
                                    <Col sm={12} className="p-0">
                                        <div className="text-wrap text-center">
                                            <h2 className="title mb-16">Thank you for your submission.</h2>
                                            <p className="subtitle2">Your license will be generated shortly and sent to your email.</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col sm={6} className="p-0" style={{minWidth: 596}}>
                                        <div className="box-card">
                                            <div className="box-section-shape">
                                                <img src={GLOBAL_IMAGES.TOP_BOX_SHAP} alt="TOP_BOX_SHAP" className="box-section-shape-top" />
                                                <img src={GLOBAL_IMAGES.BOTTOM_BOX_SHAP} alt="BOTTOM_BOX_SHAP" className="box-section-shape-bottom" />
                                                <img src={GLOBAL_IMAGES.HORIZONTAL_SHAP_DOT} alt="BOTTOM_BOX_SHAP" className="dot-shape-top" />
                                            </div>
                                            <div className="card-body-section">
                                                <div className="box-section-shape">
                                                    <img src={GLOBAL_IMAGES.HORIZONTAL_SHAP_DOT} alt="BOTTOM_BOX_SHAP" className="dot-shape-top" />
                                                </div>
                                                <div className="fs-section-content">
                                                    <div className="text-wrap">
                                                        <h6 className="text-uppercase text-center mb-24 font-med">APPLICATION SUMMARY</h6>
                                                        <div className="summary-grid">
                                                            <div className="data-row text-wrap">
                                                                <div className="data-cell d-flex justify-content-between">
                                                                    <p className="subtitle2 font-med">Application Number</p>
                                                                    <p className="subtitle2">{applicationNumber}</p>
                                                                </div>
                                                                <div className="data-cell d-flex justify-content-between">
                                                                    <p className="subtitle2 font-med">Submission Date</p>
                                                                    <p className="subtitle2">{applicationDate}</p>
                                                                </div>
                                                                <div className="data-cell d-flex justify-content-between">
                                                                    <p className="subtitle2 font-med">Total Fee</p>
                                                                    <p className="subtitle2">6,885.00</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="progress-bar-summary">
                                            <Player
                                                speed={.8}
                                                autoplay
                                                loop
                                                src={GLOBAL_ANIMATION.PROGRESS}
                                                style={{ width: '100%' }}
                                            >
                                                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                                            </Player>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fs-footer-wrap">
                    <div className="btn-wrap btn-flex-end">
                        {/* <EndSession footerBtn={true}></EndSession> */}
                        <Btn
                            variant="primary"
                            size="lg"
                            style={'btn-primary-gradient'}
                            label={'Continue'}
                            leftIcon={<img src={GLOBAL_IMAGES.TICK_CIRCLE} alt={GLOBAL_IMAGES.TICK_CIRCLE} className="btn-icon-left" />}
                            onClick={handleContinueClick}
                        />
                    </div>
                </div>
                {
                    isLoading && (
                        <div className="loader-container">
                            <div className="document-loader">
                                <Player
                                    speed={.8}
                                    autoplay
                                    loop
                                    src={GLOBAL_ANIMATION.DOCUMENT}
                                    style={{ width: '70%' }}
                                >
                                    <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                                </Player>
                            </div>
                        </div>
                    )
                }
            </div>
            {
                isAudioPlay && <TextSpeechVoice voiceText={voiceText} ref={textSpeechRef} />
            }
        </React.Fragment>
    );
};

export default ApplicationSummary;
