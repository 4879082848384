import axios from "axios";

// BASE URL
import { URL, ENVIRONMENT } from "../../config/constants";

if (ENVIRONMENT !== "PRODUCTION") {
    console.log("Linked in USER:", ENVIRONMENT);
    console.log("Link:", `${URL["BASE_URL_" + ENVIRONMENT]}/`);
}

const sendVerificationEmail = (props) => {
    console.log("props", props);
    return axios({
        method: "POST",
        url: `${URL["BASE_URL_EMAIL_VERIFICATION_" + ENVIRONMENT]}/email/sendVerificationEmail`,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        validateStatus: false,
        data: JSON.stringify(props),
    });
};

const emailVerificationStatus = (props) => {
    console.log("props", props);
    return axios({
        method: "GET",
        url: `${URL["BASE_URL_EMAIL_VERIFICATION_" + ENVIRONMENT]}/email/emailVerificationStatus/${props}`,
        headers: {
            "Content-Type": "application/json",
        },
        validateStatus: false,
    });
};

export {
    sendVerificationEmail,
    emailVerificationStatus
};
