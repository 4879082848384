import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './button.scss';

const IconButton = ({
	src = '',
	variant = 'primary',
	size = '',
	href = null,
	as = '',
	style = '',
	disabled = false,
	type = 'button',
	onClick = () => { },
	for: htmlFor = ''
}) => {
	return (
		<Button
			variant={variant}
			type={type}
			size={size}
			href={href}
			className={` ${style}`}
			disabled={disabled}
			onClick={onClick}
			htmlFor={htmlFor}
			as={as}
		>
			<img src={src} alt="icon" />
		</Button>
	);
};

IconButton.propTypes = {
	src: PropTypes.string,
	type: PropTypes.string,
	variant: PropTypes.string,
	size: PropTypes.string,
	href: PropTypes.string,
	as: PropTypes.string,
	style: PropTypes.string,
	for: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func
};

export default IconButton;
