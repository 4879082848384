import React, { useEffect, useState } from "react";
import { GLOBAL_IMAGES } from "../../assets/images/global-images";
import { useNavigate } from "react-router-dom";

//components
import IconButton from "../button/iconButton";
import Dialog from "../dialog";



const EndSession = ({handleSessionClose}) => {
    const navigate = useNavigate();
    //modal constants 
    const [showDialog, setShowDialog] = useState(false);

    const handleOpen = () => {
        setShowDialog(true);
        handleSessionClose();
    };
    const handleClose = () => setShowDialog(false);
    const sessionEnd = () => {
        console.log('sessionEnd');
        handleClose();
        navigate("/");
    };
    return (
        <React.Fragment>
            <IconButton
                src={GLOBAL_IMAGES.CLOSE_CIRCLE_ICON}
                style="btn-ts-blue-gradient square-btn"
                onClick={handleOpen}
            />
            <Dialog
                show={showDialog}
                close={handleClose}
                title={'Are you sure you want to end the session ?'}
                description="You will be able re-login but no data of the existing applicaiton will be saved."
                backdrop="static"
                closeButton={true}
                showFooter={false}
                style={'custom-modal'}
                icon={GLOBAL_IMAGES.END_SESSION}
                onClick={sessionEnd}
                btnLeftIcon={GLOBAL_IMAGES.TICK_CIRCLE}
                btnLabel={'Yes, I’m sure'}
                btnStyle={'btn-primary-gradient mt-32'}
            />
        </React.Fragment>
    );
};

export default EndSession;
