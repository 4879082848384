import { GLOBAL_IMAGES } from "../assets/images/global-images";

const USER_PASSPORT_BLUR_DATA = {
    icon: GLOBAL_IMAGES.BLUR_IMG,
    title: "The image uploaded by you is blurry",
    titleIcon: GLOBAL_IMAGES.WARNING_ICON,
    description: "Unable to capture information"
}
const USER_IMG_UNSUPPORT_DATA = {
    icon: GLOBAL_IMAGES.UNSUPPORT_IMG,
    title: "Please upload .jpg, .png, .pdf file",
    titleIcon: GLOBAL_IMAGES.WARNING_ICON,
    description: "File format not supported"
}
const UNEXPECTED_RESPONSE = {
    icon: GLOBAL_IMAGES.UNSUPPORT_IMG,
    title: "Error processing document",
    titleIcon: GLOBAL_IMAGES.WARNING_ICON,
    description: "Please try again."
}
const USER_PASSPORT_ERROR_DATA = {
    icon: GLOBAL_IMAGES.CALENDAR_BIG_ICON,
    title: "The passport uploaded by you has expired",
    titleIcon: GLOBAL_IMAGES.WARNING_ICON,
    description: "Unable to process this request"
}
const UPLOAD_OPTION_DATA = [
    {
        label: "Take a photo",
        type: "UPLOAD_VIA_CAMERA",
        icon: GLOBAL_IMAGES.CAMERA_ICON
    },
    {
        label: "Upload via device",
        type: "UPLOAD_VIA_DEVICE",
        icon: GLOBAL_IMAGES.TAB_ICON
    },
    {
        label: "Upload via link",
        type: "UPLOAD_VIA_LINK",
        icon: GLOBAL_IMAGES.ROUND_LINK_ICON
    },
]
const UPLOAD_TYPE_ACTION_DATA = {
    UPLOAD_VIA_CAMERA: [
        /* {
            label: "Retake",
            type: "UPLOAD_VIA_CAMERA",
            icon: GLOBAL_IMAGES.REDO_ICON,
            style: "btn-outline-primary no-hover",
            iconPosition: "left"
        }, */
        {
            label: "Try Another Method",
            type: "UPLOAD_ANOTHER_METHOD",
            icon: GLOBAL_IMAGES.ARROW_LEFT_ICON,
            style: "btn-outline-primary no-hover",
            iconPosition: "left"
        },
        {
            label: "Review",
            type: "UPLOAD_REVIEW",
            icon: GLOBAL_IMAGES.ARROW_RIGHT_ICON,
            style: "btn-primary-gradient",
            iconPosition: "right"
        },
    ],
    UPLOAD_VIA_DEVICE: [
        {
            label: "Upload Again",
            type: "UPLOAD_VIA_DEVICE",
            icon: GLOBAL_IMAGES.REDO_ICON,
            style: "btn-outline-primary no-hover",
            iconPosition: "left"
        },
        {
            label: "Review",
            type: "UPLOAD_REVIEW",
            icon: GLOBAL_IMAGES.ARROW_RIGHT_ICON,
            style: "btn-primary-gradient",
            iconPosition: "right"
        },
    ],
    UPLOAD_AGAIN_IMAGE_BLUR: [
        {
            label: "Retake",
            type: "UPLOAD_VIA_DEVICE",
            icon: GLOBAL_IMAGES.REDO_ICON,
            style: "btn-outline-primary no-hover",
            iconPosition: "left"
        },
        {
            label: "Try Another Method",
            type: "UPLOAD_ANOTHER_METHOD",
            icon: GLOBAL_IMAGES.ARROW_RIGHT_ICON,
            style: "btn-primary-gradient",
            iconPosition: "right"
        },
    ], 
    UPLOAD_AGAIN_UNSUPPORT: [
        {
            label: "Upload Again",
            type: "UPLOAD_ANOTHER_METHOD",
            icon: GLOBAL_IMAGES.REDO_ICON,
            style: "btn-primary-gradient",
            iconPosition: "left"
        },
    ],
    UPLOAD_TRY_ANOTHER_UNSUPPORT: [
        {
            label: "Try Another Method",
            type: "UPLOAD_ANOTHER_METHOD",
            icon: GLOBAL_IMAGES.ARROW_LEFT_ICON,
            style: "btn-primary-gradient",
            iconPosition: "left"
        },
    ],
}

const GENDER_LIST_DATA = [
    {
        label: "Male",
        value: "Male",
        disabled: false,
    },
    {
        label: "Female",
        value: "Female",
        disabled: false,
    },
    /* {
        label: "Others",
        value: "Others",
        disabled: false,
    }, */
]

const DUMMY_LIST_DATA = [
    {
        label: "Select 1",
        value: "Select 1",
        disabled: false,
    },
    {
        label: "Select 2",
        value: "Select 2",
        disabled: false,
    },
    {
        label: "Select 3",
        value: "Select 3",
        disabled: false,
    },
]

const REGISTER_FORM_DATA = {
    firstName: "tevilah824@kwalah.com",
    lastName: "tevilah824@kwalah.com",
    gender: 1,
    dateOfBirth: "1998-01-16",
    nationality: 40,
    passportNumber: "P6807564",
    issuingAuthority: "Ind",
    country: "786",
    email: "tevilah824@kwalah.com",
    password: "testpassword",
    phone: "+919833419250",
    deviceId: "testdeviceId6",
    deviceType: "3"
}

const SERVER_ERROR_DIALOG_DATA = {
    title: "Something went wrong",
    description: "Please try again later.",
    btnIcon: GLOBAL_IMAGES.TICK_CIRCLE,
    btnLabel: "Okay"
}
const ACTIVITIES_CHAT_DIALOG_DATA = {
    title: "On go back",
    description: "Would like to save changes?",
    btnIcon: GLOBAL_IMAGES.TICK_CIRCLE,
    btnLabel: "Save and Continue"
}
const COUNTRY_LIST = [
    { label: 'Afghanistan', value: 'AF' },
    { label: 'Åland Islands', value: 'AX' },
    { label: 'Albania', value: 'AL' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'AndorrA', value: 'AD' },
    { label: 'Angola', value: 'AO' },
    { label: 'Anguilla', value: 'AI' },
    { label: 'Antarctica', value: 'AQ' },
    { label: 'Antigua and Barbuda', value: 'AG' },
]
export {
    USER_PASSPORT_BLUR_DATA,
    USER_IMG_UNSUPPORT_DATA,
    UPLOAD_OPTION_DATA,
    UPLOAD_TYPE_ACTION_DATA,
    GENDER_LIST_DATA,
    DUMMY_LIST_DATA,
    REGISTER_FORM_DATA,
    SERVER_ERROR_DIALOG_DATA,
    UNEXPECTED_RESPONSE,
    ACTIVITIES_CHAT_DIALOG_DATA,
    COUNTRY_LIST,
    USER_PASSPORT_ERROR_DATA
}