import React, { useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";

const TextSpeechVoice = forwardRef(({ voiceText }, ref) => {
    const synthesizerRef = useRef(null);
    const playerRef = useRef(null);

    const startSpeech = (text) => {
        if (synthesizerRef.current) {
            synthesizerRef.current.speakTextAsync(
                text,
                result => {
                    if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                        console.log("Speech synthesis completed.");
                    } else {
                        console.error("Speech synthesis canceled or failed.", result);
                    }
                },
                error => {
                    console.error("Speech synthesis error.", error);
                }
            );
        }
    };

    useEffect(() => {
        const speechConfig = sdk.SpeechConfig.fromSubscription("fb063b1ae6784fa9af67f870ad2d7ffd", "southeastasia");

        // Use SpeakerAudioDestination for pausing and resuming
        const player = new sdk.SpeakerAudioDestination();
        const audioConfig = sdk.AudioConfig.fromSpeakerOutput(player);

        // Set the voice and language
        speechConfig.speechSynthesisVoiceName = "en-GB-RyanNeural"; // British English male voice

        const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
        synthesizerRef.current = synthesizer;
        playerRef.current = player;

        if (voiceText) {
            startSpeech(voiceText);
        }

        return () => {
            if (synthesizerRef.current) {
                synthesizerRef.current.close(); // Ensure the synthesizer is closed to stop any ongoing speech
            }
        };
    }, [voiceText]);

    useImperativeHandle(ref, () => ({
        pauseSpeech: () => {
            if (playerRef.current) {
                playerRef.current.pause();
                console.log("Speech playback paused.");
            }
        },
        muteSpeech: () => {
            if (playerRef.current) {
                // Mute by setting volume to 0
                playerRef.current.volume = 0;
                console.log("Speech playback muted.");
            }
        },
        unmuteSpeech: () => {
            if (playerRef.current) {
                // Unmute by setting volume back to 1
                playerRef.current.volume = 1;
                console.log("Speech playback unmuted.");
            }
        },
        resumeSpeech: () => {
            if (playerRef.current) {
                playerRef.current.resume();
                console.log("Speech playback resumed.");
            }
        },
        restartSpeech: () => {
            if (synthesizerRef.current) {
                synthesizerRef.current.close(); // Close the current synthesizer
                console.log("Speech playback stopped and restarted.");

                // Recreate the synthesizer and start speech again
                const speechConfig = sdk.SpeechConfig.fromSubscription("fb063b1ae6784fa9af67f870ad2d7ffd", "southeastasia");
                const player = new sdk.SpeakerAudioDestination();
                const audioConfig = sdk.AudioConfig.fromSpeakerOutput(player);

                speechConfig.speechSynthesisVoiceName = "en-GB-RyanNeural";
                synthesizerRef.current = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
                playerRef.current = player;

                startSpeech(voiceText);
            }
        }
    }));

    return null; // This component doesn't need to render anything
});

export default TextSpeechVoice;
