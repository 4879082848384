import { useRef, useCallback } from 'react';

// Custom hook to debounce a function
const useDebounce = (callback, delay) => {
  const debounceRef = useRef(null);

  return useCallback((...args) => {
    if (debounceRef.current) clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);
};

export default useDebounce;