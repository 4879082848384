import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// framework

// plugin

// styles
import "./new-user.scss";

// images
import { GLOBAL_IMAGES } from "../../assets/images/global-images";

// components
import { Btn, InfoAlertCard, Input, Spinner } from "../../components";
import { getData } from "../../utils/common/storage-utils";
import sendUploadViaLinkEmail from "../../utils/helpers/API/UPLOAD_DOCUMENT_VIA_EMAIL";
import { toast } from "react-toastify";




const UserPassportLink = (props) => {
    const userData = getData('userData') || {}; // Fallback to an empty object if null or undefined
    const [timer, setTimer] = useState(90); // Countdown starts from 60 seconds
    const [isDisabled, setIsDisabled] = useState(true);

    const {closeLink} = props;

    useEffect(() => {
        let interval;
        if (timer > 0) {
          interval = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
          }, 1000);
        } else {
          setIsDisabled(false); // Enable the resend button when the countdown is complete
        }
    
        return () => clearInterval(interval); // Clear the interval when component unmounts or timer hits 0
    }, [timer]);

    // Handle the resend action here
    const handleResend = async () => {
        console.log("Resend OTP");
    
        // Reset the timer and disable the button again
        setTimer(90);
        setIsDisabled(true);
        let UniqueId = getData('verifyingUserId');
        let verificationData = { userEmail: userData.email, userId: UniqueId }
        try {
            const response = await sendUploadViaLinkEmail(verificationData); // Call the login API with form values
            if (response?.status !== 200) {
                console.log("API Response sendUploadViaLinkEmail:", response);
            }
            console.log("API Response sendUploadViaLinkEmail:", response);
        } catch (error) {
            setIsDisabled(false);
        }
    };

    const closeLinkModal = () => {
        closeLink(false)
    }

    // Format timer as minutes and seconds
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className="fullscreen-view-wrap no-header fullscreen-view-overlap">
            <div className="fs-body-wrap">
                <div className="loader-wrap loader-with-text">
                    <div className="loader-static">
                        <Spinner></Spinner>
                    </div>
                    <div className="text-wrap pt-20">
                        <p className="subtitle2 white-color">Document upload link has been sent to your email. Please upload.</p>
                    </div>
                </div>
            </div>
            <div className="fs-footer-wrap">
                <div className="btn-wrap btn-flex-between">
                    <div>
                        <Btn
                            size="lg"
                            label={'Back'}
                            iconPosition="left"
                            style="btn-outline-primary no-hover"
                            leftIcon={<img src={GLOBAL_IMAGES.ARROW_LEFT_ICON} alt={GLOBAL_IMAGES.ARROW_LEFT_ICON} className="btn-icon-left" />}
                            onClick={()=>{closeLinkModal()}}
                        />
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="text-wrap m-0">
                            <p className="white-color subtitle2">Resend link for verification <span className="font-med">{timer > 0 ? `${formatTime(timer)} seconds` : ""}</span> </p>
                        </div>
                        <Btn
                            variant="primary"
                            style="btn-primary-gradient ml-20"
                            size="lg"
                            label="Resend"
                            disabled={isDisabled}
                            leftIcon={<img src={GLOBAL_IMAGES.REDO_ICON} alt="REDO_ICON" className="btn-icon-left" />}
                            onClick={() => handleResend()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserPassportLink;
