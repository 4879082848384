import React, { useEffect, useRef, useState } from "react";
// images
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";

// components
import { Btn, IconButton } from "../../../components";
import EndSession from "../../../components/end-session";
import TextSpeechVoice from "../../../components/text-to-speech-voice";

import { GLOBAL_ANIMATION } from "../../../assets/animation/global-anime";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//style
import './index.scss'
import { useNavigate } from "react-router-dom";
import { UNEXPECTED_RESPONSE } from "../../../services/static-content";
import { getData, storeData } from "../../../utils/common/storage-utils";




const InformationNotify = ({ onContinue, data }) => {

    const [pagedata, setPagedata] = useState(data);
    const textSpeechRef = useRef(null);
    const [isAudioPlay, setIsAudioPlay] = useState(true);
    const isInitialMount = useRef(true);

    const navigate = useNavigate();
    const sessionEnd = () => {
        console.log('sessionEnd');
        navigate("/");
    };

    const handleSpeechPause = () => {
        if (textSpeechRef.current) {
            textSpeechRef.current.pauseSpeech();
        }
    };

    const handleClick = () => {
        handleSpeechPause();
        onContinue();
    };

    // handle the audio pause and restart
    const handleResume = (data) => {
        if (textSpeechRef.current) {
            textSpeechRef.current.restartSpeech();
        }
    };

    const handleAudio = (data) => {
        setIsAudioPlay(data);
        console.log(data, "_isAudio data")
        if(!data) {
            handleSpeechPause();
        } else {
            handleResume();
        }
        storeData("isAduioPlay", data);
    }

    const getStoreAudioStatus = () => {
        const getAudioStatus = getData("isAduioPlay");
        console.log(getAudioStatus, "getAudioStatus_notify")
        setIsAudioPlay(getAudioStatus);
    }

    useEffect(()=> {
        if (isInitialMount.current) {
            storeData("isAduioPlay", true);
            getStoreAudioStatus();
            
            isInitialMount.current = false; // Set flag to prevent future calls   
        }
    }, []);

    return (
        <React.Fragment>
            <div className="fullscreen-view-wrap fullscreen-view-overlap bg-tertiary-color">
                <img src={GLOBAL_IMAGES.SQUARE_SHAPE_DOTS} alt="SQUARE_SHAPE_DOTS" className="opacity-50 top-left-corner position-absolute" />
                <div className="fs-header-wrap">
                    <div className="d-flex justify-content-end">
                        <IconButton
                            src={isAudioPlay ? GLOBAL_IMAGES.SPEAKER_ON_ICON : GLOBAL_IMAGES.SPEAKER_OFF_ICON}
                            style="btn-outline-primary no-hover square-btn mr-20"
                            onClick={()=>{handleAudio(!isAudioPlay)}}
                        />
                        <EndSession handleSessionClose={handleSpeechPause}/>
                    </div>
                </div>
                <div className="fs-body-wrap fs-body-wrap-content">
                    <div className="content-wrap">
                        <div className="d-flex flex-column justify-content-between">
                            <div className="fs-body-logo-waves d-flex flex-column align-items-center">
                                <div className="circle-logo mb-40">
                                    <img src={GLOBAL_IMAGES.CIRCLE_LOGO} alt="CIRCLE_LOGO" />
                                </div>
                                <div className="sound-wave">
                                    {/* <img src={GLOBAL_IMAGES.SOUND_WAVE} alt="LOGO_WAVES" className="w-100" /> */}
                                    <div className="wave-animation">
                                        <Player
                                            speed={.8}
                                            autoplay
                                            loop={false}
                                            src={GLOBAL_ANIMATION.SOUND_WAVE}
                                            style={{ width: '70%' }}
                                        >
                                            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                                        </Player>
                                    </div>
                                </div>
                            </div>
                            <div className="fs-body-text-wrap">
                                <div className="d-flex justify-content-center">
                                    <div className="text-wrap text-center">
                                        <h2 className="heading mb-8 text-transform-capitalize">{pagedata.title}</h2>
                                        <h4 className="line-h-6">{pagedata.description}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fs-footer-wrap">
                    <div className="btn-wrap btn-flex-end">
                        {console.log('pagedata', pagedata)}
                        {pagedata.sessionEnd ? 
                            <Btn
                                variant="primary"
                                size="lg"
                                style={'btn-primary-gradient'}
                                label={'End Session'}
                                rightIcon={<img src={GLOBAL_IMAGES.ARROW_RIGHT_ICON} alt={GLOBAL_IMAGES.ARROW_RIGHT_ICON} className="btn-icon-right" />}
                                onClick={sessionEnd}
                            />
                            : <Btn
                                variant="primary"
                                size="lg"
                                style={'btn-primary-gradient'}
                                label={'Continue'}
                                leftIcon={<img src={GLOBAL_IMAGES.TICK_CIRCLE} alt={GLOBAL_IMAGES.TICK_CIRCLE} className="btn-icon-left" />}
                                onClick={handleClick}
                            />}
                    </div>
                </div>
            </div>
            {
                isAudioPlay && <TextSpeechVoice voiceText={pagedata.voiceText} ref={textSpeechRef}/>
            }
            
        </React.Fragment>
    );
};

export default InformationNotify;
