import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import "./dialog.scss";
import Btn from '../button/button';
import { GLOBAL_IMAGES } from '../../assets/images/global-images';
import { Player, Controls } from '@lottiefiles/react-lottie-player';


const Dialog = (props) => {
  // Destructure props with default values
  const {
    show = false,
    size = "lg",
    title = "",
    description = null,
    actions = null,
    fullscreen = null,
    backdrop = "static",
    centered = false,
    closeButton = true,
    showFooter = false,
    style = '',
    contentStyle = '',
    icon = '',
    onClick = null,
    btnLeftIcon = '',
    btnLabel = '',
    btnStyle = '',
    animatedIcon = '',
    iconSize = '',
  } = props;

  const handleClose = () => {
    props.close(!show);
  };

  return (
    <Modal
      show={show}
      size={size}
      onHide={handleClose}
      backdrop={backdrop}
      keyboard={false}
      fullscreen={fullscreen}
      centered={centered}
      className={style}
    >
      <Modal.Header closeButton={closeButton}>
      </Modal.Header>
      <Modal.Body>
        <div className={`${props.contentStyle} info-alert-card-wrap card-sm`}>
          {
            props.icon && (
              <div className={`img-wrap small-icon ${props.iconSize}`}>
                <img src={props.icon} alt="info-alert-icon" />
              </div>
            )
          }
          {
            props.animatedIcon && (
              <div className="animated-wrap">
                <Player
                  speed={.8}
                  autoplay
                  loop
                  src={props.animatedIcon}
                  style={{ width: '100%' }}
                >
                  <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                </Player>
              </div>
            )
          }

          <div className="text-wrap z-1">
            {props.title &&
              <p className="subtitle2 white-color font-med">
                {props.title}
              </p>
            }
            {props.description && <h4 className="mt-20 body1 white-color">{props.description}</h4>}
            {
              props.btnLabel && (
                <Btn
                  variant="primary"
                  style={props.btnStyle}
                  size="lg"
                  label={props.btnLabel}
                  leftIcon={<img src={props.btnLeftIcon} alt={props.btnLeftIcon} className="btn-icon-left" />}
                  onClick={props.onClick}
                />
              )
            }
          </div>
        </div>
      </Modal.Body>

      {actions && showFooter && <Modal.Footer>{actions}</Modal.Footer>}
    </Modal>
  );
};

/**
  * propTypes remain unchanged
 */

Dialog.propTypes = {
  show: PropTypes.bool,
  size: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  actions: PropTypes.object,
  fullscreen: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  backdrop: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  centered: PropTypes.bool,
  closeButton: PropTypes.bool,
  showFooter: PropTypes.bool,
  style: PropTypes.string,
  contentStyle: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func, // Updated to accept a function
  btnLeftIcon: PropTypes.string,
  btnLabel: PropTypes.string,
  btnStyle: PropTypes.string,
  iconSize: PropTypes.string,
  animatedIcon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default Dialog;
