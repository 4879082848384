import PropTypes from 'prop-types';

// framework
import { Form, InputGroup } from 'react-bootstrap';

// plugin
import { DatePicker } from '@y0c/react-datepicker';


// style
import "../input-date-time.scss";

const InputDate = (props) => {

  const {
    label = '',
    id = '',
    placeholder = '',
    style = '',
    labelStyle = '',
    initialDate = null,
    dateFormat = 'YYYY/MM/DD', // YYYY/MM/DD HH =MM A
    containerStyle = '',
    helperText = '',
    disabled = false,
    isValid = false,
    isInvalid = false,
    readOnly = false,
    includeTime = false,
    showToday = true,
    onChange = () => { },
    disableDay = () => { },
    leftContent,
    rightContent,
  } = props

  // const now = new Date();
  // let dayObj = dayjs(props.initialDate);
  // console.log(props.initialDate);
  const handleChange = (date) => {
    // Day.js object
    console.log(date);
    props.onChange(date);

    // to normal Date object
    // console.log(dayjs(date.toDate()));
  }

  return (
    <Form.Group controlId={id} className={`${containerStyle} form-group ${readOnly && "form-read-only"}`}>
      {label && <Form.Label className={`${labelStyle}`}>
        {label}
      </Form.Label>}
      <div className="form-control-wrap">
        <InputGroup className={`form-date-group ${isInvalid ? 'is-invalid' : ''} ${isValid ? 'is-valid' : ''}`}>
          {leftContent}
          <div className={`form-date-wrap ${isInvalid ? 'is-invalid' : ''} ${isValid ? 'is-valid' : ''}`}>
            <DatePicker
              initialDate={initialDate ? new Date(initialDate) : null}
              onChange={handleChange}
              showDefaultIcon={true}
              readOnly={readOnly}
              disabled={disabled}
              placeholder={placeholder}
              showToday={showToday}
              dateFormat={dateFormat}
              // onClear={(data) => { console.log(data, "asd") }}
              includeTime={includeTime}
              clear={!disabled && !readOnly}
              disableDay={disableDay}
            />
          </div>
          {rightContent}
        </InputGroup>
      </div>
      
      {
        helperText && <Form.Control.Feedback type={`${isValid && 'valid'} ${isInvalid && 'invalid'}`}>{helperText}</Form.Control.Feedback>
      }
    </Form.Group>
  )
}

/**
	* label: set label text
	* id: set element id 
	* placeholder: placeholder of input 
	* initialDate: Initial display date
	* dateFormat: set date format
	* style: Manually style the control
	* labelStyle: Manually style the label
	* containerStyle: Manually style the wrapper
	* helperText:  providing helper text 
	* disabled: Make the control disabled
	* isValid : Manually style the input as valid
	* isInvalid : Manually style the input as invalid
	* readOnly : Readlonly element
	* includeTime : include TimePicker true/false
	* showToday : TodayPanel show or hide
	* onChange: handlers from firing regardless of the rendered element.
	* disableDay: Calendar day disable
*/


InputDate.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.string,
  labelStyle: PropTypes.string,
  initialDate: PropTypes.object,
  dateFormat: PropTypes.string,
  containerStyle: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  isValid: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool
	]),
	isInvalid: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool,
	]),
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  includeTime: PropTypes.bool,
  showToday: PropTypes.bool,
  disableDay: PropTypes.func,
  leftContent: PropTypes.object,
  rightContent: PropTypes.object,
};

/* InputDate.defaultProps = {
  label: '',
  id: '',
  placeholder: '',
  style: '',
  labelStyle: '',
  initialDate: null,
  dateFormat: 'YYYY/MM/DD', // YYYY/MM/DD HH:MM A
  containerStyle: '',
  helperText: '',
  disabled: false,
  isValid: false,
  isInvalid: false,
  readOnly: false,
  includeTime: false,
  showToday: true,
  onChange: () => { },
  disableDay: () => { }
} */

export default InputDate