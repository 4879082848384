import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// framework

// plugin
import { Formik } from "formik";

// styles
import "./login.scss";

// images
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";

// components
import { Btn, Dialog, Input, Spinner } from "../../../components";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// email
import { EMAIL_ID_REGEX } from "../../../utils/helpers/regex";
import loginApi from "../../../utils/helpers/API/USER_API";
import { sendVerificationEmail, emailVerificationStatus } from "../../../utils/helpers/API/VERIFICATION_EMAIL";
import { clearStorage, getData, removeItem, storeData } from "../../../utils/common/storage-utils";
import { ENVIRONMENT, URL } from "../../../utils/config/constants";
import { getCountries } from "../../../utils/helpers/API/COUNTRIES_API";
import { MessageContext } from "../../../components/message-provider";
import TextSpeechVoice from "../../../components/text-to-speech-voice";




const LoginCard = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [ws, setWs] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [payload, setPayload] = useState({});
    const [paymentRefresh, setPaymentRefresh] = useState(false);
    const [timer, setTimer] = useState(90); // Countdown starts from 60 seconds
    const [isDisabled, setIsDisabled] = useState(true);
    const [intervalId, setIntervalId] = useState(null);
    const { setMessage } = useContext(MessageContext);
    const [voiceText, setVoiceText] = useState("");
    const [polling, setPolling] = useState(true);
    const [emailVerified, setEmailVerified] = useState(false);
    let setFieldValueFunction = null;

    const [showDialog, setShowDialog] = useState(false);
    const handleClose = () => setShowDialog(false);

    const handleSelectFieldObject = (setFieldValue) => {
        setFieldValueFunction = setFieldValue
    }
    // Function to generate unique IDs
    const generateUniqueId = () => {
        return Math.random().toString(36).substring(7);
    };
    const searchCountry = async (value) => {
        try {
            const response = await getCountries(value); // Pass any necessary props here
            if (response?.data?.status === 200) {
                if (response.data.data.length > 0) {
                    console.log('response?.data?', response?.data?.data[0]?.Country);
                    //setNationality(response?.data?.data[0]?.Country);
                    // registerFormData.country = nationality;
                    return { label: response?.data?.data[0]?.Country, value: response?.data?.data[0]?.Id }
                }
            }
        } catch (error) {
            console.error("Error fetching countries:", error);
        } finally {
            setIsLoading(false); // Stop loading state
        }
    };
    useEffect(() => {
        clearStorage();
        setMessage("")
        // Connect to WebSocket server
        const ws = new WebSocket(`${URL["WEB_SOCKET_URL_" + ENVIRONMENT]}`);
        console.log('ws', ws)
        setWs(ws);
        // Send the userId to the server after connecting
        const userId = generateUniqueId(); // Generate a unique ID
        storeData("verifyingUserId", userId);
        ws.onopen = () => {
        // const userId = generateUniqueId(); // Generate a unique ID
        // storeData("verifyingUserId", userId);
            ws.send(JSON.stringify({ userId })); // Send the userId to the server
        }; 

        // return () => {
        //     ws.close();
        // };
    }, [refresh]);

    useEffect(() => {
        if (!ws) return;

        const handleWebSocketMessage = async (event) => {
            console.log("Event", event.data);
            
            const { status } = JSON.parse(event.data);
            console.log(`Verification status changed to ${status}`);
            /* if (status === "payment") {
                const socketData = JSON.parse(event.data);
                console.log("Payload", socketData);
                console.log("payment")
                storeData('socketData', socketData?.payload);
                setPaymentRefresh(!paymentRefresh)
                //navigate(`/application-summary`);
                setMessage(event.data)
            } */

            if (status === "accepted") {
                // checkMail();
            }



            if (status === "passport") {
                const socketData = JSON.parse(event.data);
                console.log("passport socketData", socketData);
                 
                const imageFile= await binaryData(socketData?.file)
                console.log('imageFile',imageFile)
                const updatedPayload = {
                    ...socketData?.payload,
                    countryData: JSON.parse(socketData?.payload.countryData),
                    passportFile: imageFile
                };
                
                console.log('updatedPayload',updatedPayload);
                navigate(`/stakeholder-details`, { state: { params: updatedPayload } });
            }

            if (status !== "accepted") {
                setRefresh(!refresh);
            }
        };

        ws.onmessage = handleWebSocketMessage;
    }, [ws, refresh, payload, paymentRefresh]);

    const notifySuccess = () => toast.success('Email sent successfully, please check your email and verify for login', {
        autoClose: 7000, // 3 seconds
    });
    const binaryData = (passportFile) => {
        return new Promise((resolve, reject) => {
            if (passportFile) {
                // Convert buffer data to Uint8Array
                const bufferData = new Uint8Array(passportFile.buffer.data);
    
                // Create a Blob from the Uint8Array
                const blob = new Blob([bufferData], { type: passportFile.mimetype });
    
                // Create a File from the Blob
                const file = new File([blob], passportFile.originalname, { type: passportFile.mimetype });
    
                // Create a URL for downloading the file
                // const fileURL = URL.createObjectURL(file);
    
                // Optional: You can return the file or fileURL depending on your use case
                resolve(file); // Or resolve(fileURL) if you want the URL instead
    
            } else {
                const errorMessage = 'Please upload a valid JPEG or PNG image.';
                console.error(errorMessage);
                reject(errorMessage); // Reject the promise if the file is not valid
            }
        });
    };


    const handleSubmit = async (values) => {
        setTimer(90)
        setIsLoading(true);
        setVoiceText("A verification link has been sent to your email. Please verify.");
        document.querySelector("#email").blur();
        try {
            const userId = getData('verifyingUserId');
            setPayload(values);
            let verificationData = { userEmail: values?.email, userId: userId };
            try {
                //notifySuccess();
                resendEmail();
                const response = await sendVerificationEmail(verificationData); // Call the login API with form values
                // if (response?.data?.status === 403) {
                //     setShowDialog(true);
                // }
                if (response?.status !== 200) {
                    setIsLoading(false);
                } else {
                    // pooling verification api
                    setEmailVerified(true);
                    setPolling(false);
                    console.log('polling started');

                    //notifySuccess();
                }
                console.log("API Response:", response);
            } catch (error) {
                setIsLoading(false);
                console.error("API call error:", error);
            }
        } catch (error) {
            setIsLoading(false);
            console.error("API call error:", error);
        }
    };

    const handleSubmitForResend = async (newUserId) => {
        setPolling(true);
        setEmailVerified(false);
        setIsLoading(true);
        document.querySelector("#email").blur();
        try {
            const values = payload; // Use the existing payload values
            let verificationData = { userEmail: values?.email, userId: newUserId };
            try {
                //notifySuccess();
                resendEmail();
                const response = await sendVerificationEmail(verificationData); // Call the login API with form values
                if (response?.status !== 200) {
                    setIsLoading(false);
                } else {
                    setPolling(false);
                    setEmailVerified(true)
                    console.log('polling started');

                }
                console.log("API Response:", response);
            } catch (error) {
                setIsLoading(false);
                console.error("API call error during resend:", error);
            }
        } catch (error) {
            setIsLoading(false);
            console.error("API call error during resend:", error);
        }
    };

    const resendEmail = () => {
        if (intervalId) {
            clearInterval(intervalId);
        }

        const id = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 1) {
                    clearInterval(id);
                    setIsDisabled(false);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        setIntervalId(id);
    };

    const handleResend = () => {
        console.log("Resend OTP");
        // const userId = getData('verifyingUserId');
        const userId = generateUniqueId(); // Generate a unique ID again
        storeData("verifyingUserId", userId);
        setTimer(90);
        setIsDisabled(true);
        handleSubmitForResend(userId);
    };

    const handleBack = () => {
        setIsLoading(false);
        setTimer(0);
        setIsDisabled(true);
        setPolling(true); // Stop polling
        setFieldValueFunction("email", "");
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const checkMail = async () => {
        try {
            setIsLoading(true);
            const response = await loginApi(payload); // Call the login API with form values
            console.log("API Response:", response);

            if (response?.data?.status === 200) {
                setIsLoading(false);

                let path = response?.data?.data?.redirect;

                if (path === 'new-user') {
                    payload['newUser'] = response?.data?.data?.newUser;
                }

                if (response?.data?.data?.userData) {
                    console.log('userData', response?.data?.data?.userData);

                    // Extract the user data
                    const getUserData = response?.data?.data?.userData;

                    const nationalityRes = await searchCountry(getUserData?.country);

                    // Get the first letter of firstName and lastName
                    const firstName = getUserData?.firstName;
                    const lastName = getUserData?.lastName;
                    const shortName = firstName.charAt(0) + lastName.charAt(0);
                    getUserData.country = nationalityRes.label
                    getUserData.nationality = nationalityRes.label
                    getUserData.nationalityRes = nationalityRes
                    // Update the getUserData object with the shortName
                    getUserData.shortName = shortName;

                    // Store the updated userData object
                    console.log('getUserData', getUserData);
                    storeData('userData', getUserData);
                }
                else {
                    storeData('userData', payload);
                }

                navigate(`/${path}`, { state: { params: payload } });
            }
            else {
                setIsLoading(false);
                console.error("Login failed", response.data);
            }
        } catch (error) {
            console.error("API call error:", error);
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        if (emailVerified) {
            console.log('polling inside useefeect', emailVerified);
            const POLLING_INTERVAL = 5000; // Poll every 5 seconds
            const emailVerification = async () => {
                const userId = getData('verifyingUserId');

                try {
                    const response = await emailVerificationStatus(userId); // Call the login API with form values
                    if (response.data.status === 200 && response.data.data.emailVerified) {
                        await checkMail();
                    }

                } catch (error) {
                    console.error("API call error:", error);
                } finally {

                }
            }

            // Set up the polling
            if (!polling) {
                const intervalId = setInterval(emailVerification, POLLING_INTERVAL);

                // Cleanup the interval on component unmount
                return () => clearInterval(intervalId);
            }
        }
    }, [emailVerified, polling]);

    return (
        <React.Fragment>
            <div className="card-wrap zoom-1">
                <div className="card-header">
                    <div className="login-img-wrap">
                        <img src={GLOBAL_IMAGES.LOCK_BAND} alt="LOCK_BAND" className="w-100" />
                    </div>
                </div>
                <div className="card-body">
                    <div className="text-wrap mb-20">
                        <h5 className="white-color text-center">Login</h5>
                    </div>


                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            email: ""
                        }}
                        validate={(values) => {
                            const errors = {};
                            if (!values.email) {
                                errors.email = "Required";
                            } else if (!EMAIL_ID_REGEX.test(values.email)) {
                                errors.email = "Invalid email address";
                            }

                            console.log("validate", values, errors);
                            return errors;
                        }}
                        onSubmit={(values) => {
                            handleSubmit(values);
                        }}
                    >
                        {({
                            handleChange, handleBlur, handleSubmit, errors, touched, isValid, dirty, values, setFieldValue
                        }) => (
                            <Form className="form-wrap z-1" onSubmit={handleSubmit} autoComplete="off">
                                {handleSelectFieldObject(setFieldValue)}
                                <Row>
                                    <Col sm={12}>
                                        <Input
                                            autoFocus={true}
                                            leftContent={
                                                <InputGroup.Text className="left-content-group">
                                                    <img src={GLOBAL_IMAGES.USER_ICON} alt="USER_ICON" />
                                                </InputGroup.Text>
                                            }
                                            label="Stakeholder’s Email"
                                            type="email"
                                            id="email"
                                            value={values.email}
                                            containerStyle={"mb-32 input-tertiary-color input-with-group"}
                                            onChange={handleChange("email")}
                                            onBlur={handleBlur("email")}
                                            isInvalid={errors.email && touched.email}
                                            helperText={
                                                errors.email &&
                                                touched.email &&
                                                errors.email
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <div className="btn-wrap">
                                            <Btn
                                                variant="secondary"
                                                style="btn-primary-gradient w-100"
                                                size={"lg"}
                                                type="submit"
                                                label="Send verification link"
                                                disabled={!isValid || !dirty}
                                                rightIcon={<img src={GLOBAL_IMAGES.LINK_ICON} className="btn-icon-right" />}
                                                onClick={() => { console.log("test") }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                    {
                        isLoading && (
                            <div className="fullscreen-view-wrap no-header fullscreen-view-overlap z-2">
                                <div className="fs-body-wrap">
                                    <div className="loader-wrap loader-with-text">
                                        <div className="loader-static">
                                            <Spinner></Spinner>
                                        </div>
                                        <div className="text-wrap pt-20">
                                            <p className="subtitle2 white-color">A verification link has been sent to your email. Please verify.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="fs-footer-wrap">
                                    <div className="btn-wrap btn-flex-between">
                                        <div>
                                            <Btn
                                                size="lg"
                                                label={'Back'}
                                                iconPosition="left"
                                                style="btn-outline-primary no-hover"
                                                leftIcon={<img src={GLOBAL_IMAGES.ARROW_LEFT_ICON} alt={GLOBAL_IMAGES.ARROW_LEFT_ICON} className="btn-icon-left" />}
                                                onClick={()=>{handleBack()}}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="text-wrap m-0">
                                                <p className="subtitle2 white-color">Resend link for verification <span className="font-med">{timer > 0 ? `${formatTime(timer)} seconds` : ""}</span> </p>
                                            </div>
                                            <Btn
                                                variant="primary"
                                                style="btn-primary-gradient ml-20"
                                                size="lg"
                                                label="Resend"
                                                disabled={isDisabled}
                                                leftIcon={<img src={GLOBAL_IMAGES.REDO_ICON} alt="REDO_ICON" className="btn-icon-left" />}
                                                onClick={() => handleResend()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <ToastContainer />
            <TextSpeechVoice voiceText={voiceText} />
            <Dialog
                show={showDialog}
                close={handleClose}
                title="Access Denied"
                backdrop="static"
                closeButton={false}
                showFooter={false}
                style={'custom-modal'}
                icon={GLOBAL_IMAGES.WARNING_ICON}
            />
        </React.Fragment>
    );
};

export default LoginCard;
