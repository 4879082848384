// utils/storageUtils.js

/**
 * Store data in localStorage
 * @param {string} key - The key to store the data under
 * @param {any} value - The data to store
 */
export function storeData(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    // Optionally log or handle success
    // console.log('Data stored in localStorage');
  } catch (error) {
    console.error('Error storing data in localStorage', error);
  }
}

/**
 * Retrieve data from localStorage
 * @param {string} key - The key to retrieve data from
 * @returns {any} The retrieved data
 */
export function getData(key) {
  try {
    const dataObj = localStorage.getItem(key);
    // Optionally log or handle success
    // console.log('Data from localStorage', dataObj);
    return dataObj ? JSON.parse(dataObj) : null;
  } catch (error) {
    console.error('Error retrieving data from localStorage', error);
    return null;
  }
}

/**
 * Remove data from localStorage
 * @param {string} key - The key to remove data for
 */
export function removeItem(key) {
  try {
    localStorage.removeItem(key);
    // Optionally log or handle success
    // console.log('Data removed from localStorage');
  } catch (error) {
    console.error('Error removing data from localStorage', error);
  }
}

/**
 * Clear all data from localStorage
 */
export function clearStorage() {
  try {
    localStorage.clear();
    // Optionally log or handle success
    // console.log('All data cleared from localStorage');
  } catch (error) {
    console.error('Error clearing localStorage', error);
  }
}
