import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// plugin
import * as sdk from "microsoft-cognitiveservices-speech-sdk";

// Framework
import { Col, Container, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

// Images
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";

// Components
import { Btn, Dialog, IconButton, Spinner } from "../../../components";
import VoiceAnimation from "../../../components/voice-recording-animation";
import EndSession from "../../../components/end-session";
import TextSpeechVoice from "../../../components/text-to-speech-voice";
import { toast, ToastContainer } from "react-toastify";

// utils
import { customerInteractionApi } from "../../../utils/helpers/API/USER_JOURNEY";

// CSS
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";

// services
import {
  SERVER_ERROR_DIALOG_DATA,
  ACTIVITIES_CHAT_DIALOG_DATA,
} from "../../../services/static-content";
import { getData, storeData } from "../../../utils/common/storage-utils";
import { TypeAnimation } from "react-type-animation";
import { htmlToText } from "html-to-text";

const ApplicationChat = ({
  onContinue,
  onBack,
  setAllActivities,
  activitiesData,
}) => {
  const audioChunk = useRef([]);
  const isInitialMount = useRef(true);
  const mediaRecorderRef = useRef(null);
  const chatContainerRef = useRef(null);
  const inputChatRef = useRef(null);
  const audioBarsRef = useRef([]);
  const audioRef = useRef(null);
  const audioRef1 = useRef(null);
  const [recording, setRecording] = useState(null);
  const [streamRecording, setStreamRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [error, setError] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [message, setMessage] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [sentRecording, setSentRecording] = useState(false);
  const [noactivityfound, setNoactivityfound] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [activitiesLogData, setActivitiesLogData] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showChatDialog, setShowChatDialog] = useState(false);
  const [isChatTyping, setIsChatTyping] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [dialogData, setDialogData] = useState({});
  const userData = getData("userData") || {};
  const [playingIndex, setPlayingIndex] = useState(null);
  const [voiceText, setVoiceText] = useState("");
  const [isAudioPlay, setIsAudioPlay] = useState(null);

  const [userUniqueId, setUserUniqueId] = useState("");

  const activitiesInit = {
    type: "chat",
    text: `<p>Hello <span class="text-transform-capitalize">${userData.firstName.toLowerCase()}</span>, to help us determine the most suitable activities for the license, could you provide additional information about the company you are registering?</p>`,
    audio: null,
    chatAgain: false,
  };

  // Function to generate unique IDs
  const generateUniqueId = () => {
    return Math.random().toString(36).substring(7);
  };

  //azure implementation

  const [transcript, setTranscript] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [finalTranscript, setFinalTranscript] = useState("");
  const [audioUrl, setAudioUrl] = useState(""); // State to store the audio URL
  const recognizerRef = useRef(null);
  const audioChunksRef = useRef([]);
  const textSpeechRef = useRef(null);

  const startRecording = async () => {
    try {
      audioChunk.current = [];
      setRecording(null);
      setError(null);
      handleSpeechPause();

      //azure code

      // Stop any previous recording
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current = null;
        audioChunksRef.current = [];
        setAudioUrl(""); // Clear the old audio URL
      }

      // Get MediaStream from user's microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      const speechConfig = sdk.SpeechConfig.fromSubscription(
        "7fd689deb0e541b086e48841fb70b9ec",
        "uaenorth"
      );
      const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();

      const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
      recognizerRef.current = recognizer;

      recognizer.recognizing = (s, e) => {
        setTranscript(e.result.text);
      };

      recognizer.recognized = (s, e) => {
        if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
          setTranscript(e.result.text);
        } else if (e.result.reason === sdk.ResultReason.NoMatch) {
          console.log("No speech could be recognized.");
        }
      };

      recognizer.startContinuousRecognitionAsync();
      setIsListening(true);

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          audioChunk.current.push(e.data);
        }
      };

      mediaRecorder.onstop = async () => {
        try {
          const audioBlob = new Blob(audioChunk.current, { type: "audio/mpeg" });
          const audioUrl = URL.createObjectURL(audioBlob);
          setRecording(audioUrl);
          audioChunksRef.current = []; // Clear the audio chunks
        } catch (err) {
          console.error(
            "Error while processing or sending the audio data:",
            err
          );
          setError("Error while processing or sending the audio data.");
        }
      };

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setStreamRecording(true);
      setIsPaused(false);
    } catch (err) {
      console.error("Error accessing microphone:", err);
      setError("Error accessing microphone.");
    }
  };

  const pauseRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
    }
  };

  const resumeRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "paused"
    ) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
    }
  };

  const stopRecording = async () => {
    try {
      setTimeout(() => {
        if (
          mediaRecorderRef.current &&
          (mediaRecorderRef.current.state === "recording" ||
            mediaRecorderRef.current.state === "paused")
        ) {
          mediaRecorderRef.current.stop();
          setStreamRecording(false);
          setIsPaused(false);
        }
        if (recognizerRef.current) {
          recognizerRef.current.stopContinuousRecognitionAsync(
            () => {
              setIsListening(false);
              setFinalTranscript(transcript);
              recognizerRef.current.close();
              recognizerRef.current = null;
              handleSpeechPause();
            },
            (err) => {
              console.error("Error stopping recognition:", err);
              setIsListening(false);
            }
          );
        }

        if (mediaRecorderRef.current) {
          mediaRecorderRef.current.stop(); // Stop recording
        }
      }, 1000);
    } catch (err) {
      console.error("Error stopping recording:", err);
      setError("Error stopping recording.");
    }
  };

  const deleteRecording = () => {
    setRecording(null);
    audioChunk.current = [];
    setStreamRecording(false);
    setIsPaused(false);
    setIsPlaying(false);
  };

  const sendRecording = async () => {
    if (recording) {
      try {
        setUserMessage(message);
        setMessage("");
        setIsTyping(false);
        setSentRecording(true);
        deleteRecording();

        const _chatPayload = {
          type: "user",
          text: transcript,
          audio: recording,
          chatAgain: false,
        };

        // console.log(chatStepNumber, "chatStepNumber recording")
        getCustomerInteraction(_chatPayload);
      } catch (err) {
        console.error("Error sending recording:", err);
        setError("Error sending recording.");
      }
    } else if (isTyping) {
      handleSpeechPause();
      setSentRecording(false);
      deleteRecording();
      setUserMessage(message);
      setMessage("");
      setIsTyping(false);
      console.log("message", message);

      const _chatPayload = {
        type: "user",
        text: message,
        audio: null,
        chatAgain: false,
      };

      // console.log(chatStepNumber, "chatStepNumber message")
      getCustomerInteraction(_chatPayload);
    }
  };

  const handlePlayPause = () => {

    // Initialize the audio object if it does not exist
    if (!audioRef1.current) {
      audioRef1.current = new Audio(recording);
    }

    const audio =  audioRef1.current;

    recording && new Audio(recording).play();

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
  };

  const handleChatPlayPause = (index, audioSrc) => {
    // Initialize the audio object if it does not exist
    if (!audioRef.current[index]) {
      audioRef.current[index] = new Audio(audioSrc);
    }
    const audio = audioRef.current[index];
    if (playingIndex === index) {
      // Toggle play/pause for the currently playing audio
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
        setPlayingIndex(null);
      }
    } else {
      // Pause the currently playing audio if there is one
      if (playingIndex !== null) {
        const playingAudio = audioRef.current[playingIndex];
        playingAudio.pause();
        playingAudio.currentTime = 0; // Reset the audio to the start
      }

      // Play the new audio and update the playing index
      audio.currentTime = 0; // Start the new audio from the beginning
      audio.play();
      setPlayingIndex(index);
    }

    // Reset the playing index when the audio ends
    audio.onended = () => {
      setPlayingIndex(null);
    };
  };

  const reRecord = () => {
    handleSpeechPause();
    setUserMessage("");
    setRecording(null);
    audioChunk.current = [];
    setStreamRecording(false);
    setIsPaused(false);
    setIsPlaying(false);
    setNoactivityfound(false);
    setIsloading(false);
    setSentRecording(false);
  };

  const handleSpeechPause = () => {
    if (textSpeechRef.current) {
      textSpeechRef.current.pauseSpeech();
    }
  };

  const handleResume = (data) => {
    if (textSpeechRef.current) {
      textSpeechRef.current.restartSpeech();
    }
  };

  const handleBackClick = () => {
    if (chatList.length == 1) {
      handleSpeechPause();
      onBack();
    } else {
      setShowChatDialog(true);
      setDialogData(ACTIVITIES_CHAT_DIALOG_DATA);
    }
  };
  const seeAllActivities = () => {
    handleSpeechPause();
    setAllActivities(true);
    onContinue();
    activitiesData(activitiesLogData);
  };
  const submitData = () => {
    handleSpeechPause();
    //onContinue();
    getCustomerInteraction("ContinueEZMS");
  };

  const notifyError = (message) =>
    toast.error(message, {
      autoClose: 10000, // 3 seconds
    });

  // api extractCategoriesFunctionApi
  const getCustomerInteraction = async (data) => {
    // setIsloading(true);
    let _payload = "";
    if (data == "ContinueEZMS") {
      setIsloading(true);
      _payload = "ContinueEZMS";
    } else {
      setIsChatTyping(true);
      addNewMessage(data);

      // console.log(chatList, "user_chatList")
      // console.log("setFinalTranscript ", transcript);

      if (chatList.length == 1) {
        _payload = `Hello, I am ${userData.firstName},` + data.text;
      } else {
        _payload = data.text;
      }
    }

    try {
      // const response = await customerInteractionApi(_payload, "act_"+userData.spcUserId);
      const response = await customerInteractionApi(_payload, userUniqueId);

      if (data == "ContinueEZMS") {
        if (response.status === 200) {
          console.log(response, "ContinueEZMS");
          const _tempResponse = response.data.response;
          if (
            _tempResponse.activityCode == null ||
            _tempResponse.customerCountries.length == 0 ||
            _tempResponse.supplierCountries.length == 0 ||
            _tempResponse.customerCountries[0].trim().length == 0 ||
            _tempResponse.supplierCountries[0].trim().length == 0
          ) {
            notifyError("Please submit all information");
          } else {
            const userData = getData("userData") || {};
            const _tempActivities = {
              activitycode: _tempResponse.activityCode,
              englishname: _tempResponse.activityName,
            };

            userData.activities = _tempActivities;
            storeData("userData", userData);

            storeData("chatList", chatList);
            storeData("chatInfo", _tempResponse);

            onContinue();
          }

          setIsloading(false);
        } else {
          notifyError("Please submit all information");
          setIsloading(false);
        }
      } else {
        if (response.status === 200) {
          const _responsePayload = {
            type: "chat",
            text: response.data.response,
            audio: null,
            chatAgain: false,
          };

          addNewMessage(_responsePayload);
          // console.log(chatList, "bot_chatList")
          setIsChatTyping(false);
          // document.querySelector("#input-chat").focus();
          // console.log(inputChatRef, inputChatRef.current, "inputChatRef")
          handleChatInputFocus();
        } else {
          notifyError("Something went wrong. Please try again");
          setIsChatTyping(false);
          // document.querySelector("#input-chat").focus();
          handleChatInputFocus();
        }
      }

      // console.log(response, "getCustomerInteraction");
    } catch (error) {
      console.log(error);
      notifyError("Something went wrong. Please try again");
      setIsloading(false);
      setIsChatTyping(false);
      // document.querySelector("#input-chat").focus();
      handleChatInputFocus();
      // handleOpen();
      // setDialogData(SERVER_ERROR_DIALOG_DATA)
    }
  };

  const handleChatInputFocus = () => {
    setTimeout(()=>{
      inputChatRef.current.focus();
    }, 50)
  }

  const addNewMessage = (newMessage) => {
    setChatList((prevChatList) => [...prevChatList, newMessage]);
  };

  // dialog handle
  const handleOpen = () => {
    setShowDialog(true);
  };
  const handleClose = () => {
    setShowDialog(false);
    setShowChatDialog(false);
  };
  const handleChatClose = () => {
    storeData("chatList", chatList);

    setShowChatDialog(false);
    handleSpeechPause();
    onBack();
  };

  const welcomeAgain = () => {
    const _responsePayload = {
      type: "chat",
      text: `<p>Welcome again, ${userData.firstName} you can continue with the chat.</p>`,
      audio: null,
      chatAgain: true,
    };

    addNewMessage(_responsePayload);
  };

  // const voiceText = noactivityfound ? 'I was unable to determine the activities based on the information provided. Would you like to select them yourself?' : "To help us determine the most suitable activities for the license, could you provide additional information about the company you are registering?";

  useEffect(() => {
    if (isInitialMount.current) {
      const _tempChatList = getData("chatList"); // check data is store is local
      if (_tempChatList) {
        console.log("set saved chat list");
        setChatList(_tempChatList);
      } else {
        console.log("add activitiesInit if not found any chat");
        addNewMessage(activitiesInit); // set initial chat
      }

      // welcome again
      console.log(chatList, "chatList isInitialMount");
      if (_tempChatList && _tempChatList.length > 1) {
        // check the last welcome message in list
        const _templastMessageWelcome = _tempChatList[_tempChatList.length - 1];

        if (!_templastMessageWelcome.chatAgain) {
          welcomeAgain();
        }
      }

      isInitialMount.current = false; // Set flag to prevent future calls
      // setVoiceText("To help us determine the most suitable activities for the license, could you provide additional information about the company you are registering?");

      // for testing generate unique id
      /* const _uniqueId = generateUniqueId();
                setChatList([]);
                addNewMessage(activitiesInit);
                setUserUniqueId(_uniqueId);
                console.log(_uniqueId, "_uniqueId") */
      // for testing generate unique id

      const _getUserUniqueId = getData("userUniqueId");
      if (_getUserUniqueId) {
        setUserUniqueId(_getUserUniqueId);
      } else {
        const _userUniqueIdInit = userData.shortName + "_" + generateUniqueId();
        storeData("userUniqueId", _userUniqueIdInit);
        setUserUniqueId(_userUniqueIdInit);
      }
      console.log(_getUserUniqueId, "setUserUniqueId");

      // getStoreAudioStatus();
    }
  }, []);

  useEffect(() => {
    // Check if content overflows and scroll to bottom
    const chatContainer = chatContainerRef.current;

    if (chatList.length > 1) {
      if (chatContainer) {
        const { scrollHeight, clientHeight } = chatContainer;
        if (scrollHeight > clientHeight) {
          chatContainer.scrollTop = scrollHeight;
        }
      }
      console.log(chatList, "chatList");
    }

    // play for all audio
    /* const _voiceText = getLastMessageText(chatList);
        const firstParagraph = extractFirstParagraph(_voiceText);
        setVoiceText(firstParagraph); // dont speech first paragraph also */

    if (chatList && chatList.length == 1 && isAudioPlay) {
      // console.log(chatList, "chatList")
      // extract the last object to get the text
      const _voiceText = getLastMessageText(chatList);
      const firstParagraph = extractFirstParagraph(_voiceText);
      console.log(firstParagraph, "firstParagraph");
      // setVoiceText("To help us determine the most suitable activities for the license, could you provide additional information about the company you are registering?");
      setVoiceText(firstParagraph);
    }
  }, [chatList]); // Re-run this effect whenever chatList changes

  useEffect(() => {
    // get isAudio is set
    if (!isAudioPlay) {
      handleSpeechPause();
    } else {
      handleResume();
    }
  }, [isAudioPlay]);

  useEffect(() => {
    if (inputChatRef.current) {
      handleChatInputFocus();
    }
  }, []);

  // get the last text message
  const getLastMessageText = (messages) => {
    if (messages.length === 0) {
      return "No messages available.";
    }

    const lastMessage = messages[messages.length - 1];
    return lastMessage && lastMessage.text
      ? lastMessage.text
      : "No text available.";
  };

  // extract extractFirstParagraph
  const extractFirstParagraph = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const firstParagraphElement = doc.querySelector("p");

    if (firstParagraphElement) {
      return removeAllEmojis(firstParagraphElement.textContent);
    } else {
      return " ";
    }
  };

  // remove emoji
  const removeAllEmojis = (text) => {
    return text.replace(
      /[\p{Emoji}\p{Emoji_Presentation}\p{Extended_Pictographic}\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}\u{2B06}\u{2934}\u{2935}\u{25AA}\u{25AB}\u{2B06}\u{2194}-\u{21AA}\u{2B50}\u{2B06}\u{1F004}-\u{1F0CF}\u{1F5FB}-\u{1F64F}]/gu,
      ""
    );
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "You will lose your data on refresh!";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // handle the audio pause and restart
  const handleAudio = (data) => {
    setIsAudioPlay(data);
    console.log(data, "_isAudio data");
    if (!data) {
      handleSpeechPause();
    } else {
      handleResume();
    }
    storeData("isAduioPlay", data);
  };

  const getStoreAudioStatus = () => {
    const getAudioStatus = getData("isAduioPlay");
    console.log(getAudioStatus, "getAudioStatus_chat");
    setIsAudioPlay(getAudioStatus);
  };

  return (
    <React.Fragment>
      <div className="fullscreen-view-wrap fullscreen-view-overlap bg-tertiary-color">
        <div className="fs-header-wrap">
          <div className="d-flex justify-content-between">
            <div className="logo-wrap mb-0">
              <img src={GLOBAL_IMAGES.LOGO} alt="LOGO" />
            </div>
            <div>
              {/* <IconButton
                                src={isAudioPlay ? GLOBAL_IMAGES.SPEAKER_ON_ICON : GLOBAL_IMAGES.SPEAKER_OFF_ICON}
                                style="btn-outline-primary no-hover square-btn mr-20"
                                onClick={()=>{handleAudio(!isAudioPlay)}}
                            /> */}
              {/* <IconButton
                src={GLOBAL_IMAGES.SPEAKER_OFF_ICON}
                style="btn-outline-primary no-hover square-btn mr-20"
                // onClick={()=>{handleAudio(!isAudioPlay)}}
              /> */}
              <EndSession handleSessionClose={handleSpeechPause} />
            </div>
          </div>
        </div>
        <div className="fs-body-wrap fs-body-wrap-content pt-0">
          <div className="content-wrap position-relative" style={{minHeight: '100%', gap: '1rem'}}>
            {/* <Container> */}
              {/* <Row>
                <Col sm={12}> */}
                {/* <div className="chat-container"> */}
                <div className="customScroll-shade-grey" ref={chatContainerRef} style={{flex: 1, display: 'flex', minHeight:0,  justifyContent: 'center', width: '100%'}}>
                    <div
                      className="conversation-list"
                      style={{maxWidth: 1366, paddingLeft: 24, paddingRight: 24}}
                      
                    >
                      {chatList.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {item.type == "chat" && (
                              <div className="conversation-item">
                                <div className="media-item">
                                  <div className="circle-logo-small text-center">
                                    <img
                                      src={GLOBAL_IMAGES.PROMPT_LOGO}
                                      alt="PROMPT_LOGO"
                                    />
                                    <div className="text-wrap">
                                      <p className="caption">SAEED</p>
                                    </div>
                                  </div>
                                  <div className="media-content">
                                    {/* <TypeAnimation
                                                                                  sequence={[
                                                                                      htmlToText(item.text),
                                                                                      3000,
                                                                                  ]}
                                                                                  speed={{ type: 'keyStrokeDelayInMs', value: 30 }}
                                                                                  omitDeletionAnimation={true}
                                                                                  repeat={false}
                                                                              /> */}
                                    <div
                                      className="text-wrap"
                                      dangerouslySetInnerHTML={{
                                        __html: `<div class="media-content-text subtitle1">${item.text}<div>`,
                                      }}
                                    >
                                      {/* <p className="subtitle1"></p> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {item.type == "user" && (
                              <div className="conversation-item user-conversation">
                                <div className="media-item">
                                  <div className="circle-user-avtar text-center">
                                    <span>
                                      {userData ? userData?.shortName : "SM"}
                                    </span>
                                  </div>
                                  <div className="media-content">
                                    {item.audio != null && (
                                      <div className="audio-recorded">
                                        <IconButton
                                          src={
                                            playingIndex === index
                                              ? GLOBAL_IMAGES.PAUSE_WHITE_ICON
                                              : GLOBAL_IMAGES.PLAY_WHITE_ICON
                                          }
                                          style="btn media-btn"
                                          onClick={() =>
                                            handleChatPlayPause(index, item.audio)
                                          }
                                        />

                                        {playingIndex === index ? (
                                          <div className="voice-animation">
                                            <div id="bars">
                                              {Array.from({ length: 20 }).map(
                                                (_, index) => (
                                                  <div
                                                    key={index}
                                                    className={`bar`}
                                                  ></div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        ) : (
                                          <img
                                            className="recorded-img"
                                            src={GLOBAL_IMAGES.RECORDED_WAVE}
                                            alt="RECORDED_WAVE"
                                          />
                                        )}

                                        {/* <img className="recorded-img" src={GLOBAL_IMAGES.RECORDED_WAVE} alt="RECORDED_WAVE" /> */}
                                      </div>
                                    )}
                                    <div
                                      className="text-wrap text-end mt-24"
                                      dangerouslySetInnerHTML={{
                                        __html: `<div class="media-content-text subtitle1">${item.text}<div>`,
                                      }}
                                    >
                                      {/* <p className="subtitle1">{item.text}</p> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}

                      {/* {
                                              userMessage && (
                                                  <div className="conversation-item user-conversation">
                                                      <div className="media-item">
                                                          <div className="circle-user-avtar text-center">
                                                              <span>{userData ? "SM" : userData?.shortName}</span>
                                                          </div>
                                                          <div className="media-content">
                                                              <div className="text-wrap text-end">
                                                                  <p className="subtitle1">{userMessage}</p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              )
                                          }
                                          {
                                              sentRecording && (
                                                  <div className="conversation-item user-conversation">
                                                      <div className="media-item">
                                                          <div className="circle-user-avtar text-center">
                                                              <span>{userData ? "SM" : userData?.shortName}</span>
                                                          </div>
                                                          <div className="media-content">
                                                              <div className="audio-recorded">
                                                                  <IconButton
                                                                      src={isPlaying ? GLOBAL_IMAGES.PAUSE_WHITE_ICON : GLOBAL_IMAGES.PLAY_WHITE_ICON}
                                                                      style="btn media-btn"
                                                                      onClick={handlePlayPause}
                                                                  />
                                                                  <img className="recorded-img" src={GLOBAL_IMAGES.RECORDED_WAVE} alt="RECORDED_WAVE" />
                                                              </div>
                                                              <div className="text-wrap text-end mt-24">
                                                                  <p className="subtitle1">{finalTranscript}</p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              )
                                          } */}
                      {/* {
                                              noactivityfound && (
                                                  <div className="conversation-item">
                                                      <div className="media-item">
                                                          <div className="circle-logo-small text-center">
                                                              <img src={GLOBAL_IMAGES.PROMPT_LOGO} alt="PROMPT_LOGO" />
                                                          </div>
                                                          <div className="media-content">
                                                              <div className="text-wrap">
                                                                  <p className="subtitle1">I was unable to determine the activities based on the information provided. Would you like to select them yourself?</p>
                                                                  <div className="d-flex">
                                                                      <Btn
                                                                          variant="primary"
                                                                          size="md"
                                                                          style={'btn-ts-blue-gradient mt-28 mr-20 no-border min-w-160'}
                                                                          label={'Record Again'}
                                                                          leftIcon={<img src={GLOBAL_IMAGES.REDO_ICON} alt={GLOBAL_IMAGES.REDO_ICON} className="btn-icon-left" />}
                                                                          onClick={reRecord}
                                                                      />
                                                                      <Btn
                                                                          variant="primary"
                                                                          size="md"
                                                                          style={'btn-primary-gradient mt-28 min-w-160'}
                                                                          label={'See All Activities'}
                                                                          rightIcon={<img src={GLOBAL_IMAGES.ARROW_RIGHT_ICON} alt={GLOBAL_IMAGES.ARROW_RIGHT_ICON} className="btn-icon-right" />}
                                                                          onClick={seeAllActivities}
                                                                      />
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              )
                                          } */}
                      {isloading && (
                        <div className="loader-container">
                          <div className="loader-wrap loader-with-text">
                            <div className="loader-static">
                              <Spinner></Spinner>
                            </div>
                            <div className="text-wrap pt-20">
                              <p className="subtitle2 white-color">
                                Processing your input.
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {isChatTyping && (
                        <div className="conversation-item">
                          <div className="media-item align-items-center">
                            <div className="circle-logo-small text-center">
                              <img
                                src={GLOBAL_IMAGES.PROMPT_LOGO}
                                alt="PROMPT_LOGO"
                              />
                              <div className="text-wrap">
                                <p className="caption">SAEED</p>
                              </div>
                            </div>
                            <div className="media-content">
                              <div className="chat-typing-wrap text-wrap">
                                <p className="subtitle2">Typing</p>
                                <div className="typing">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    )}
                    </div>
                    <audio
                      controls
                      playsInline
                      muted
                      ref={audioRef}
                      src={recording}
                      onPlay={() => setIsPlaying(true)}
                      onPause={() => setIsPlaying(false)}
                      onEnded={() => setIsPlaying(false)}
                      style={{ display: "none" }}
                    />
                    <audio
                      controls
                      playsInline
                      muted
                      ref={audioRef1}
                      src={recording}
                      onPlay={() => setIsPlaying(true)}
                      onPause={() => setIsPlaying(false)}
                      onEnded={() => setIsPlaying(false)}
                      style={{ display: "none" }}
                    />
                  </div>
                  <div
                    className={`user-message-input conversation-input ${
                      isChatTyping ? "chat-typing-row" : ""
                    }`}
                  >
                    {recording && (
                      <IconButton
                        src={GLOBAL_IMAGES.DELETE_ICON}
                        style="btn-ts-black-gradient square-btn border-primary mr-20"
                        onClick={deleteRecording}
                      />
                    )}
                    <InputGroup>
                      {recording && (
                        <IconButton
                          src={
                            isPlaying
                              ? GLOBAL_IMAGES.PAUSE_ICON
                              : GLOBAL_IMAGES.PLAY_ICON
                          }
                          style="btn-ts-black-gradient square-btn border-blue"
                          onClick={handlePlayPause}
                          disabled={isChatTyping}
                        />
                      )}
                      {streamRecording && (
                        <IconButton
                          // src={isPaused ? GLOBAL_IMAGES.PLAY_ICON : GLOBAL_IMAGES.PAUSE_ICON}
                          src={GLOBAL_IMAGES.PLAY_ICON}
                          style="btn-ts-black-gradient square-btn border-blue"
                          disabled={isChatTyping}
                          // onClick={isPaused ? resumeRecording : pauseRecording}
                        />
                      )}
                      {streamRecording || recording ? (
                        <div className="voice-pop">
                          <VoiceAnimation isRecording={streamRecording} />
                        </div>
                      ) : null}
                      <Form.Control
                        ref={inputChatRef}
                        id="input-chat"
                        autoFocus={true}
                        placeholder={
                          !streamRecording
                            ? "Type a message here..."
                            : "Recording now..."
                        }
                        disabled={!!recording || isChatTyping} //
                        value={message}
                        onChange={(e) => {
                          e.target.value.length > 0
                            ? setIsTyping(true)
                            : setIsTyping(false);
                          setMessage(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.target.value.length > 0) {
                            sendRecording();
                          }
                        }}
                      />
                      {!streamRecording && !recording && !isTyping && (
                        <IconButton
                          src={GLOBAL_IMAGES.RECORD_ICON}
                          style="btn-primary-gradient square-btn"
                          onClick={startRecording}
                          disabled={isChatTyping}
                        />
                      )}
                      {streamRecording && (
                        <IconButton
                          src={GLOBAL_IMAGES.STOP_ICON}
                          style="btn-primary-gradient square-btn"
                          onClick={stopRecording}
                          disabled={isChatTyping}
                        />
                      )}
                      {(recording || isTyping) && (
                        <IconButton
                          src={GLOBAL_IMAGES.SEND_ICON}
                          style="btn-primary-gradient square-btn"
                          onClick={sendRecording}
                          disabled={isChatTyping}
                        />
                      )}
                    </InputGroup>
                  </div>
                {/* </div> */}
                {/* </Col>
              </Row> */}
            {/* </Container> */}
          </div>
        </div>
        <div className="fs-footer-wrap">
          <div className="btn-wrap btn-flex-between">
            <Btn
              size="lg"
              label={"Back"}
              iconPosition="left"
              style="btn-outline-primary no-hover"
              leftIcon={
                <img
                  src={GLOBAL_IMAGES.ARROW_LEFT_ICON}
                  alt={GLOBAL_IMAGES.ARROW_LEFT_ICON}
                  className="btn-icon-left"
                />
              }
              onClick={handleBackClick}
            />
            <Btn
              variant="primary"
              style={"btn-primary-gradient"}
              size="lg"
              label={"Continue"}
              disabled={isloading}
              onClick={submitData}
              rightIcon={
                <img
                  src={GLOBAL_IMAGES.ARROW_RIGHT_ICON}
                  alt={GLOBAL_IMAGES.ARROW_RIGHT_ICON}
                  className="btn-icon-right"
                />
              }
            />
          </div>
        </div>
      </div>
      <Dialog
        show={showDialog}
        close={handleClose}
        title={dialogData.title}
        description={dialogData.description}
        backdrop="static"
        closeButton={true}
        showFooter={false}
        style={"custom-modal"}
        onClick={handleClose}
        btnLeftIcon={dialogData.btnIcon}
        btnLabel={dialogData.btnLabel}
        btnStyle={"btn-primary-gradient mt-32"}
      />
      <Dialog
        show={showChatDialog}
        close={handleClose}
        title={dialogData.title}
        description={dialogData.description}
        backdrop="static"
        closeButton={true}
        showFooter={false}
        style={"custom-modal"}
        onClick={handleChatClose}
        btnLeftIcon={dialogData.btnIcon}
        btnLabel={dialogData.btnLabel}
        btnStyle={"btn-primary-gradient mt-32"}
      />
      {isAudioPlay && (
        <TextSpeechVoice voiceText={voiceText} ref={textSpeechRef} />
      )}
      {/* <TextSpeechVoice voiceText={voiceText} ref={textSpeechRef} /> */}
      <ToastContainer />
    </React.Fragment>
  );
};

export default ApplicationChat;
