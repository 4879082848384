import React from 'react';
import PropTypes from 'prop-types';

// framework
import { Form, InputGroup } from 'react-bootstrap';

const SelectBox = (props) => {

	const {
		label = '',
		id = '',
		// defaultValue = '',
		size = '',
		style = '',
		labelStyle = '',
		containerStyle = '',
		helperText = '',
		disabled = false,
		isValid = false,
		isInvalid = false,
		required = false,
		data = [{
			label : "Select",
			value : "",
			disabled : false,
		}],
		onChange = () => { },
		leftContent = null,
    	rightContent = null, 
		value = '',
		readOnly = false
	} = props

	const handleChange = (event) => {
		props.onChange(event.target.value);
	};

	return (
		<Form.Group controlId={id} className={`${containerStyle} form-group ${readOnly && "form-read-only"}`}>
			{label && <Form.Label className={`${labelStyle}`}>{label}</Form.Label>}
			<div className={`form-control-wrap ${isInvalid && 'is-invalid'}`}>
				<InputGroup>
					{leftContent}
					<Form.Select
						disabled={disabled}
						isValid={isValid}
						isInvalid={isInvalid}
						onChange={handleChange}
						size={size}
						// defaultValue={defaultValue}
						value={value}
						id={id}
					>
						<option>Select gender</option>

						{
							data.map((item, index) => {
								return (
									<option value={item.value} disabled={item.disabled} key={index}>{item.label}</option>
								)
							})
						}
					</Form.Select>
					{rightContent}
				</InputGroup>
			</div>
			
			<Form.Control.Feedback type={`${isValid ? 'valid' : ''} ${isInvalid ? 'invalid' : ''}`}>{helperText}</Form.Control.Feedback>
		</Form.Group>
	)
}

/**
	* label : set label text
	* id :  set element id 
	* style : Manually style the checkbox/ radio
	* labelStyle : Manually style the label
	* containerStyle : Manually style the wrapper
	* helperText : providing helper text 
	* disabled : Disables the control.
	* isValid : Manually style the input as valid
	* isInvalid : Manually style the input as invalid
	* data : Providing array of object 
	* onChange : handlers from firing regardless of the rendered element.
*/


SelectBox.propTypes = {
	label: PropTypes.string,
	id: PropTypes.string,
	// defaultValue: PropTypes.string,
	size: PropTypes.string,
	style: PropTypes.string,
	value: PropTypes.string,
	labelStyle: PropTypes.string,
	containerStyle: PropTypes.string,
	helperText: PropTypes.string,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	isValid: PropTypes.bool,
	isInvalid: PropTypes.bool,
	data: PropTypes.array,
	onChange: PropTypes.func
};

export default SelectBox