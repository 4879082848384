const GLOBAL_IMAGES = {
    LOGO: require("./logo/logo.svg").default,
    CIRCLE_LOGO: require("./logo/circle-logo.svg").default,
    PROMPT_LOGO: require("./logo/prompt-logo.svg").default,

    BG_BUILDING: require("./background/bg-building.png"),

    LINK_ICON: require("./icons/link.svg").default,
    USER_ICON: require("./icons/user.svg").default,
    GALERY_ICON: require("./icons/gallery.svg").default,
    ARROW_RIGHT_ICON: require("./icons/arrow-right.svg").default,
    ROUND_LINK_ICON: require("./icons/round-link.svg").default,
    CAMERA_ICON: require("./icons/camera.svg").default,
    TAB_ICON: require("./icons/tab.svg").default,
    REDO_ICON: require("./icons/redo.svg").default,
    CLOSE_CIRCLE_ICON: require("./icons/close-circle.svg").default,
    TICK_CIRCLE: require("./icons/tick-circle.svg").default,
    WARNING_ICON: require("./icons/warning.svg").default,
    PAUSE_ICON: require("./icons/pause.svg").default,
    STOP_ICON: require("./icons/stop.svg").default,
    DELETE_ICON: require("./icons/delete.svg").default,
    PLAY_ICON: require("./icons/play.svg").default,
    SEND_ICON: require("./icons/send.svg").default,
    RECORD_ICON: require("./icons/record.svg").default,
    PLAY_WHITE_ICON: require("./icons/play-white.svg").default,
    PAUSE_WHITE_ICON: require("./icons/pause-white.svg").default,
    LIST: require("./icons/list.svg").default,
    CHEVRON_RIGHT: require("./icons/chevron-right.svg").default,
    CALENDAR_ICON: require("./icons/calendar.svg").default,
    ARROW_LEFT_ICON: require("./icons/arrow-left.svg").default,
    INVALID: require("./icons/invalid.svg").default,
    END_SESSION: require("./icons/end-session.svg").default,
    LIKE: require("./icons/like.svg").default,
    CLOSE_CIRCLE_SMALL_ICON: require("./icons/close-circle-small.svg").default,
    INFO_CIRCLE_ICON: require("./icons/info-circle.svg").default,
    INFO_WARNING_ICON: require("./icons/info-warning.svg").default,
    EDIT_ICON: require("./icons/edit.svg").default,
    PERSONAL_CARD_ICON: require("./icons/personal-card.svg").default,
    ARROW_DOWN_ICON: require("./icons/down-arrow.svg").default,
    EDIT_GREY_ICON: require("./icons/edit-grey.svg").default,
    SAVE_ICON: require("./icons/save.svg").default,
    SEARCH_ICON: require("./icons/search.svg").default,
    SPEAKER_ON_ICON: require("./icons/speaker-on.svg").default,
    SPEAKER_OFF_ICON: require("./icons/speaker-off.svg").default,
    CALENDAR_BIG_ICON: require("./icons/calendar-big.svg").default,
    ERROR_BIG_ICON: require("./icons/error.svg").default,

    LOCK_BAND: require("./illustration/lock-band.svg").default,
    USER_BAND: require("./illustration/user-band.svg").default,
    L_SHAPE_DOTS: require("./illustration/l-shape-dots.svg").default,
    SQUARE_SHAPE_DOTS: require("./illustration/square-shape-dots.svg").default,
    PASSPORT_DUMMY: require("./illustration/passport-dummy.png"),
    SOUND_WAVE: require("./illustration/sound-wave.svg").default,
    TOP_BOX_SHAP: require("./illustration/top-box-shap.svg").default,
    BOTTOM_BOX_SHAP: require("./illustration/bottom-box-shap.svg").default,
    HORIZONTAL_SHAP_DOT: require("./illustration/horizontal-shap-dot.svg").default,
    RECORDING_WAVE: require("./illustration/recording-wave.png"),
    RECORDED_WAVE: require("./illustration/recorderd-wave.svg").default,
    ACTIVITIES_HEADER_SHAPE: require("./illustration/activities-header-shape.svg").default,
    BLUR_IMG: require("./illustration/blur-img.svg").default, 
    UNSUPPORT_IMG: require("./illustration/unsupport-img.svg").default, 
    PAYMENT_HEADER_SHAPE: require("./illustration/payment-header-shape.png"),
}

export { GLOBAL_IMAGES }