import axios from "axios";

// BASE URL
import { URL, ENVIRONMENT } from "../../config/constants";

if (ENVIRONMENT !== "PRODUCTION") {
    console.log("Linked in USER:", ENVIRONMENT);
    console.log("Link:", `${URL["BASE_URL_" + ENVIRONMENT]}/`);
}

const passportDetails = (props) => {
    console.log("props", props);
    // Create a new FormData object
    const formData = new FormData();
    // Append each key-value pair from props to the FormData object
    Object.keys(props).forEach((key) => {
        formData.append(key, props[key]);
    });

    // Send the FormData with the request
    return axios({
        method: "POST",
        url: `${URL["BASE_URL_AZURE_" + ENVIRONMENT]}/passportdetailsfunction`,
        headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
        },
        validateStatus: false,
        data: formData,
    });
};


export default passportDetails;
