import React, { useContext, useEffect, useRef, useState } from "react";
//framework
import { Col, Row } from "react-bootstrap";

//components
import { Btn, Dialog, IconButton, Spinner } from "../../../components";
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";
import EndSession from "../../../components/end-session";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { GLOBAL_ANIMATION } from "../../../assets/animation/global-anime";
import TextSpeechVoice from "../../../components/text-to-speech-voice";
import paymentViaEmail from "../../../utils/helpers/API/PAYMENT_EMAIL";
import { getData, removeItem, storeData } from "../../../utils/common/storage-utils";
import { useLocation, useNavigate } from "react-router-dom";
import { paymentTransectionStatus } from "../../../utils/helpers/API/PAYMENT_STATUS";

//css
import "./index.scss";
import { MessageContext } from "../../../components/message-provider";
import saveDataApi from "../../../utils/helpers/API/SAVE_DATA";
import { ENVIRONMENT } from "../../../utils/config/constants";




const PaymentInitiation = () => {
    // Modal constants
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(false);
    const [showDialogFail, setShowDialogFail] = useState(false);
    const [showDialogPaid, setShowDialogPaid] = useState(false);
    const [paymentverification, setPaymentverification] = useState(false)
    const [timer, setTimer] = useState(90); // Countdown starts from 60 seconds
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const textSpeechRef = useRef(null);
    const userData = getData('userData') || {};
    const verifyingUserId = getData('verifyingUserId') || {};
    const location = useLocation();
    const [intervalId, setIntervalId] = useState(null);
    const [isRefId, setIsRefId] = useState("");
    const [paymentstatusloading, setPaymentstatusloading] = useState(false)
    const { message } = useContext(MessageContext);
    const [polling, setPolling] = useState(true);
    const applicationNumber = getData('companyId');
    const paymentstatus = getData('paymentstatus');
    const [offlinepayment, setOfflinepayment] = useState(false);
    const [offlinepaymentmodal, setOfflinepaymentmodal] = useState(false);
    const ENVIRONMENT_TYPE = "STAGING"; 

    const notifySuccess = (message) => toast.success(message, {
        autoClose: 3000, // 3 seconds
    });
    const notifyError = (message) => toast.error(message, {
        autoClose: 3000, // 3 seconds
    });

    const [isAudioPlay, setIsAudioPlay] = useState(null);
    const isInitialMount = useRef(true);


    const handleContinueClick = () => {
        handleSpeechPause();
        navigate("/");
    };


    useEffect(() => {
        if(applicationNumber) {
            setShowDialogPaid(true);
        }
        if (applicationNumber && ENVIRONMENT === ENVIRONMENT_TYPE) {
            setOfflinepaymentmodal(true);
        }
    }, [applicationNumber])

    const handlePaidDialog = ()=> {
        handleSpeechPause();
        navigate("/application-summary");
        handleClose()
    }

    const saveData = async () => {
        const payload = getData('saveFormData');
        try {
            const response = await saveDataApi(payload); // Call the login API with form values
            console.log('response', response?.data);
            if (response?.data?.status === 200) {
                const companyId = response?.data?.data?.company_id;
                console.log('companyId', companyId);
                storeData('companyId', companyId);
                storeData('licenseDocument', response?.data?.data?.url);
                setIsLoading(false);
                setOfflinepayment(false);
                navigate("/application-summary");
            } else {
                setPaymentstatusloading(false);
                setShowDialog(false);
                setShowDialogPaid(false);
                setIsLoading(false);
                setOfflinepayment(false);
                setTimeout(() => {
                    notifyError(`${response?.data?.message}`);
                }, 1000);
            }
        } catch (error) {
            setPaymentstatusloading(false);
        }
    }
    const paymentProccess = async () => {
        if (paymentstatus === "PURCHASED") {
            notifySuccess('Your payment has already been completed');
            return;
        } 
        if (ENVIRONMENT === ENVIRONMENT_TYPE) {
            console.log('ENVIRONMENT', ENVIRONMENT);
            setOfflinepayment(true);
            await saveData();
            return;
        }

        resendEmail();
        setIsLoading(true);
        const payload = {
            "userEmail": userData?.email,
            "userId": userData?.spcUserId,
            "socketId": verifyingUserId,
            "businessActivity" : `${userData?.activities?.activitycode} - ${userData?.activities?.englishname}`,
            "billingAddress": {
                "firstName": userData?.firstName,
                "lastName": userData?.lastName,
                "address1": userData?.address,
                "countryCode": userData?.country.toString()
            }
        }

        try {
            //notifySuccess('Payment link sent on email');
            const response = await paymentViaEmail(payload); // Call the login API with form values
            console.log("API Response paymentViaEmail:", response);
            if (response?.status === 200) {

                const _refId = response?.data?.data?.refId;
                setIsRefId(_refId)

                setIsLoading(true);
            } 
            // else {
            //     setIsLoading(false);
            // }
            console.log("API Response paymentViaEmail:", response);
        } catch (error) {
            console.log('err', error);
            setIsLoading(false);
        }
    }


    

    useEffect(() => {
        if (isRefId) {
            const POLLING_INTERVAL = 5000; // Poll every 5 seconds
            const userData = getData('userData');
            const paymentStatusPayload = {
                "refId": isRefId,
                "userId": userData?.spcUserId,
            };

            const paymentStatusApi = async() => {
                try {
                    const response = await paymentTransectionStatus(paymentStatusPayload);
                    if (response?.data?.status === 200) {
                        console.log("API Response paymentStatus:", response); // Debug log
                        if (response?.data?.data?.paymentstatus === 'PURCHASED') {
                            setPolling(false); // Stop polling on success
                            setShowDialog(true);
                            console.log("paymentStatusApi", response?.data);
                            storeData('paymentstatus', 'PURCHASED');
                            await saveData();
                        } else if (response?.data?.data?.paymentstatus === 'FAILED') {
                            // Instead of stopping polling, we can continue it to retry
                            setIsRefId('');
                            setShowDialogFail(true);
                            setTimeout(() => {
                                handleCloseFailDialog()
                            }, 3000);

                            setPaymentstatusloading(false);
                            console.log("paymentStatusApi", response?.data);
                        }
                    } 
                } catch (error) {
                    console.log('Error:', error); // Debug log
                }
            };

            // Set up the polling
            if (polling) {
                const intervalId = setInterval(paymentStatusApi, POLLING_INTERVAL);

                // Cleanup the interval on component unmount
                return () => clearInterval(intervalId);
            }
        }
    }, [polling, isRefId]);

    const handleCloseFailDialog = () => {
        setShowDialogFail(false);
        setPolling(true);  // Restart polling after the dialog is closed
        setIsLoading(false);
    };



    // Handle the resend action here
    const handleResend = () => {
        console.log("Resend OTP");

        // Reset the timer and disable the button again
        setTimer(90);
        setIsDisabled(true);
        paymentProccess();
    };
    const resendEmail = () => {

        setTimer(90)

        if (intervalId) {
            clearInterval(intervalId);
        }

        const id = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 1) {
                    clearInterval(id);
                    setIsDisabled(false);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        setIntervalId(id);
    };
    // Format timer as minutes and seconds
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleOpen = () => setShowDialog(true);
    const handleClose = () => setShowDialog(false);



    const handleSpeechPause = () => {
        if (textSpeechRef.current) {
            textSpeechRef.current.pauseSpeech();
        }
    };

    const handleBackClick = () => {
        handleSpeechPause();
        navigate(`/user-journey`, { state: { params: 'preview' } });
    };
    const voiceText = 'Here is the total amount due for your application. Please proceed with the payment.';
    // handle the audio pause and restart
    const handleResume = (data) => {
        if (textSpeechRef.current) {
            textSpeechRef.current.restartSpeech();
        }
    };

    const handleAudio = (data) => {
        setIsAudioPlay(data);
        if(!data) {
            handleSpeechPause();
        } else {
            handleResume();
        }
        storeData("isAduioPlay", data);
    }

    const getStoreAudioStatus = () => {
        const getAudioStatus = getData("isAduioPlay");
        console.log(getAudioStatus, "getAudioStatus_startup")
        setIsAudioPlay(getAudioStatus);
    }

    useEffect(() => {
        //if (isInitialMount.current) {
        getStoreAudioStatus()

        isInitialMount.current = false; // Set flag to prevent future calls
        //}
    }, []);

    return (
        <React.Fragment>
            <div className="fullscreen-view-wrap fullscreen-view-overlap bg-tertiary-color">
                <div className="fs-header-wrap">
                    <div className="d-flex justify-content-end">
                        <IconButton
                            src={isAudioPlay ? GLOBAL_IMAGES.SPEAKER_ON_ICON : GLOBAL_IMAGES.SPEAKER_OFF_ICON}
                            style="btn-outline-primary no-hover square-btn mr-20"
                            onClick={()=>{handleAudio(!isAudioPlay)}}
                        />
                        <EndSession handleSessionClose={handleSpeechPause} />
                    </div>
                </div>
                <div className="fs-body-wrap fs-body-wrap-content pt-0">
                    <div className="content-wrap position-relative height-100" style={{alignItems: 'initial', padding: '0 3rem'}}>
                        <Row>
                            <Col sm={6} className="p-0">
                                <div className="conversation-list">
                                    <div className="conversation-item full-width">
                                        <div className="media-item">
                                            <div className="circle-logo-small text-center mb-40">
                                                <img src={GLOBAL_IMAGES.CIRCLE_LOGO} alt="CIRCLE_LOGO" />
                                            </div>
                                            <div className="media-content">
                                                <div className="text-wrap">
                                                    <p className="subtitle2 max-w-100 ">{voiceText}</p>
                                                </div>
                                                <div className="activities-panel pb-0">
                                                    <div className="mb-32">
                                                        <img src={GLOBAL_IMAGES.PAYMENT_HEADER_SHAPE} alt="PAYMENT_HEADER_SHAPE" className="w-100 opacity-50" />
                                                    </div>
                                                    <div className="activities-wrap">
                                                        <div className="activities-header mb-24">
                                                            <div className="text-wrap">
                                                                <div className="d-flex justify-content-between">
                                                                    <h4 className="font-med">Price break-down (in AED)</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="activities-body payment-panel">
                                                            <div className="payment-grid">
                                                                <div className="data-row text-wrap">
                                                                    <div className="data-cell d-flex justify-content-between">
                                                                        <p className="subtitle2">License Fee</p>
                                                                        <p className="subtitle2">6,500.00</p>
                                                                    </div>
                                                                    <div className="data-cell d-flex justify-content-between">
                                                                        <p className="subtitle2">Facility</p>
                                                                        <p className="subtitle2">375.00</p>
                                                                    </div>
                                                                    <div className="data-cell d-flex justify-content-between">
                                                                        <p className="subtitle2">Knowledge Fee</p>
                                                                        <p className="subtitle2">10.00</p>
                                                                    </div>
                                                                    <div className="mt-8 data-cell total d-flex justify-content-between">
                                                                        <p className="subtitle2 font-med">Grand Total</p>
                                                                        <p className="subtitle2 font-med">6,885.00</p>
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <Btn
                                                                            variant="primary"
                                                                            size="md"
                                                                            style={'btn-primary-gradient mt-28 mb-20'}
                                                                            label={'Send Payment Link'}
                                                                            rightIcon={<img src={GLOBAL_IMAGES.LINK_ICON} alt={GLOBAL_IMAGES.LINK_ICON} className="btn-icon-right" />}
                                                                            onClick={paymentProccess}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="fs-footer-wrap">
                    <div className="btn-wrap btn-flex-between">
                        {!paymentverification && (
                            <React.Fragment>
                                {paymentstatus === "PURCHASED" ?
                                    <Btn
                                        size="lg"
                                        label={'End Session'}
                                        iconPosition="left"
                                        style="btn-outline-primary no-hover"
                                        rightIcon={<img src={GLOBAL_IMAGES.TICK_CIRCLE} alt={GLOBAL_IMAGES.TICK_CIRCLE} className="btn-icon-right" />}
                                        onClick={handleContinueClick}
                                    />
                                    :
                                    <Btn
                                        size="lg"
                                        label={'Back'}
                                        iconPosition="left"
                                        style="btn-outline-primary no-hover"
                                        leftIcon={<img src={GLOBAL_IMAGES.ARROW_LEFT_ICON} alt={GLOBAL_IMAGES.ARROW_LEFT_ICON} className="btn-icon-left" />}
                                        onClick={handleBackClick}
                                    />
                                }
                            </React.Fragment>
                        ) }
                    </div>
                </div>
            </div>
            {
                paymentstatusloading && (
                    <div className="loader-container">
                        <Spinner></Spinner>
                    </div>
                )
            }
            <Dialog
                show={showDialog}
                close={handleClose}
                title={"Your payment has been successfully processed"}
                backdrop="static"
                closeButton={false}
                showFooter={false}
                style={'custom-modal'}
                animatedIcon={GLOBAL_ANIMATION.SUCCESS}
            />
            <Dialog
                show={showDialogPaid}
                close={handleClose}
                title={"Your payment has already been completed" }
                backdrop="static"
                closeButton={false}
                showFooter={false}
                style={'custom-modal'}
                animatedIcon={GLOBAL_ANIMATION.SUCCESS}
                onClick={() => {handlePaidDialog()}}
                btnLeftIcon={GLOBAL_IMAGES.TICK_CIRCLE}
                btnLabel={"Okay"}
                btnStyle={'btn-primary-gradient mt-32'}

            />
            <Dialog
                show={offlinepaymentmodal}
                close={handleClose}
                title={"Your details already saved!"}
                backdrop="static"
                closeButton={false}
                showFooter={false}
                style={'custom-modal'}
                animatedIcon={GLOBAL_ANIMATION.SUCCESS}
                onClick={() => { handlePaidDialog() }}
                btnLeftIcon={GLOBAL_IMAGES.TICK_CIRCLE}
                btnLabel={"Okay"}
                btnStyle={'btn-primary-gradient mt-32'}

            />
            <Dialog
                show={showDialogFail}
                close={handleClose}
                title="Oops! Your payment could not be processed. Please try again."
                backdrop="static"
                closeButton={false}
                showFooter={false}
                style={'custom-modal'}
                icon={GLOBAL_IMAGES.ERROR_BIG_ICON}
                iconSize={'big-icon'}
            />
            {
                isAudioPlay && <TextSpeechVoice voiceText={voiceText} ref={textSpeechRef} />
            }
            {
                isLoading && (
                    <div className="fullscreen-view-wrap no-header fullscreen-view-overlap">
                        <div className="fs-body-wrap">
                            <div className="loader-wrap loader-with-text">
                                <div className="loader-static">
                                    <Spinner></Spinner>
                                </div>
                                <div className="text-wrap pt-20">
                                    <p className="subtitle2 white-color">Payment link has been sent to your email. Please make payment.</p>
                                </div>
                            </div>
                        </div>
                        <div className="fs-footer-wrap">
                            <div className="btn-wrap btn-flex-between">
                                <div>
                                    <Btn
                                        size="lg"
                                        label={'End Session'}
                                        iconPosition="left"
                                        style="btn-outline-primary no-hover"
                                        rightIcon={<img src={GLOBAL_IMAGES.TICK_CIRCLE} alt={GLOBAL_IMAGES.TICK_CIRCLE} className="btn-icon-right" />}
                                        onClick={handleContinueClick}
                                    />
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="text-wrap m-0">
                                        <p className="white-color subtitle2">Resend link for payment <span className="font-med">{timer > 0 ? `${formatTime(timer)} seconds` : ""}</span> </p>
                                    </div>
                                    <div>
                                        <Btn
                                            variant="primary"
                                            style="btn-primary-gradient ml-20"
                                            size="lg"
                                            label="Resend"
                                            disabled={isDisabled}
                                            leftIcon={<img src={GLOBAL_IMAGES.REDO_ICON} alt="REDO_ICON" className="btn-icon-left" />}
                                            onClick={() => handleResend()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                offlinepayment && (
                    <div className="fullscreen-view-wrap no-header fullscreen-view-overlap">
                        <div className="fs-body-wrap">
                            <div className="loader-wrap loader-with-text">
                                <div className="loader-static">
                                    <Spinner></Spinner>
                                </div>
                                <div className="text-wrap pt-20">
                                    <p className="subtitle2 white-color">Saving the details</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <ToastContainer />
        </React.Fragment>
    );
};

export default PaymentInitiation;
