import React from 'react';
import PropTypes from 'prop-types';

// styles
import './background-block.scss';

const BackgroundBlock = (props) => {

    const { src = '' } = props;

	return (
        <div className="background-block-wrap">
            <img src={src} alt="bg-alt" />
            <div className="triangle-bar-wrap top-right-corner"></div>
            <div className="horizontal-bar-wrap bottom-left-corner"></div>
        </div>
    )
}

BackgroundBlock.propTypes = {
	src: PropTypes.string,
};

export default BackgroundBlock