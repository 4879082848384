import axios from "axios";

// BASE URL
import { URL, ENVIRONMENT } from "../../config/constants";

if (ENVIRONMENT !== "PRODUCTION") {
    console.log("Linked in USER:", ENVIRONMENT);
    console.log("Link:", `${URL["BASE_URL_" + ENVIRONMENT]}/`);
}

const fetchCountries = () => {
//console.log("props", props);
    return axios({
        method: "GET",
        url: `${URL["BASE_URL_" + ENVIRONMENT]}/country/list`,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        validateStatus: false,
    });
};
const getCountries = (props) => {
    //console.log("props", props);
    return axios({
        method: "GET",
        url: `${URL["BASE_URL_" + ENVIRONMENT]}/country?searchQuery=${props}`,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        validateStatus: false,
    });
};

export {
    fetchCountries,
    getCountries
};
