import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// pages
import Login from "../screens/auth/login";
import NewUser from "../screens/new-user/index";
import UserJourney from "../screens/user-journey";
import StakeholderDetailsForm from "../screens/stakeholder-forms";
import UploadDocument from "../screens/upload-document";
import ApplicationLicense from "../screens/user-journey/application-license";
import ApplicationSummary from "../screens/user-journey/application-summary";
import PaymentVerification from "../screens/user-journey/payment";
import PaymentInitiation from "../screens/user-journey/payment-initiation";

function BaseRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Login/>}
      />
      <Route
        path="/new-user"
        element={<NewUser/>}
      />
      <Route
        path="/stakeholder-details"
        element={<StakeholderDetailsForm/>}
      />
      <Route
        path="/user-journey"
        element={<UserJourney />}
      />
      <Route
        path="/upload-document"
        element={<UploadDocument />}
      />
      <Route
        path="/payment-verification"
        element={<PaymentVerification />}
      />
      <Route
        path="/payment-initiation"
        element={<PaymentInitiation />}
      />
      <Route
        path="/application-summary"
        element={<ApplicationSummary />}
      />
      <Route
        path="/application-license"
        element={<ApplicationLicense />}
      />
    </Routes>
  );
}

export default BaseRoutes;
