import React, { useEffect, useRef, useState } from "react";
//framework
import { Col, Row } from "react-bootstrap";

//components
import { Btn, Dialog, Spinner } from "../../../components";
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";
import EndSession from "../../../components/end-session";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { GLOBAL_ANIMATION } from "../../../assets/animation/global-anime";
import TextSpeechVoice from "../../../components/text-to-speech-voice";
import paymentViaEmail from "../../../utils/helpers/API/PAYMENT_EMAIL";
import { getData } from "../../../utils/common/storage-utils";
import { useLocation, useNavigate } from "react-router-dom";
import { paymentStatus } from "../../../utils/helpers/API/PAYMENT_STATUS";

//css
import "./index.scss";

const PaymentVerification = () => {
    // Modal constants
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(false);
    const textSpeechRef = useRef(null);
    const [showDialogFail, setShowDialogFail] = useState(false);
    const userData = getData('userData') || {};
    const verifyingUserId = getData('verifyingUserId') || {};
    const location = useLocation();
    const [intervalId, setIntervalId] = useState(null);
    const [isAudioPlay, setIsAudioPlay] = useState(true);
    const isInitialMount = useRef(true);
    const [paymentstatusloading, setPaymentstatusloading] = useState(true);

    const notifySuccess = (message) => toast.success(message, {
        autoClose: 3000, // 3 seconds
    });
    const notifyError = (message) => toast.error(message, {
        autoClose: 3000, // 3 seconds
    });

    const fetchPaymentStatus = async () => {
        console.log("fetchPaymentStatus called"); // Debug log
        const params = new URLSearchParams(location.search); // Parse query parameters
        const ref = params.get('ref'); // Get the 'ref' parameter
        const paymentStatusPayload = {
            "type": "payment_status_updated",
            "data": {
                "orderReference": ref
            }
        };

        if (ref) {
            setPaymentstatusloading(true);
            try {
                const response = await paymentStatus(paymentStatusPayload); // Call the paymentStatus API with form values
                if (response?.status === 200) {
                    console.log("API Response paymentStatus:", response); // Debug log
                    if (response?.data?.paymentstatus === 'PURCHASED') {
                        setShowDialog(true);
                    } else {
                        console.log("Notify error will be called"); // Debug log
                        // notifyError('Payment failed! Please try again');
                        setShowDialogFail(true);
                    }
                } else {
                    console.log("API Response error paymentStatus:", response); // Debug log
                }
            } catch (error) {
                console.log('Error:', error); // Debug log
                notifyError('An error occurred while checking payment status.');
            } finally {
                setPaymentstatusloading(false);
            }
        }
    };

    useEffect(() => {
        fetchPaymentStatus(); // Only called once on component mount
    }, []);

    const handleOpen = () => setShowDialog(true);
    const handleClose = () => setShowDialog(false);

    const handleSpeechPause = () => {
        if (textSpeechRef.current) {
            textSpeechRef.current.pauseSpeech();
        }
    };

    const handleBackClick = () => {
        handleSpeechPause();
        navigate(`/user-journey`, { state: { params: 'preview' } });
    };


    // handle the audio pause and restart
    const handleResume = (data) => {
        if (textSpeechRef.current) {
            textSpeechRef.current.restartSpeech();
        }
    };

    useEffect(()=> {
        if (isInitialMount.current) {
            const _isAudio = getData("isAduioPlay");
            console.log(_isAudio, typeof _isAudio, "_isAudio")
            setIsAudioPlay(_isAudio);
            
            isInitialMount.current = false; // Set flag to prevent future calls   
        }
    }, []);

    useEffect(()=> {
        // get isAudio is set
        if(!isAudioPlay) {
            handleSpeechPause();
        } else {
            handleResume();
        }
    }, [isAudioPlay])

    const voiceText = 'Here is the total amount due for your application. Please proceed with the payment.';

    return (
        <React.Fragment>
            <div className="fullscreen-view-wrap fullscreen-view-overlap bg-tertiary-color">
                <div className="fs-body-wrap fs-body-wrap-content pt-0">
                    <div className="content-wrap position-relative height-100">
                    </div>
                </div>
            </div>
            {
                paymentstatusloading && (
                    <div className="loader-container">
                        <Spinner />
                    </div>
                )
            }
            <Dialog
                show={showDialog}
                close={handleClose}
                title="Your payment has been successfully processed"
                backdrop="static"
                closeButton={false}
                showFooter={false}
                style={'custom-modal'}
                animatedIcon={GLOBAL_ANIMATION.SUCCESS}
            />
            <Dialog
                show={showDialogFail}
                close={handleClose}
                title="Oops! Your payment could not be processed. Please try again."
                backdrop="static"
                closeButton={false}
                showFooter={false}
                style={'custom-modal'}
                icon={GLOBAL_IMAGES.ERROR_BIG_ICON}
                iconSize={'big-icon'}
            />
            <ToastContainer />
        </React.Fragment>
    );
};

export default PaymentVerification;
