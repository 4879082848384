import Cookies from "universal-cookie";

export function setSession(token) {
  console.log("token", token)
  const cookies = new Cookies();
  cookies.set("token", token, { path: "/" });
}

export function setUserDetails(user) {
  const cookies = new Cookies();
  cookies.set("user", user, { path: "/" });
}

export function setVendorFileUuid(fileUuid) {
  const cookies = new Cookies();
  cookies.set("fileUuid", fileUuid, { path: "/" });
}

export function isAuthenticated() {
  const cookies = new Cookies();
  return cookies.get("token") ? true : false;
}

export function getSessionToken() {
  const cookies = new Cookies();
  return cookies.get("token");
}

export function getUserDetails() {
  const cookies = new Cookies();
  return cookies.get("user");
}

export function getVendorFileUuid() {
  const cookies = new Cookies();
  return cookies.get("fileUuid");
}

export function removeSession() {
  _removeSession(["token", "user"]);
}

export function removeCookies() {
  const cookies = new Cookies();
  return cookies.remove("user", { path: "/" });
}

export function redirectToLogin() {
  removeSession();
  window.location.href = "/";
}

export function getEAOSDate() {
  const cookies = new Cookies();
  return cookies.get("easoDate");
}

function _removeSession(keys) {
  let cookies = document.cookie.split(";");
  cookies.forEach((value, index) => {
    let key = cookies[index].split("=");
    const cookieKey = key[0].trim();
    if (keys.indexOf(cookieKey) !== -1) {
      document.cookie =
        key[0] + " =;path=/;expires = Thu, 01 Jan 1970 00:00:00 GMT";
    }
  });
}
