import React, { useEffect, useRef, useState } from "react";
//framework
import { Accordion, InputGroup, ListGroup } from "react-bootstrap";
import Form from 'react-bootstrap/Form';

//components
import { Btn, Dialog, Input, Spinner } from "../../../components";

// assets
import { GLOBAL_IMAGES } from "../../../assets/images/global-images";

//css
import "./index.scss";
import 'react-toastify/dist/ReactToastify.css';

// utils
import { extractCategoriesFunctionApi } from "../../../utils/helpers/API/USER_JOURNEY";
import { getData, storeData } from "../../../utils/common/storage-utils";
import useDebounce from "../../../utils/common/useDebounce";

// service
import { SERVER_ERROR_DIALOG_DATA } from "../../../services/static-content";
import { toast, ToastContainer } from "react-toastify";


const AllActivities = ({ activities }) => {

    const searchRef = useRef(null);
    const isInitialMount = useRef(true);

    const [isShowSearchModal, setIsSearchModal] = useState(false);
    const [activitiesLogData, setActivitiesLogData] = useState({ searchResults: [] });
    const [activitiesSearchLogData, setActivitiesSearchLogData] = useState(null);
    const textSpeechRef = useRef(null);
    const [isloading, setIsloading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogData, setDialogData] = useState({});
    const debounceDelay = 500; // Delay in milliseconds

    // Selected activity state
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);

    const [isAudioPlay, setIsAudioPlay] = useState(true);

    // Debounce the search term with a 300ms delay
    // const debouncedSearchTerm = useDebounce(searchTerm, 500);
    

    // handleSubCategoryClick
    const handleSubCategoryClick = (category, subcategory) => {
        console.log(subcategory, "handleSubCategoryClick")
        setSelectedCategory(category);
        setSelectedSubCategory(subcategory);
        // setSelectedActivity(null); // Reset activity selection when subcategory changes
    };

    // Handle radio button change
    const handleRadioChange = (data) => {
        setSelectedActivity(data.activitycode);
        /* const userData = getData("userData") || {};
        userData.activities = data;
        storeData("userData", userData); */
        activities(data);
    };


    // handle search click
    const handleSearchClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setIsSearchModal(false);
        } else {
            setIsSearchModal(true);
        }
    };

    const notifyError = (message) => toast.error(message, {
        autoClose: 10000, // 3 seconds
    });

    useEffect(() => {
        // document.addEventListener("mousedown", handleSearchClickOutside);
        if (isShowSearchModal) {
            document.addEventListener("mousedown", handleSearchClickOutside);
        } else {
            document.removeEventListener("mousedown", handleSearchClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleSearchClickOutside);
        };
    }, [isShowSearchModal]);

    // dialog handle
    const handleOpen = () => {setShowDialog(true)};
    const handleClose = () => {setShowDialog(false)};

    // api extractCategoriesFunctionApi
    const getExtractCategories = async (data, type) => {
        console.log(type, "getExtractCategories type")
        setIsloading(true);
        try {
            const response = await extractCategoriesFunctionApi(data);
            
            if(response.status === 200) {
                console.log(response.data, "getExtractCategories all activities");
                if(type === "search") { 
                    setActivitiesSearchLogData(response.data);
                } else {
                    setActivitiesLogData(response.data);

                    // Default selection logic
                    /* if (response.data.searchResults.length > 0) {
                        const firstCategory = response.data.searchResults[0];
                        if (firstCategory.subcategories.length > 0) {
                            const firstSubcategory = firstCategory.subcategories[0];
                            setSelectedCategory(firstCategory.category);
                            setSelectedSubCategory(firstSubcategory.subcategory);
                            
                            // if (firstSubcategory.activities.length > 0) {
                            //     setSelectedActivity(firstSubcategory.activities[0].activitycode);
                            // }
                        }
                    } */
                }
                
                setIsloading(false);
                
            } else {
                setIsloading(false);
            }
        } catch (error) {
            console.log(error);
            notifyError("Something went wrong. Please try again");
            setIsloading(false);
        }
    };

    /* // handleSearchData
    const handleSearchData = (e) => {
        const searchValue = e.target.value.toLowerCase();
        getExtractCategories(searchValue, "search");
    } */

    // Debounced API call
    const handleSearchData = useDebounce(async (e) => {
        const searchValue = e.target.value.toLowerCase();
        await getExtractCategories(searchValue, "search"); // Your API call here
    }, debounceDelay);

    useEffect(() => {
        if (isInitialMount.current) {
            getExtractCategories("", "all-activity"); // Call only once on initial mount
            isInitialMount.current = false; // Set flag to prevent future calls
        }
    },[]);
    

    // Filter categories to find activities only from the selected subcategory
    const filteredActivities = activitiesLogData.searchResults
        .filter(cat => cat.category === selectedCategory)
        .flatMap(category => category.subcategories)
        .filter(subcat => subcat.subcategory === selectedSubCategory)
        .flatMap(subcat => subcat.activities);


    // handle the audio pause and restart

    const handleSpeechPause = () => {
        if (textSpeechRef.current) {
            textSpeechRef.current.pauseSpeech();
        }
    };

    
    const handleResume = (data) => {
        if (textSpeechRef.current) {
            textSpeechRef.current.restartSpeech();
        }
    };

    useEffect(()=> {
        if (isInitialMount.current) {
            const _isAudio = getData("isAduioPlay");
            console.log(_isAudio, typeof _isAudio, "_isAudio")
            setIsAudioPlay(_isAudio);
            
            isInitialMount.current = false; // Set flag to prevent future calls   
        }
    }, []);

    useEffect(()=> {
        // get isAudio is set
        if(!isAudioPlay) {
            handleSpeechPause();
        } else {
            handleResume();
        }
    }, [isAudioPlay])


    return (
        <div className="activities-panel pb-0">
            <div className="mb-32 activities-header-img">
                <img src={GLOBAL_IMAGES.ACTIVITIES_HEADER_SHAPE} alt="ACTIVITIES_HEADER_SHAPE" className="w-100 opacity-50" />
            </div>
            <div className="activities-wrap">
                {
                    isloading && (
                        <div className="loader-container activity-loader">
                            <Spinner></Spinner>
                        </div>
                    )
                }
                <div className="activities-header mb-20">
                    <div className="text-wrap">
                        <div className="d-flex justify-content-between">
                            <h4 className="font-med">Activities</h4>
                        </div>
                        <div className="mt-24 d-flex justify-content-between">
                            <h6 className="body1">Maximum Activities Permitted : 1</h6>
                            <div className="activities-count d-flex">
                                <h6 className="subtitle2 font-med">1</h6>
                                <h6 className="subtitle2">/1</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Btn
                    variant="primary"
                    style={'btn-primary-gradient'}
                    size="lg"
                    label={'Continue'}
                    rightIcon={<img src={GLOBAL_IMAGES.ARROW_RIGHT_ICON} alt={GLOBAL_IMAGES.ARROW_RIGHT_ICON} className="btn-icon-right" />}
                    onClick={()=> {getExtractCategories("acetic acid", "all-activity");}}
                /> */}
                <div className="activities-body">
                    <div className="activities-search-wrap" onClick={() => { setIsSearchModal(true) }} ref={searchRef}>
                        <Input
                            label=""
                            type="text"
                            id="searchActivities"
                            placeholder=""
                            containerStyle={"mb-8 input-tertiary-color"}
                            onChange={handleSearchData}
                            onBlur={()=>{console.log("test")}}
                            leftContent={
                                <InputGroup.Text className="left-content-group">
                                    <img src={GLOBAL_IMAGES.SEARCH_ICON} alt="SEARCH_ICON" />
                                </InputGroup.Text>
                            }
                        />
                        {
                            isShowSearchModal && (
                                <div className="search-list-wrap customScroll-shade-grey">
                                    {
                                       activitiesSearchLogData ? (
                                            activitiesSearchLogData.searchResults.map((categoryItem, categoryIndex) => {
                                                return (
                                                    <div className="mb-32" key={`category-${categoryIndex}`}>
                                                        <div className="text-wrap mb-16">
                                                            <p className="body2 white-color text-uppercase">{categoryItem.category}</p>
                                                        </div>
                                                        {categoryItem.subcategories.map((subcategoryItem, subcategoryIndex) => (
                                                            <div className="search-subcategory-wrap" key={`subcategory-${subcategoryIndex}`}>
                                                                <div className="text-wrap mb-16">
                                                                    <p className="body2 white-color text-uppercase">{subcategoryItem.subcategory}</p>
                                                                </div>
                                                                <div className="search-subactivities-wrap">
                                                                    {subcategoryItem.activities.map((activityItem, activityIndex) => (
                                                                        <div key={`activity-${activityIndex}`}>
                                                                            {activityItem.subactivities.map((subactivityItem, subactivityIndex) => (
                                                                                <div className="activities-item mb-20" key={`subactivity-${subactivityIndex}`}>
                                                                                    <Form.Check type={'radio'} id={subactivityItem.activitycode} className="mb-20 mt-0 p-0">
                                                                                        <Form.Check.Input
                                                                                            type={'radio'}
                                                                                            checked={selectedActivity === subactivityItem.activitycode}
                                                                                            onChange={() => handleRadioChange(subactivityItem)}
                                                                                        />
                                                                                        <Form.Check.Label>
                                                                                            <div
                                                                                                className="text-wrap d-flex"
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: `
                                                                                                        <div class="font-med subtitle2 text-nowrap">${subactivityItem.activitycode} &nbsp;- &nbsp;&nbsp;&nbsp;</div>
                                                                                                        <div class="subtitle2">${subactivityItem.englishname}</div>
                                                                                                    `
                                                                                                }}
                                                                                            />
                                                                                        </Form.Check.Label>
                                                                                    </Form.Check>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )
                                            })
                                    
                                        ) : (
                                            <div className="text-wrap mb-16">
                                                <p className="body2 white-color text-uppercase">No search found</p>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                    <div className="activities-category-body customScroll-shade-grey">
                        <div className="activities-category-wrap">
                            <div className="text-wrap p-20 mb-12">
                                <p className="body2 white-color">ACTIVITY CATEGORIES</p>
                            </div>
                            <div className="accordion-wrap accordion-with-divider accordion-header-uppercase">
                                <Accordion defaultActiveKey="0">
                                    {
                                        activitiesLogData && activitiesLogData.searchResults.map((category, categoryIndex) => (
                                            <Accordion.Item eventKey={categoryIndex} key={categoryIndex}>
                                                <Accordion.Header>{category.category}</Accordion.Header>
                                                <Accordion.Body className="p-0">
                                                    <div className="accordion-list-wrap">
                                                        <ListGroup as="ul">
                                                            {
                                                                category.subcategories.map((subcategory, subcategoryIndex) => {
                                                                    // Determine if there are any selected subactivities in this subcategory
                                                                    const hasSelectedSubactivity = subcategory.activities
                                                                        .flatMap(activity => activity.subactivities)
                                                                        .some(subactivity => subactivity.activitycode === selectedActivity);

                                                                    return (
                                                                        <ListGroup.Item
                                                                            as="li"
                                                                            key={subcategoryIndex}
                                                                            className={
                                                                                selectedCategory === category.category && selectedSubCategory === subcategory.subcategory
                                                                                    ? 'active'
                                                                                    : ''
                                                                            }
                                                                            onClick={() => handleSubCategoryClick(category.category, subcategory.subcategory)}
                                                                        >
                                                                            <span>{subcategory.subcategory}</span>
                                                                            {hasSelectedSubactivity && <span className="badge badge-circular tertiary-color">1</span>}
                                                                        </ListGroup.Item>
                                                                    );
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))
                                    }
                                </Accordion>
                            </div>
                        </div>
                        <div className="activities-option-wrap">
                            <div className="text-wrap p-20 mb-12">
                                <p className="body2 white-color">ACTIVITIES</p>
                            </div>
                            <div className="accordion-wrap">
                                <Accordion>
                                    {
                                        filteredActivities.map((activity, activityIndex) => (
                                            <Accordion.Item eventKey={activityIndex} key={activityIndex}>
                                                <Accordion.Header className="row-reverse accordion-arrow-right-down">
                                                    <div
                                                        className="text-wrap d-flex ml-8"
                                                        dangerouslySetInnerHTML={{
                                                            __html: `
                                                                <div class="font-med subtitle2 text-nowrap">${activity.activitycode} ${activity.englishname ? `&nbsp;- &nbsp;&nbsp;&nbsp;` : ``}</div>
                                                                ${activity.englishname ? `<div class="subtitle2">${activity.englishname}</div>` : ``}
                                                            `
                                                        }}
                                                    />
                                                </Accordion.Header>
                                                <Accordion.Body className="p-0 mb-16">
                                                    {
                                                        activity.subactivities
                                                            .sort((a, b) => a.activitycode - b.activitycode)
                                                            .map((subactivity, subactivityIndex) => (
                                                                <Form className="pl-32" key={subactivityIndex}>
                                                                    <Form.Check 
                                                                        type={'radio'} 
                                                                        id={`${subactivity.activitycode}`} 
                                                                        className="mb-20 mt-0"
                                                                    >
                                                                        <Form.Check.Input
                                                                            type={'radio'}
                                                                            checked={selectedActivity === subactivity.activitycode}
                                                                            onChange={() => handleRadioChange(subactivity)}
                                                                        />
                                                                        <Form.Check.Label>
                                                                            <div
                                                                                className="text-wrap d-flex"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: `
                                                                                        <div class="font-med subtitle2 text-nowrap">${subactivity.activitycode} &nbsp;- &nbsp;&nbsp;&nbsp;</div>
                                                                                        <div class="subtitle2">${subactivity.englishname}</div>
                                                                                    `
                                                                                }}
                                                                            />
                                                                        </Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form>
                                                            ))
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))
                                    }
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <Dialog
                show={showDialog}
                close={handleClose}
                title={dialogData.title}
                description={dialogData.description}
                backdrop="static"
                closeButton={true}
                showFooter={false}
                style={'custom-modal'}
                onClick={handleClose}
                btnLeftIcon={dialogData.btnIcon}
                btnLabel={dialogData.btnLabel}
                btnStyle={'btn-primary-gradient mt-32'}
            />
        </div>
        
    );
};

export default AllActivities;
